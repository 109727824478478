import gql from "graphql-tag"

const CreateAgreementMutation = gql`mutation createAgreement($input: CreateAgreementMutationInput!){
    createAgreement(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateReservation = gql`
mutation updateReservationMutation($input: UpdateReservationMutationInput!){
    updateReservation(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const CreateReservationPromo = gql`mutation createReservationPromo($input: CreateReservationPromoMutationInput!){
    createReservationPromo(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`
const RemoveReservationPromo = gql`mutation removeReservationPromo($input: RemoveReservationPromoMutationInput!){
    removeReservationPromo(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

const CreateReservationPrice = gql`mutation createReservationPrice($input: CreateReservationPriceMutationInput!){
    createReservationPrice(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`
const RemoveReservationPrice = gql`mutation removeReservationPrice($input: RemoveReservationPriceMutationInput!){
    removeReservationPrice(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

const CreateReservationMutation = gql`mutation createReservation ($input: CreateReservationMutationInput!){
    createReservation(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
        reservation{
            id
            pickupType
            driver{
                id
                firstName
            }
            surcharge
            status
            car{
                id
                stage
            }
        }
    }
}`

export {
    CreateAgreementMutation, UpdateReservation, CreateReservationPrice, RemoveReservationPrice, CreateReservationMutation, CreateReservationPromo, RemoveReservationPromo
}
