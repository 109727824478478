import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { Input } from 'reactstrap'
import './SearchPlaces.css'

// Updated query to include additional address fields
const SEARCH_PLACES = gql`
  query SearchPlaces($searchTerm: String!, $searchType: String!, $country: String) {
    searchPlaces(searchTerm: $searchTerm, searchType: $searchType, country: $country) {
        name
        addressLine1
        addressLine2
        city
        state
        postalCode
    }
  }
`

const SearchPlaces = ({ client, onSaveAction, initialValue, searchType, country }) => {
    const [placesOptions, setPlacesOptions] = useState([])
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if (initialValue) {
            setInputValue(initialValue)
        }
    }, [initialValue])

    const fetchPlaces = async (searchTerm) => {
        try {
            const { data } = await client.query({
                query: SEARCH_PLACES,
                variables: { searchTerm, searchType, country },
                fetchPolicy: 'network-only',
            })

            // Map API results to options
            const options = (data && data.searchPlaces && data.searchPlaces.map((place) => ({
                label: place.name,
                value: {
                    name: place.name,
                    addressLine1: place.addressLine1,
                    addressLine2: place.addressLine2,
                    city: place.city,
                    state: place.state,
                    postalCode: place.postalCode,
                },
            }))) || []

            // Add the user's custom input as an option
            setPlacesOptions([
                { label: `Use "${searchTerm}"`, value: { name: searchTerm } },
                ...options,
            ])
        } catch {
            // On error, show the user's input as the only option
            setPlacesOptions([{ label: `Use "${searchTerm}"`, value: { name: searchTerm } }])
        }
    }

    const handleInputChange = (newInputValue) => {
        setInputValue(newInputValue)
        if (newInputValue.length > 2) {
            fetchPlaces(newInputValue)
        } else {
            setPlacesOptions([{ label: `Use "${newInputValue}"`, value: { name: newInputValue } }])
        }
    }

    const handleSelectOption = (option) => {
        setInputValue(option.value.name) // Update the input field with the selected value
        setPlacesOptions([]) // Hide suggestions after selection
        onSaveAction && onSaveAction(option.value)
    }

    const handleInputBlur = () => {
        // Save the current input value when the input loses focus
        onSaveAction && onSaveAction({ name: inputValue })
    }

    return (
        <div className="search-places-wrapper">
            <Input
                type="text"
                value={inputValue}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={`Type to search for ${searchType === "all" ? "address" : searchType}`}
                onBlur={handleInputBlur}
                className="bos-custom-input"
            />
            {placesOptions.length > 0 && (
                <ul className="autocomplete-suggestions">
                    {placesOptions.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => handleSelectOption(option)}
                            className="suggestion-item"
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default withApollo(SearchPlaces)
