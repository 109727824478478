import gql from 'graphql-tag'

export const BoardQuery = gql`query Board($id: ID!){
    board(id: $id){
        id
        title
        description
        showDriverColumn
        showVehicleColumn
    }
}`

export const AllBoardsQuery = gql`query AllBoards($cursor: String, $orderBy:[String], $first: Int){
    allBoards(after: $cursor, orderBy: $orderBy, first: $first){
        edges{
            cursor
            node{
                id
                title
                description
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
    
}`


export const AllTasksQuery = gql`query AllTasks($orderBy:[String], $first: Int, $after: String, $assignedToIds: [ID], $statuses: [String], $boardIds: [ID], $searchTerm: String, $isArchived: Boolean){
    allTasks(orderBy: $orderBy, first: $first, after: $after, assignedToIds: $assignedToIds, statuses: $statuses, boardIds: $boardIds, searchTerm: $searchTerm, isArchived: $isArchived){
        edges{
            node{
                id
                pk
                dateAdded
                dateModified
                title
                description
                dueDate
                status
                assignedBy {
                    id
                    firstName
                    lastName
                    username
                }
                assignedTo (first: 10) {
                    edges {
                      node { 
                        id 
                        username
                        firstName
                        lastName
                        }
                    }
                }
                driver{
                    id
                    name
                }
                vehicle{
                    id vin year make model
                }
                board{
                    id
                    title
                    showDriverColumn
                    showVehicleColumn
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
    
}`


export const TaskQuery = gql`query Task($id: ID!){
    task(id: $id){
        id
        title
        description
        dateAdded
        dueDate
        createdBy {id username firstName lastName }
        board { id title }
        taskcommentSet {
            edges {
                node {
                    id
                    dateAdded
                    createdBy { id username firstName lastName }
                    comment
                    reactionsCount
                    taskcommentattachmentSet{
                        edges{ node{ id url extension name } }
                    }
                }
            }
        }
        taskchecklistSet {
            edges {
                node {
                    id
                    title
                    description
                    mustComplete
                    completedOn
                }
          }
        }
        history{
            date
            comment
            user
        }
        driver{
            id name
        }
        vehicle{
            id vin year make model
        }
    }
}`


export const AllDriversQuery = gql`query AllDrivers($searchTerm: String){
    allDrivers(searchTerm: $searchTerm, first: 10){
        edges{
            node{ 
                id name email
            }
        }
    }
    
}`

export const CarsQuery = gql`query AllCars($searchTerm: String){
    allCars(searchTerm: $searchTerm, first: 10){
        edges{
            node{
                id vin customName make model year
            }
        }
    }
    
}`

export const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $searchTerm: String){
        allReps(searchTerm: $searchTerm, first: 10, isStaff:true, isActive:true, groups:$groups, orderBy:["username"]){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
          }
        }
      }
    }
`

export const GroupsQuery = gql`
    query AllGroupsQuery($searchTerm: String){
        allGroups(name_Icontains: $searchTerm, first: 10){
            edges{
                node{
                    id
                    name
                }
            }
        }
    }
`

export const AllTaskCommentReactionsQuery = gql`query AllTaskCommentReactions($commentId: ID, $taskId: ID, $createdById: ID){
    allTaskCommentReactions(commentId: $commentId, taskId: $taskId, createdById: $createdById){
        edges{
            node{
                id
                reaction
                comment{ id }
                createdBy{
                    id
                    username
                    firstName
                    lastName
                }
            }
        }
    }
}`