import React, { useState, useEffect } from "react"
import { compose, graphql } from "react-apollo"
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Input, Label, Row, Col, Progress, Button } from "reactstrap"
import { CreateConditionMutation, UpdateConditionMutation } from "./Mutations"
import Select from "react-select"
import { ConditionTypes, AllConditionGroupsQuery, AllTriggersQuery, AvailableModelQuery, ConditionQuery } from "./Queries"

const ConditionForm = ({ createCondition, updateCondition, conditionValue, conditionTypes, allConditionGroups, availableModel, loading, handleClose, isOpen, isUpdate, conditionId, refetchAutomationQuery }) => {

    const [logicalOperator, setLogicalOperator] = useState('')
    const [condition, setCondition] = useState({ fieldName: '', conditionType: '', conditionValue: '' })
    const [error, setError] = useState(null)
    const [stateLoading, setStateLoading] = useState(false)

    useEffect(() => {
        if (conditionValue) {
            setLogicalOperator(conditionValue.conditionGroup.id)
            setCondition({
                fieldName: conditionValue.fieldName,
                conditionType: conditionValue.conditionType,
                conditionValue: conditionValue.conditionValue,
            })
        }
    }, [conditionValue])

    const handleConditionChange = (field, value) => {
        let newCondition = { ...condition }
        newCondition[field] = value
        setCondition(newCondition)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStateLoading(true)

        const variables = {
            input: {
                fieldName: condition.fieldName,
                conditionType: condition.conditionType,
                conditionValue: condition.conditionValue,
            }
        }

        const mutation = isUpdate ? updateCondition : createCondition
        if (isUpdate) variables.input.id = conditionId
        if (!isUpdate) variables.input.conditionGroupId = logicalOperator

        mutation({ variables }).then((response) => {
            if (isUpdate ? response.data.updateCondition.ok : response.data.createCondition.ok) {
                refetchAutomationQuery()
                handleClose()
            } else {
                setError(isUpdate ? response.data.updateCondition.errors[0].messages : response.data.createCondition.errors[0].messages)
            }
            setStateLoading(false)
        })
    }
    return (
        <Modal isOpen={isOpen} toggle={handleClose} >
            <ModalHeader toggle={handleClose}>{isUpdate ? "Update Condition" : "Add Condition"}</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || stateLoading) && <Progress animated color="info" value="100" />}
                <Form className="ConditionForm" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="logicalOperator">Logical Operator</Label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select"
                            id="logicalOperator"
                            options={
                                allConditionGroups && allConditionGroups.edges.map((conditionGroup) => ({
                                    label: conditionGroup.node.logicalOperator ? conditionGroup.node.logicalOperator : '',
                                    value: conditionGroup.node.id,
                                }))
                            }
                            placeholder="Select Logical Operator"
                            onChange={(option) => setLogicalOperator(option.value)}
                            value={allConditionGroups && logicalOperator ? { value: logicalOperator, label: allConditionGroups.edges.find(group => group.node.id === logicalOperator).node.logicalOperator } : null}
                        />
                    </FormGroup>
                    <br />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="fieldName">Field Name</Label>
                                <Select className="bos-custom-select"
                                    classNamePrefix="bos-select" isLoading={false}
                                    options={
                                        availableModel ? availableModel.availableModelFields.map((field) => ({
                                            label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                            value: field,
                                        })) : []
                                    }
                                    placeholder="Select Field of the the trigger model"
                                    onChange={(option) => handleConditionChange('fieldName', option.value)}
                                    value={availableModel && condition.fieldName ? { label: condition.fieldName, value: condition.fieldName } : null}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="conditionType">Condition Type</Label>
                                <Select className="bos-custom-select"
                                    classNamePrefix="bos-select" isLoading={false}
                                    options={
                                        conditionTypes && conditionTypes.states.map((item) => ({
                                            label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                            value: item.name,
                                        }))
                                    }
                                    placeholder="Select Condition Type"
                                    onChange={(option) => handleConditionChange('conditionType', option.value)}
                                    value={conditionTypes && condition.conditionType ? { label: condition.conditionType, value: condition.conditionType } : null}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="conditionValue">Condition Value</Label>
                                <Input
                                    type="text"
                                    id="conditionValue"
                                    placeholder="Enter Condition Value"
                                    value={condition.conditionValue}
                                    onChange={(e) => handleConditionChange('conditionValue', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-right">
                        <Button color="secondary" type="submit" disabled={!availableModel}>{isUpdate ? "Update Condition" : "Add Condition"}</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default compose(
    graphql(ConditionTypes, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, conditionTypes } }) {
            return { loading, conditionTypes }
        },
    }),
    graphql(ConditionQuery, {
        options: ({ conditionId }) => ({ variables: { id: conditionId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { condition } }) {
            return { conditionValue: condition }
        },
    }),
    graphql(AllTriggersQuery, {
        options: ({ automationId }) => ({
            variables: { automation: automationId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allTriggers } }) {
            return {
                allTriggersloading: loading, allTriggers,
            }
        },
    }),
    graphql(AvailableModelQuery, {
        options: ({ contentTypeId }) => ({
            variables: { contentTypeId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { availableModel, refetch } }) {
            return {
                availableModel,
            }
        },
    }),
    graphql(AllConditionGroupsQuery, {
        options: ({ automationId }) => ({ variables: { automation: automationId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading: stateloading, allConditionGroups } }) {
            return { stateloading, allConditionGroups }
        },
    }),
    graphql(CreateConditionMutation, { name: 'createCondition' }),
    graphql(UpdateConditionMutation, { name: 'updateCondition' }),
)(ConditionForm)
