import gql from "graphql-tag"

const AllRegistrationsQuery = gql`query AllRegistrationsQuery($first:Int!, $cursor:String, $orderBy:[String], $registrationsFor:String, $isInstalled:Boolean, $isActive:Boolean, $searchTerm:String, $registrationTypeIn:[ID], $carStageIn:[Int], $inspectionStatusIn:[String], $inInspection:Boolean){
    allRegistrations(first: $first, after: $cursor, orderBy: $orderBy, registrationsFor:$registrationsFor, isInstalled: $isInstalled, isActive:$isActive, searchTerm:$searchTerm, registrationTypeIn:$registrationTypeIn, carStageIn:$carStageIn, inspectionStatusIn:$inspectionStatusIn, inInspection:$inInspection){
        edges{
            node{
                id
                pk
                number
                dateAdded
                dateModified
                expirationDate
                notes
                metadata
                registrationType{
                    id
                    typeFor
                    name
                    stateCode
                }
                createdBy{
                    username
                    firstName
                    lastName
                }
                upcomingInspection{
                    id
                    dateAdded
                    dateModified
                    inspectionDate
                    status
                    runnerType
                    runner{
                        ... on DriverNode{
                            id
                            name
                            phone
                            tlcLicense
                        }
                        ... on UserNode{
                            id
                            username
                            firstName
                        lastName
                        }
                    }
                    notes
                    metadata
                    createdBy {
                        username
                        firstName
                        lastName
                    }
                    car {
                        id
                        pk
                        vin
                        color
                        year
                        stage
                        carModel {
                            name
                            make
                            series
                            groupType {
                                id
                                name
                            }
                        }
                        branch {
                            id
                            name
                        }
                    }
                }
                activeRegistration:activeObject{
                    ... on CarRegistrationNode{
                        id
                        isActive
                        createdBy{
                            username
                            firstName
                        lastName
                        }
                        car{
                            id
                            pk
                            vin
                            color
                            year
                            stage
                            carModel{
                                name
                                make
                                series
                                groupType{
                                    id
                                    name
                                }
                            }
                            branch{
                                id
                                name
                            }
                        }
                    }
                }
            }  
        } 
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const AllCarInspectionsQuery = gql`query AllCarInspectionsQuery($first:Int!, $cursor:String, $orderBy:[String], $isInstalled:Boolean, $isActive:Boolean, $searchTerm:String, $registrationTypeIn:[ID], $inspectionStatusIn:[String]){
    allCarInspections(first: $first, after: $cursor, orderBy: $orderBy, isInstalled: $isInstalled, isActive:$isActive, searchTerm:$searchTerm, registrationTypeIn:$registrationTypeIn, status_In:$inspectionStatusIn){
        edges {
            node {
                id
                dateAdded
                dateModified
                inspectionDate
                status
                runnerType
                runner{
                    ... on DriverNode{
                        id
                        name
                        phone
                        tlcLicense
                    }
                    ... on UserNode{
                        id
                        username
                        firstName
                        lastName
                    }
                }
                notes
                metadata
                createdBy {
                    username
                    firstName
                        lastName
                }
                registration {
                    id
                    pk
                    number
                    dateAdded
                    dateModified
                    expirationDate
                    notes
                    metadata
                    registrationType {
                        id
                        typeFor
                        name
                        stateCode
                    }
                    createdBy {
                        username
                        firstName
                        lastName
                    }
                }
                car {
                    id
                    pk
                    vin
                    color
                    year
                    stage
                    carModel {
                        name
                        make
                        series
                        groupType {
                        id
                        name
                        }
                    }
                    branch {
                        id
                        name
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`


const AllRegistrationTypesQuery = gql`query AllRegistrationTypesQuery($typeFor: String){
    allRegistrationTypes(isActive:true, typeFor: $typeFor){
        edges {
            node {
                id
                name
                stateCode
                isActive
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const RegistrationQuery = gql`query RegistrationQuery($id: ID, $pk: Int){
    registration(id: $id, pk: $pk){
        id
        pk
        number
        dateAdded
        dateModified
        expirationDate
        notes
        metadata
        registrationType{
            id
            typeFor
            name
            stateCode
        }
        createdBy{
            username
            firstName
                        lastName
        }
        upcomingInspection{
            id
            dateAdded
            dateModified
            inspectionDate
            status
            runnerType
            runner{
                ... on DriverNode{
                    id
                    name
                    phone
                    tlcLicense
                }
                ... on UserNode{
                    id
                    username
                    firstName
                        lastName
                }
            }
            notes
            metadata
            createdBy {
                username
                firstName
                        lastName
            }
            car {
                id
                pk
                vin
                color
                year
                stage
                carModel {
                    name
                    make
                    series
                    groupType {
                        id
                        name
                    }
                }
                branch {
                    id
                    name
                }
            }
        }
        activeRegistration:activeObject{
            ... on CarRegistrationNode{
                id
                isActive
                createdBy{
                    username
                    firstName
                        lastName
                }
                car{
                    id
                    pk
                    vin
                    color
                    year
                    stage
                    carModel{
                        name
                        make
                        series
                        groupType{
                            id
                            name
                        }
                    }
                    branch{
                        id
                        name
                    }
                }
            }
        }
    } 
}`

const AllCarRegistrationsQuery = gql`query AllCarRegistrationsQuery($first:Int!, $cursor:String, $orderBy:[String], $registrationId:ID){
    allCarRegistrations(first: $first, after: $cursor, orderBy: $orderBy, registrationId: $registrationId){
        edges{
            node {
                id
                dateAdded
                isInstalled
                isActive
                createdBy {
                    username
                    firstName
                        lastName
                }
                removedAt
                removedBy {
                    username
                    firstName
                        lastName
                }
                car {
                    id
                    pk
                    vin
                    color
                    year
                    stage
                    carModel {
                        name
                        make
                        series
                        groupType {
                            id
                            name
                        }
                    }
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }    
    } 
}`


const CarInspectionStatus = gql`query CarInspectionStatus{
    carInspectionStatus: __type(name: "CarInspectionStatus") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AllOwnersQuery = gql`query AllOwnersQuery{
    allOwners{
        edges{
            node{
                id
                pk
                name
            }
        }
    }   
}`

const CarSearchQuery = gql`query CarSearchQuery($searchTerm: String){
    allCars(searchTerm: $searchTerm, first:5){
        edges{
            node{
                id
                pk
                vin
                color
                year
                stage
                carModel{
                    name
                    make
                    series
                    groupType{
                        id
                        name
                    }
                }
                branch{
                    id
                    name
                }
            }
        }
    } 
}`

const RepSearchQuery = gql`query RepSearchQuery($searchTerm:String){
    allReps(username_Icontains:$searchTerm, first:5){
        edges{
            node{
                id
                username
                firstName
                lastName
            }
        }
    }   
}`

const DriverSearchQuery = gql`query DriverSearchQuery($searchTerm:String){
    allDrivers(searchTerm:$searchTerm, first:5){
        edges{
            node{
                id
                name
                phone
                tlcLicense
            }
        }
    }   
}`

const DMVSearchQuery = gql`query DMVSearchQuery($searchTerm:String){
    allRegistrations(searchTerm:$searchTerm, registrationTypeName: "DMV", first:5){
        edges{
            node{
                id
                number
                registrationType{
                    name
                }
            }
        }
    }   
}`

const RegistrationSearchQuery = gql`query RegistrationSearchQuery($searchTerm:String){
    allRegistrations(searchTerm:$searchTerm, first:5){
        edges{
            node{
                id
                pk
                number
                dateAdded
                dateModified
                expirationDate
                notes
                metadata
                registrationType {
                    id
                    typeFor
                    name
                    stateCode
                }
                createdBy {
                    username
                    firstName
                    lastName
                }
            }
        }
    }   
}`

const DriverQuery = gql`query DriverQuery($pk:Int!){
    driver(pk:$pk){
        id
        name
        phone
        tlcLicense
    }
}`

const UserQuery = gql`query UserQuery($pk:Int!){
    user(pk:$pk){
        id
        username
        firstName
        lastName
    }
}`


export { AllRegistrationsQuery, AllRegistrationTypesQuery, CarSearchQuery, AllCarRegistrationsQuery, RegistrationQuery, AllOwnersQuery, CarInspectionStatus, RepSearchQuery, DriverSearchQuery, DMVSearchQuery, DriverQuery, UserQuery, AllCarInspectionsQuery, RegistrationSearchQuery }