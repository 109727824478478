import React, { Component } from 'react'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Button, Card, CardBody, Table, Progress } from 'reactstrap'
import AuditLogModal from '../../Material/AuditLogModal'
import { Link } from 'react-router-dom'
import moment from 'moment'
import renderEnumToInt from "../../Functions/renderEnumToInt"

const AUDIT_STAGES = {
  "-1": "Unknown",
  "0": "Pending",
  "1": "Manager Approved",
  "2": "Top Management Approved",
}

const AuditLogsQuery = gql`query AuditLogsQuery($cursor: String, $searchTerm: String, $stage: String, $orderBy: [String], $auditReason: String, $representative: ID, $branch: ID)
{ allAuditLogs: allAuditLogs(auditStage:$stage, first:30, orderBy:$orderBy, after: $cursor, auditNotes_Icontains:$searchTerm, auditReason_Icontains:$auditReason, representative: $representative, branch: $branch){
    edges{
      node{
        id
        dateAdded
        agreement{
          id
          car{
            pk
          }
          driver{
            name
            id
          }
        }
        driver{
          name
          id
        }
        auditType
        auditStage
        auditReason
        auditNotes
        managerApproved
        managerApprovedDate
        topManagementApproved
        topManagementApprovedDate
        representative{
          id
          username
          firstName
          lastName
        }
      }
    }
    pageInfo{
      endCursor
      hasNextPage
    }
  } 
}`

const tableHeaders = [
  { id: "date_added", name: "Date", sortable: false },
  { id: "driver", name: "Driver", sortable: false },
  { id: "agreement", name: "Rental", sortable: false },
  { id: "auditStage", name: "Stage", sortable: false },
  { id: "audit_type", name: "Type", sortable: false },
  { id: "audit_reason", name: "Reason", sortable: false },
  { id: "audit_notes", name: "Notes", sortable: false },
  { id: "representative", name: "Rep", sortable: false },
  { id: "action", name: "Action", sortable: false }
]

class AuditList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: "",
    }
  }
  toggleModal = (modalName) => {
    if (this.state.openModal === modalName) {
      this.setState({ openModal: "" })
    } else {
      this.setState({ openModal: modalName })
    }
  }
  updateSelected = (value) => {
    let selectedLogs = this.props.selectedLogs
    if (selectedLogs.includes(value)) {
      selectedLogs = selectedLogs.filter(item => item !== value)
    } else {
      selectedLogs.push(value)
    }
    this.props.setSelectedLogs(selectedLogs)
  };
  toggleSelectAllClick = () => {
    if (this.props.allAuditLogs && !this.state.selectAll) {
      this.props.setSelectedLogs(this.props.allAuditLogs.edges.map(n => n.node.id))
      this.setState({ selectAll: true })
    } else {
      this.props.setSelectedLogs([])
      this.setState({ selectAll: false })
    }
  };

  render() {
    return (
      <div>
        {this.props.loading && <Progress style={{ marginTop: "10px", maxHeight: "5px" }} animated color="info" value="100" />}
        <Card className="audit-data-table-container">
          <CardBody>
            {/* {this.props.currentUser && this.state.selectedLog && this.state.openModal === "AuditLogDetails" &&
            <AuditLogModal auditLog={this.state.selectedLog} currentUser={this.props.currentUser} open={this.state.openModal === "AuditLogDetails"}
              handleClose={() => { this.setState({ selectedLog: null }); this.toggleModal("") }} refetchQuery={this.props.refetchAuditLogsQuery} />} */}
            <Table responsive>
              <thead>
                <tr>
                  <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                  {
                    tableHeaders.map(tableHeader => {
                      return (
                        tableHeader.sortable ?
                          <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                          <th key={tableHeader.id}>{tableHeader.name}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {this.props.allAuditLogs && this.props.allAuditLogs.edges && this.props.allAuditLogs.edges.length > 0 ? this.props.allAuditLogs.edges.map((auditLog, i) =>
                  <tr key={auditLog.node.id}>
                    <td scope="row" className="listing-checkbox-wrapper">
                      {!this.props.selectedLogs.includes(auditLog.node.id) && <span className="listing-counter">{i + 1}</span>}
                      <span className="listing-checkbox" onClick={() => this.updateSelected(auditLog.node.id)}>
                        {this.props.selectedLogs.includes(auditLog.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                      </span>
                    </td>
                    <td><span>{auditLog.node.dateAdded ? moment(auditLog.node.dateAdded).tz("America/New_York").format('lll') : ""}</span></td>
                    <td><span>{auditLog.node.driver ? <Link color="secondary" to={'/driver/' + auditLog.node.driver.id} target="_blank">{auditLog.node.driver.name}&nbsp;&nbsp;<i className="fa fa-external-link color-blue"></i></Link> : "--"}</span></td>
                    <td><span>{auditLog.node.agreement ? <span>{auditLog.node.agreement.car.pk} - {auditLog.node.agreement.driver.name}</span> : "--"}</span></td>
                    <td><span>{AUDIT_STAGES[renderEnumToInt(auditLog.node.auditStage)] || "--"}</span></td>
                    <td><span>{auditLog.node.auditType}</span></td>
                    <td><span>{auditLog.node.auditReason}</span></td>
                    <td><span>{auditLog.node.auditNotes}</span></td>
                    <td><span>{auditLog.node.representative ? auditLog.node.representative.firstName ? auditLog.node.representative.firstName + " " + auditLog.node.representative.lastName : auditLog.node.representative.username : ""}</span></td>
                    <td>
                      {"AuditLogDetails" + i == this.state.openModal && <AuditLogModal auditLog={auditLog.node} currentUser={this.props.currentUser} open={this.state.openModal === "AuditLogDetails" + i}
                        handleClose={() => this.toggleModal("AuditLogDetails" + i)} refetchQuery={this.props.refetchAuditLogsQuery} />}
                      <Button color="link" size="sm" onClick={() => this.toggleModal("AuditLogDetails" + i)}><small>DETAILS</small></Button>
                    </td>
                  </tr>) :
                  this.props.loading ?
                    <tr>
                      <th scope="row">&nbsp;</th>
                      <td colSpan={7}>Loading Audit Logs List...</td>
                    </tr> :
                    <tr>
                      <th scope="row">&nbsp;</th>
                      <td colSpan={7}>No AuditLogs Found</td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card >
        {this.props.allAuditLogs && this.props.allAuditLogs.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} className="float-right mt-2 btn btn-primary-showmore-outline">Show More <i className={"fa fa-caret-down"}></i></Button>}
      </div>
    )
  }
}


export default compose(
  graphql(AuditLogsQuery, {
    options: ({ searchTerm, stage, orderBy, auditReason, representative, branch }) => ({ variables: { searchTerm, stage, orderBy, auditReason, representative, branch } }),
    // This function re-runs every time `data` changes, including after `updateQuery`,
    // meaning our loadMoreEntries function will always have the right cursor
    props({ data: { loading, allAuditLogs, fetchMore, searchTerm, variables, auditReason } }) {
      return {
        loading, allAuditLogs, variables,
        refetchAuditLogsQuery: () => {
          //No apollo function that refetches in place with pagination considered so this function instead
          let currentLength = allAuditLogs && allAuditLogs.edges && allAuditLogs.edges.length > 0 ? allAuditLogs.edges.length : 30
          return fetchMore({
            query: AuditLogsQuery,
            variables: {
              ...variables,
              first: currentLength,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.allAuditLogs.edges
              const pageInfo = fetchMoreResult.allAuditLogs.pageInfo
              return {
                allAuditLogs: {
                  edges: [...newEdges],
                  pageInfo,
                  searchTerm: previousResult.searchTerm,
                  auditReason: previousResult.auditReason,
                  __typename: previousResult.allAuditLogs.__typename
                },
              }
            },
          })
        },
        loadMoreEntries: () => {
          return fetchMore({
            query: AuditLogsQuery,
            variables: {
              cursor: allAuditLogs.pageInfo.endCursor,
              ...variables
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.allAuditLogs.edges
              const pageInfo = fetchMoreResult.allAuditLogs.pageInfo
              return {
                // Put the new comments at the end of the list and update `pageInfo`
                // so we have the new `endCursor` and `hasNextPage` values
                allAuditLogs: {
                  edges: [...previousResult.allAuditLogs.edges, ...newEdges],
                  pageInfo,
                  searchTerm: previousResult.searchTerm,
                  auditReason: previousResult.auditReason,
                  __typename: previousResult.allAuditLogs.__typename
                },
              }
            },
          })
        },
      }
    },
  })
)(AuditList)