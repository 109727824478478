import React, { useState } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, Badge } from 'reactstrap'
import Loader from "../Material/Loader"
import { HasPermissionsQuery } from "../Functions/querys"
import { AutomationQuery } from "./Queries"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import ActionForm from "./ActionForm"
import TriggerForm from "./TriggerForm"
import ConditionForm from "./ConditionForm"
import "./Automately.css"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import ConditionGroupForm from "./ConditionGroupForm"
import { DeleteActionMutation, UpdateAutomationMutation, DeleteTriggerMutation, DeleteConditionMutation } from "./Mutations"
import CopyToClipboard from "../Material/CopyToClipboard"

const OverviewTab = (props) => {
    const { actionLoading, automation, refetchQuery, automationId, deleteAction, deleteTrigger, deleteCondition, updateAutomation } = props
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState("")
    const [loading, setLoading] = useState(false)
    const [actionDeleted, setActionDeleted] = useState(false)
    const [triggerDeleted, setTriggerDeleted] = useState(false)
    const [conditionDeleted, setConditionDeleted] = useState(false)
    const [editField, setEditField] = useState("")
    const [value, setValue] = useState("")

    const toggleModal = (modalName) => {
        if (openModal === modalName) {
            setOpenModal("")
        } else {
            setOpenModal(modalName)
        }
    }

    const updateInput = (e) => {
        let value = e.target.value
        setValue(value)
    }

    const automationUpdate = (name, value) => {
        setLoading(true)
        updateAutomation({
            variables: {
                input: {
                    id: automationId,
                    [name]: value,
                },
            },
        }).then(({ data }) => {
            if (data.updateAutomation.ok) {
                setLoading(false)
                setEditField("")
                refetchQuery()
            } else {
                setError(data.updateAutomation.errors[0].messages[0])
                setLoading(false)
            }
        })
    }

    const actionDelete = (actionId) => {
        setLoading(true)
        deleteAction({
            variables: {
                input: {
                    id: actionId,
                },
            },
        }).then(({ data }) => {
            if (data.deleteAction.ok) {
                setLoading(false)
                toggleModal("")
                setActionDeleted(true)
                refetchQuery()
            } else {
                setError(data.deleteAction.errors[0].messages[0])
                setLoading(false)
            }
        })
    }

    const triggerDelete = (triggerId) => {
        setLoading(true)
        deleteTrigger({
            variables: {
                input: {
                    id: triggerId,
                },
            },
        }).then(({ data }) => {
            if (data.deleteTrigger.ok) {
                setLoading(false)
                toggleModal("")
                setTriggerDeleted(true)
                refetchQuery()
            } else {
                setError(data.deleteTrigger.errors[0].messages[0])
                setLoading(false)
            }
        })
    }

    const conditionDelete = (conditionId) => {
        setLoading(true)
        deleteCondition({
            variables: {
                input: {
                    id: conditionId,
                },
            },
        }).then(({ data }) => {
            if (data.deleteCondition.ok) {
                setLoading(false)
                toggleModal("")
                setConditionDeleted(true)
                refetchQuery()
            } else {
                setError(data.deleteCondition.errors[0].messages[0])
                setLoading(false)
            }
        })
    }

    return (
        <Container fluid>
            {actionLoading || !automation ? <Loader /> : <>
                <Row>
                    {openModal === "newAction" && <ActionForm isOpen={openModal === "newAction"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
                    {openModal === "newTrigger" && <TriggerForm isOpen={openModal === "newTrigger"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
                    {openModal === "newCondition" && <ConditionForm isOpen={openModal === "newCondition"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
                    {openModal === "newLogicalCondition" && <ConditionGroupForm isOpen={openModal === "newLogicalCondition"} automationId={automationId} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
                    <Col xs={12} className="bos-object-section-wrapper">
                        <h4>AUTOMATION INFO</h4>
                        <Row className="bos-object-section">
                            <Col xs={4}>
                                <Row>
                                    <Col xs={6}>
                                        <Label>Name</Label>
                                    </Col>
                                    <Col className="text-right" xs={6}>
                                        {editField !== "name" ?
                                            <>
                                                <i className="fa fa-lg fa-pencil-square" style={{ marginTop: "10px" }} onClick={() => setEditField("name")}></i>&nbsp;&nbsp;
                                                <CopyToClipboard textToCopy={automation && automation.name ? automation.name : ""} displayText={"name"} />
                                            </>
                                            :
                                            <div>
                                                <i className="fa fa-lg fa-times" style={{ marginTop: "10px" }} onClick={() => setEditField("")}></i>&nbsp;&nbsp;
                                                <i className="fa fa-lg fa-check" onClick={() => automationUpdate("name", value)}></i>
                                            </div>
                                        }

                                    </Col>
                                </Row>
                                <Input disabled={editField !== "name"} defaultValue={automation && automation.name ? automation.name : "--"} onChange={updateInput} />

                            </Col>
                            <Col xs={3}>
                                <Row>
                                    <Col xs={6}>
                                        <Label>Is Active</Label>
                                    </Col>
                                    <Col className="text-right" xs={6}>
                                        {editField !== "isActive" ?
                                            <>
                                                <i className="fa fa-lg fa-pencil-square" style={{ marginTop: "10px" }} onClick={() => setEditField("isActive")}></i>&nbsp;&nbsp;
                                            </>
                                            :
                                            <div>
                                                <i className="fa fa-lg fa-times" style={{ marginTop: "10px" }} onClick={() => setEditField("")}></i>&nbsp;&nbsp;
                                                <i className="fa fa-lg fa-check" onClick={() => automationUpdate("isActive", value === "true" ? true : false)}></i>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Input type="select" disabled={editField !== "isActive"} className="box-object-property-input" defaultValue={automation && automation.isActive ? "true" : "false"}>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </Input>
                            </Col>
                            <Col xs={2}>
                                <Label>Date Added</Label>
                                <DatetimeRenderer datetime={automation.dateAdded} />
                            </Col>
                            <Col xs={3}>
                                <Label>Created By</Label>
                                <Input disabled className="box-object-property-input" value={automation.createdBy ? automation.createdBy.username : "--"} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} className="bos-object-section-wrapper">
                        <Row>
                            <Col>
                                <h4>TRIGGER</h4>
                            </Col>
                            <Col>
                                {automation && !automation.trigger &&
                                    <a id="newTrigger" className="driver-list-button float-right" onClick={() => toggleModal("newTrigger")}>
                                        New Trigger | <i className="fa fa-filter" aria-hidden="true"></i>
                                    </a>
                                }
                            </Col>
                        </Row>
                        <Row className="bos-object-section">
                            <Col xs={12}>
                                <Row className="mb-2">
                                    <Col xs={4}><Label>Trigger Type</Label></Col>
                                    <Col xs={4}><Label>Model</Label></Col>
                                    <Col xs={4}><Label>Action</Label></Col>
                                </Row>
                            </Col>
                            {automation.trigger &&
                                <React.Fragment key={automation.trigger.id}>
                                    {openModal === "editTrigger" && <TriggerForm triggerId={automation.trigger.id} isOpen={openModal === "editTrigger"} isUpdate={true} handleClose={() => setOpenModal("")} refetchQuery={refetchQuery} />}
                                    {openModal === "deleteTrigger" &&
                                        <ConfirmationPopup
                                            open={openModal === "deleteTrigger"}
                                            message={"Are you sure you want to delete this trigger?"}
                                            loading={loading}
                                            handleClose={() => toggleModal("")}
                                            action={"Yes"}
                                            confirmAction={() => triggerDelete(automation.trigger.id)}
                                            color="danger"
                                            error={error ? error : ""}
                                            success={triggerDeleted ? "Trigger Deleted Successfully" : ""}
                                        />
                                    }
                                    <Col xs={4}>
                                        <p>{automation.trigger.triggerType}</p>
                                    </Col>
                                    <Col xs={4}>
                                        <p>{automation.trigger.contentType.model}</p>
                                    </Col>
                                    <Col xs={4}>
                                        <i className="fa fa-pencil fa-lg" onClick={() => setOpenModal("editTrigger")} />
                                        {" "} | {" "}
                                        <i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteTrigger")} />
                                    </Col>
                                </React.Fragment>
                            }
                        </Row>
                    </Col>
                    <Col xs={6} className="bos-object-section-wrapper">
                        <Row>
                            <Col>
                                <h4>ACTIONS</h4>
                            </Col>
                            <Col>
                                <a id="newAction" className="driver-list-button float-right" onClick={() => toggleModal("newAction")}>
                                    New Action | <i className="fa fa-filter" aria-hidden="true"></i>
                                </a>
                            </Col>
                        </Row>
                        <Row className="bos-object-section">
                            <Col xs={12}>
                                <Row className="mb-2">
                                    <Col xs={3}><Label>Action Type</Label></Col>
                                    <Col xs={6}><Label>Action Params</Label></Col>
                                    <Col xs={3}><Label>Action</Label></Col>
                                </Row>
                                {automation.actionSet && automation.actionSet.edges && automation.actionSet.edges.map((action, index) =>
                                    <Row key={action.node.id}>
                                        {openModal === "action" + index && <ActionForm actionId={action.node.id} automationId={automation.id} contentTypeId={automation.trigger.contentType.id} isOpen={openModal === "action" + index} isUpdate={true} handleClose={() => setOpenModal("")} refetchQuery={refetchQuery} />}
                                        {openModal === "deleteAction" + index &&
                                            <ConfirmationPopup
                                                open={openModal === "deleteAction" + index}
                                                message={"Are you sure you want to delete this action?"}
                                                loading={loading}
                                                handleClose={() => toggleModal("")}
                                                action={"Yes"}
                                                confirmAction={() => actionDelete(action.node.id)}
                                                color="danger"
                                                error={error ? error : ""}
                                                success={actionDeleted ? "Action Deleted Successfully" : ""}
                                            />
                                        }
                                        <Col xs={3}>
                                            <p>{action.node.actionType}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p>{action.node.actionParams}</p>
                                        </Col>
                                        <Col xs={3}>
                                            <i className="fa fa-pencil fa-lg" onClick={() => setOpenModal("action" + index)} />
                                            {" "} | {" "}
                                            <i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteAction" + index)} />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="bos-object-section-wrapper">
                        <Row>
                            <Col>
                                <h4>Conditions</h4>
                            </Col>
                            <Col>
                                <a id="newCondition" className="driver-list-button float-right" onClick={() => toggleModal("newCondition")}>
                                    New Condition | <i className="fa fa-filter" aria-hidden="true"></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </a>
                                {automation.conditiongroupSet && automation.conditiongroupSet.edges && automation.conditiongroupSet.edges.length < 2 && <a id="newLogicalCondition" className="driver-list-button float-right" onClick={() => toggleModal("newLogicalCondition")}>
                                    Add Logical Operator | <i className="fa fa-filter" aria-hidden="true"></i>
                                </a>}
                            </Col>
                        </Row>
                        <Row className="bos-object-section">
                            <Col xs={12}>
                                <Row className="mb-2">
                                    <Col><Label>Operator</Label></Col>
                                    <Col><Label>Condition Type</Label></Col>
                                    <Col><Label>Field Name</Label></Col>
                                    <Col><Label>Condition Value</Label></Col>
                                    <Col><Label>Action</Label></Col>
                                </Row>
                                {automation.conditiongroupSet && automation.conditiongroupSet.edges && automation.conditiongroupSet.edges.map((conditionGroup, index) =>
                                (conditionGroup.node.conditionSet && conditionGroup.node.conditionSet.edges && conditionGroup.node.conditionSet.edges.length > 0 && conditionGroup.node.conditionSet.edges.map((condition, index) =>
                                    <Row key={condition.node.id}>

                                        {openModal === "deleteCondition" + index &&
                                            <ConfirmationPopup
                                                open={openModal === "deleteCondition" + index}
                                                message={"Are you sure you want to delete this condition?"}
                                                loading={loading}
                                                handleClose={() => toggleModal("")}
                                                action={"Yes"}
                                                confirmAction={() => conditionDelete(condition.node.id)}
                                                color="danger"
                                                error={error ? error : ""}
                                                success={conditionDeleted ? "Condition Deleted Successfully" : ""}
                                            />
                                        }
                                        {openModal === "editCondition" + index && <ConditionForm conditionId={condition.node.id} contentTypeId={automation.trigger.contentType.id} isOpen={openModal === "editCondition" + index} isUpdate={true} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}

                                        <Col>
                                            <p>{conditionGroup.node.logicalOperator}</p>
                                        </Col>
                                        <Col>
                                            <p>{condition.node.conditionType}</p>
                                        </Col>
                                        <Col>
                                            <p>{condition.node.fieldName}</p>
                                        </Col>
                                        <Col>
                                            <p>{condition.node.conditionValue}</p>
                                        </Col>
                                        <Col>
                                            <i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteCondition" + index)} />
                                            {" "} | {" "}
                                            <i className="fa fa-pencil fa-lg" onClick={() => setOpenModal("editCondition" + index)} />
                                        </Col>
                                    </Row>
                                ))
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>}
        </Container >
    )
}


export default compose(
    withApollo,
    graphql(AutomationQuery, {
        options: ({ automationId }) => ({ variables: { id: automationId } }),
        props({ data: { automation, loading, refetch, variables } }) {
            return {
                automation, actionLoading: loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: AutomationQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { automation: fetchMoreResult.automation }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_update_branch_status", "add_transactionsetting"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
    graphql(DeleteActionMutation, { name: 'deleteAction' }),
    graphql(DeleteTriggerMutation, { name: 'deleteTrigger' }),
    graphql(DeleteConditionMutation, { name: 'deleteCondition' }),
    graphql(UpdateAutomationMutation, { name: 'updateAutomation' }),
)(OverviewTab)
