import React, { Component } from 'react'
import { withApollo } from 'react-apollo/index'
import { Button, Col, Row, Input } from 'reactstrap'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import ListingFilters from "../../Material/ListingFilters"
import { debounce } from 'lodash'
import toTitleCase from "../../Functions/toTitleCase"

const editAuditLog = gql`
mutation editAuditLog($input: EditAuditLogInput!){
    editAuditLog(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}
`

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
          }
        }
      }
    }
`

const BranchQuery = gql`
    query AllBranch{
        optionsList: allBranches(orderBy:"-id"){
            edges{
                node{
                    id
                    customId
                    name
                }
            }
        }
    } 
`

class AuditListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            filters_configuration: null
        }
    }
    getfilterConfigurations = () => {
        return [
            { type: "text", name: "searchTerm", title: "Search Term", placeholder: "Search By Name, TLC, Phone", showFilter: false },
            { type: "text", name: "auditReason", title: "Audit Reason", placeholder: "Search By Reason", showFilter: true },
            {
                type: "select", name: "stage", title: "Audit Stage", options: [
                    { value: "-1", label: "Unknown" },
                    { value: "0", label: "Pending" },
                    { value: "1", label: "Manager Approved" },
                    { value: "2", label: "Top Management Approved" },
                ], placeholder: "Filter By Stage", showFilter: true
            },
            {
                type: "select", name: "representative", title: "Filter By Reps", optionsQuery: RepsQuery, labelSelector: "username", valueSelector: "id", placeholder: "Filter By Reps", showFilter: true
            },
            {
                type: "select", name: "branch", title: "Filter By Branch", optionsQuery: BranchQuery, labelSelector: "name", valueSelector: "id", placeholder: "Filter By Branch", showFilter: true
            }
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = debounce((searchTerm) => {
        this.props.setFilterValues({ ...this.props.filterValues, searchTerm })
    }, 500)
    approveSelected = () => {
        // check if rep is topmanagement before showing button
        const today = new Date().toISOString().slice(0, 10)
        let changeAuditInput = {
            auditStage: 1,
            managerApproved: this.props.currentUser ? this.props.currentUser.username : "None",
            managerApprovedDate: today,
            topManagementApprovedDate: today
        }
        this.props.selectedLogs.map((item) => {
            this.props.edit({ auditLog: item, ...changeAuditInput }).then(r => { r.data.editAuditLog.ok && this.props.setSelectedLogs([]) })
            return ''
        })
    }
    getValueByFilterType = (conf, value) => {
        if (conf.type === "select") {
            let options = conf["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (conf.type === "boolean") {
            value = toTitleCase(value.toString())
        }
        return Array.isArray(value) ? value.join(", ") : value

    }
    // removeFilter = (name) => {
    //     let confs = this.props.filtersValues
    //     let filterKeys = Object.keys(confs)
    //     if (filterKeys.includes(name)) {
    //         delete confs[name]
    //         this.props.setFilterValues({ ...confs })
    //     }
    // }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.props.setFilterValues({ ...filterValues })
        }
    }
    componentDidMount() {
        let confs = this.getfilterConfigurations()
        let configurations = confs.map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0)
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ filters_configuration: configurations })
    }

    render() {
        return (
            <Row>
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={this.props.setFilterValues}
                        filters={this.state.filters_configuration}
                    />}
                <Col xs={4}>
                    <span className="audit-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" value={this.props.filterValues.searchTerm} onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={4} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] && this.state.filters_configuration && this.state.filters_configuration.find(setting => setting.name == key) && this.state.filters_configuration.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.filters_configuration.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                            <span>{this.state.filters_configuration.find(setting => setting.name == key).title}</span>
                            {this.getValueByFilterType(this.state.filters_configuration.find(conf => conf.name === key), this.props.filterValues[key])}
                            {/* {Array.isArray(this.props.filterValues[key]) ? this.props.filterValues[key].join(", ") : this.props.filterValues[key]} */}
                        </span>
                    )}
                </Col>
                <Col xs="4" className="text-right mt-2">
                    {this.props.selectedLogs && this.props.selectedLogs.length > 0 && <><a className="audit-list-button" onClick={this.approveSelected}><i className="fa fa-eye" aria-hidden="true"></i> Approve Selected</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <a id="viewFilters" className="audit-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] && this.state.filters_configuration && this.state.filters_configuration.find(setting => setting.name == key) && this.state.filters_configuration.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(editAuditLog, {
        props: ({ mutate }) => ({ edit: (input) => mutate({ variables: { input } }), }),
        options: { refetchQueries: ['AuditLogsQuery',], },
    }),
)(AuditListHeader)