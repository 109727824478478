import React, { Component } from "react"
import { compose, graphql, withApollo } from "react-apollo"
import { Modal, ModalHeader, ModalBody, FormGroup, Col, Row, Alert, Input, ModalFooter, Button } from "reactstrap"
import Select from "react-select"
import { AllBranches, AllChargeTypes } from "../Queries"
import Label from "reactstrap/lib/Label"
import { CreateMultipleSalesTax } from "../Mutations"


class NewTaxRateModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {
                chargeTypeId: [],
                branchIds: []
            },
            errorMessage: "",
            loading: false
        }
    }

    createSalesTax = async () => {
        this.setState({ loading: true })
        if (this.state.input.chargeTypeId
            && this.state.input.branchIds.length > 0) {
            try {
                const result = await this.props.client.mutate({
                    mutation: CreateMultipleSalesTax, //CreateSalesTax,
                    variables: { input: { ...this.state.input } }
                })
                if (result && result.data
                    && result.data.createMultipleSalesTax
                    && result.data.createMultipleSalesTax.ok) {
                    this.setState({ errorMessage: "" })
                    this.props.handleClose()
                }
                else {
                    let errorMessage = "An error has ocurred, the request could not be complete "
                    if (result.data && result.data.createMultipleSalesTax && result.data.createMultipleSalesTax.errors && result.data.createMultipleSalesTax.errors[0] && result.data.createMultipleSalesTax.errors[0].messages) {
                        errorMessage += result.data.createMultipleSalesTax.errors[0].messages
                    }
                    this.setState({ errorMessage, loading: false })
                }

            } catch (error) {
                let errorMessage = "An error has ocurred " + error
                this.setState({ errorMessage, loading: false })
            }
        }
        else {
            this.setState({ errorMessage: "Missing required Field", loading: false })
        }
    }

    updateInput = (e) => {
        let { name, value } = e.target
        if (value === "null") value = null
        if (e.target.dataset.type) value = value === "false" ? false : true
        this.setState({ input: { ...this.state.input, [name]: value } })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="box-create-tenant-modal">
                    <ModalHeader>NEW SALES TAX ITEM</ModalHeader>
                    <ModalBody>

                        <Row>
                            {this.state.errorMessage && <Col xs={12}><Alert>{this.state.errorMessage}</Alert></Col>}
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>Charge Type *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={(this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0) ? this.props.allChargeTypes.edges.map(chargeType => ({ label: chargeType.node.name, value: chargeType.node.id })) : []}
                                        onChange={(chargeType) => this.setState({ input: { ...this.state.input, chargeTypeId: chargeType.map(item => item.value) } })}
                                        isMulti
                                        placeholder="Select Charge Type"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>Branches *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        options={(this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0) ? this.props.allBranches.edges.map(branch => ({ label: branch.node.name, value: branch.node.id })) : []}
                                        onChange={(branch) => this.setState({ input: { ...this.state.input, branchIds: branch.map(item => item.value) } })}
                                        isMulti
                                        placeholder="Select Branches"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="new-tenant-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>&nbsp;&nbsp;
                        <Button className="new-tenant-modal-primary-btn" onClick={this.createSalesTax} disabled={this.state.loading}>Add Sales Tax</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllBranches, { props({ data: { allBranches } }) { return { allBranches } } }),
    graphql(AllChargeTypes, { props({ data: { allChargeTypes } }) { return { allChargeTypes } } }),
)(NewTaxRateModal)
