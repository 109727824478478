import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    Input,
    Badge,
    FormGroup,
    Progress,
    Alert
} from 'reactstrap'
import { AllEquipmentItems } from '../Queries'
import { InstallCarEquipment } from '../Mutations'

class InstallEquipment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: 1,
            error: "",
            openModal: "",
            equipment: null,
            customId: null
        }
    }

    addCarEquipment = (e) => {
        e.preventDefault()
        if (this.state.equipment) {
            this.setState({ loading: true })
            let input = { carPk: this.props.pk, equipmentItemPk: this.state.equipment }
            if (this.state.customId)
                input['customDeviceId'] = this.state.customId

            this.props.client.mutate({
                mutation: InstallCarEquipment,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.createEquipment && result.data.createEquipment.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ loading: false })
                } else if (result && result.data && result.data.createEquipment && result.data.createEquipment.errors && result.data.createEquipment.errors.length > 0) {
                    this.setState({ error: result.data.createEquipment.errors[0].messages[0] })
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false })
                    this.setState({ error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false })
                this.setState({ error: err })
            })
        }
    }


    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> Install Equipment</ModalHeader>
            <ModalBody>
                {this.props.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                {this.props.error && <Row><Col xs={12}><Alert color="danger">{this.props.error}</Alert></Col></Row>}
                <FormGroup>
                    <Label for="equipmentItem" className="float-left"><b>Equipment Name</b></Label>
                    <Input type="select" name="equipmentItem" id="equipmentItem" defaultValue="" value={this.props.equipment} onChange={(e) => this.setState({ equipment: e.target.value })}>
                        <option disabled value="">-- Select Equipment To Add -- </option>
                        {this.props && this.props.allEquipmentItems && this.props.allEquipmentItems.edges && this.props.allEquipmentItems.edges.length > 0 && this.props.allEquipmentItems.edges.map(item =>
                            <option value={item.node.pk}>{item.node.name}</option>
                        )}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="customDeviceId" className="float-left"><b>Custom Device ID</b></Label>
                    <Input type="text" name="customDeviceId" id="customDeviceId" value={this.props.customId} onChange={(e) => this.setState({ customId: e.target.value })} placeholder="Custom Device ID (if any)" />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                <Button type="submit" color="primary" onClick={this.addCarEquipment}>Install Equipment</Button>
            </ModalFooter>
        </Modal>
    };
}
export default compose(
    withApollo,
    graphql(AllEquipmentItems, {
        options: () => ({
            variables: {
                first: 8
            }
        }),
        props({ data: { allEquipmentItems } }) {
            return { allEquipmentItems }
        }
    }
    ),
)(InstallEquipment)