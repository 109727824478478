import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input } from 'reactstrap'
import Loader from "../../../Material/Loader"
import moment from "moment"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import UpdateTenantInfo from "../UpdateTenantInfo"

const TenantDetailQuery = gql`query Tenant($id: ID!){
    tenant(id: $id){
        id
        dateAdded
        dateModified
        name
        customId
        description
        defaultBranch {
            id
            name
        }
        logoImage
        isActive
        metadata
        createdBy {
            id
            username
            firstName
            lastName
        }
        history {
            date
            user
            comment
        }
    }
}`

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.tenant ? <Loader /> : <>
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;BRANCH INFO</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col xs={3}>
                                    <Label>Date Added</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.tenant.dateAdded).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Date Modified</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.tenant.dateModified).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Created By</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.createdBy ? this.props.tenant.createdBy.firstName ? this.props.tenant.createdBy.firstName + " " + this.props.tenant.createdBy.lastName : this.props.tenant.createdBy.username : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Custom ID</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.customId} />
                                </Col>
                                <Col xs={3}>
                                    <Label id="tenant-name">Tenant Name <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('tenantNameEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.name} />
                                    {this.state.openModal === 'tenantNameEdit' && <UpdateTenantInfo tenantId={this.props.tenant.id} title="Edit Tenant Name" name="name" value={this.props.tenant.name}
                                        target="tenant-name" type="text" open={this.state.openModal === 'tenantNameEdit'} handleClose={() => this.toggleModal("")} refetchTenantQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="tenant-default-branch">Default Branch <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('tenantDefaultBranchEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.defaultBranch ? this.props.tenant.defaultBranch.name : "--"} />
                                    {this.state.openModal === 'tenantDefaultBranchEdit' && <UpdateTenantInfo tenantId={this.props.tenant.id} title="Edit Default Branch" name="defaultBranch" value={this.props.tenant.defaultBranch.id}
                                        target="tenant-default-branch" type="select" open={this.state.openModal === 'tenantDefaultBranchEdit'} handleClose={() => this.toggleModal("")} refetchTenantQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="tenant-description">Description <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('tenantDescriptionEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.description} />
                                    {this.state.openModal === 'tenantDescriptionEdit' && <UpdateTenantInfo tenantId={this.props.tenant.id} title="Edit Tenant Description" name="description" value={this.props.tenant.description}
                                        target="tenant-description" type="text" open={this.state.openModal === 'tenantDescriptionEdit'} handleClose={() => this.toggleModal("")} refetchTenantQuery={this.props.refetchQuery} />}
                                </Col>
                                <Col xs={3}>
                                    <Label id="tenant-isactive">Is Active <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('tenantIsActive')} /></Label>
                                    {this.state.openModal === 'tenantIsActive' && <UpdateTenantInfo tenantId={this.props.tenant.id} title="Edit Is Active" name="isActive" value={this.props.tenant.isActive}
                                        target="tenant-isactive" type="boolean" open={this.state.openModal === 'tenantIsActive'} handleClose={() => this.toggleModal("")} refetchTenantQuery={this.props.refetchQuery} />}
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isActive"
                                            id="isActive"
                                            disabled
                                            checked={!this.props.tenant.isActive}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isActive">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={12}>
                                    <Label id="tenant-logo-url">Logo Url <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('tenantLogoEdit')} /></Label>
                                    <Input disabled className="box-object-property-input" value={this.props.tenant.logoImage} />
                                    {this.state.openModal === 'tenantLogoEdit' && <UpdateTenantInfo tenantId={this.props.tenant.id} title="Edit Tenant Logo Url" name="logoImage" value={this.props.tenant.logoImage}
                                        target="tenant-logo-url" type="text" open={this.state.openModal === 'tenantLogoEdit'} handleClose={() => this.toggleModal("")} refetchTenantQuery={this.props.refetchQuery} />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;HISTORY</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section" style={{ maxHeight: "400px", overflow: "auto" }}>
                                <Col xs={4}><Label>Date</Label></Col>
                                <Col xs={4}><Label>Comment</Label></Col>
                                <Col xs={4}><Label>Username</Label></Col>
                                {this.props.tenant && this.props.tenant.history && this.props.tenant.history.length > 0 ? this.props.tenant.history.map((history, i) =>
                                    <>
                                        <Col xs={4}>
                                            <p className="columnContent">{history.date ? <DatetimeRenderer datetime={history.date} /> : ""}</p>
                                        </Col>
                                        <Col xs={4}>
                                            <p className="columnContent">{history.comment || ""}</p>
                                        </Col>
                                        <Col xs={4}>
                                            <p className="columnContent">{history.user || ""}</p>
                                        </Col>
                                    </>
                                ) :
                                    <Col><p> No tenant history found!</p></Col>}
                            </Row>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


export default compose(
    withApollo,
    graphql(TenantDetailQuery, {
        options: ({ tenantId }) => ({ variables: { id: tenantId } }),
        props({ data: { tenant, loading, refetch, variables } }) {
            return {
                tenant, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: TenantDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { tenant: fetchMoreResult.tenant }
                        },
                    })
                },
            }
        }
    }),

)(OverviewTab)
