import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import './PaymentPlan.css'
import './TransactionInfo.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, small, Col, Row, b, Label, Alert, FormGroup, Popover, PopoverBody, PopoverHeader, Progress } from 'reactstrap'
import UpdateTransactionInfo from './UpdateTransactionInfo'
import moment from 'moment-timezone'
import { HasPermissionsQuery } from "../../Functions/querys"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import Loader from "../../Material/Loader"

const DeleteTransactionMutation = gql`mutation deleteTransaction($input: DeleteTransactionMutationInput!){
    deleteTransaction(input: $input){
        ok
        errors{
            messages
        }
    }
}`

const TransactionQuery = gql`query Transaction($id: ID!){
    transaction(id: $id){
        id
        pk
        idempotencyKey
        amount
        dueDate
        eventDatetime
        dateAdded
        dateModified
        notes
        balance
        changeReason
        isDeleted
        status
        chargeType{
            id
            name
        }
        createdBy{
            id
            username
            firstName
            lastName
        }
        updatedBy{
            id
            username
            firstName
            lastName
        }
        groupType{
            id
            name
        }
        agreement{
            id
            car{
                id
                pk
            }
        }
        driver{
            id
            name
        }
        branch{
            id
            name
            tenant{
                id
            }
        }
        updates {
            edges {
                node {
                    id
                    pk
                    idempotencyKey
                    amount
                    dueDate
                    eventDatetime
                    dateAdded
                    dateModified
                    notes
                    balance
                    changeReason
                    isDeleted
                    status
                    chargeType{
                        id
                        name
                    }
                    createdBy{
                        id
                        username
                        firstName
                        lastName
                    }
                    updatedBy{
                        id
                        username
                        firstName
                        lastName
                    }
                    groupType{
                        id
                        name
                    }
                    agreement{
                        id
                        car{
                            id
                            pk
                        }
                    }
                    driver{
                        id
                        name
                    }
                    branch{
                        id
                        name
                        tenant{
                            id
                        }
                    }
                }
            }
        }
    }
}`

class TransactionInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            openModal: "",
            allRentals: null,
            selectedRental: "",
            transactionDeleted: false,
            openSection: ""
        }
    }

    handleClose = (e) => {
        this.props.handleClose()
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };

    toggleSection = (section) => {
        if (this.state.openSection === section) {
            this.setState({ openSection: "" })
        } else {
            this.setState({ openSection: section })
        }
    };

    deleteTransaction = () => {
        this.setState({ loading: true })
        this.props.client.mutate({
            mutation: DeleteTransactionMutation,
            variables: { input: { id: this.props.transaction.id } },
        }).then((result) => {
            if (result.data && result.data.deleteTransaction && result.data.deleteTransaction.ok) {
                this.setState({ transactionDeleted: true, error: null, loading: false })
                this.props.refetchQuery()
                this.props.refetchDriverQuery()
                this.handleClose()
            } else {
                this.setState({ loading: false, transactionDeleted: false, error: result.data.deleteTransaction.errors[0].messages[0] })
            }
        }).catch((error) => {
            let errorMessage = "An error has occured!"
            this.setState({ error: errorMessage, loading: false, transactionDeleted: false, })
        })
    }

    refetchQuery = (e) => {
        this.props.refetchTransactionQuery()
        this.props.refetchQuery()
    }

    componentDidUpdate(prevProps) {
        if (this.props.transaction && prevProps.transaction !== this.props.transaction && this.props.transaction.pk) {
            this.setState({
                openSection: "transactionInfo" + this.props.transaction.pk
            })
        }
    }

    renderTransaction = (transaction) => {
        return <>
            <Label className={`toggle-section-heading ${this.state.openSection.includes("transactionInfo" + transaction.pk) ? "active" : ""}`} onClick={() => this.toggleSection("transactionInfo" + transaction.pk)}>
                {transaction.pk} - {transaction.chargeType ? transaction.chargeType.name : '--'}<small>{transaction.groupType ? " (" + transaction.groupType.name + ")" : ''}</small> (${transaction.amount}) {this.state.openSection.includes("transactionInfo" + transaction.pk) ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}
            </Label>
            {this.state.openSection === "transactionInfo" + transaction.pk && <>
                <Row>
                    <Col>
                        <FormGroup>
                            <small>Date Added</small>
                            <br /><b>{transaction.dateAdded ? moment(transaction.dateAdded).tz("America/New_York").format("lll") : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small>Date Modified</small>
                            <br /><b>{transaction.dateModified ? moment(transaction.dateModified).tz("America/New_York").format("lll") : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small>Event Date</small>
                            <br /><b>{transaction.eventDatetime ? moment(transaction.eventDatetime).tz("America/New_York").format("lll") : "--"}</b>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <small>Due Date</small>
                            <br /><b>{transaction.dueDate ? moment(transaction.dueDate).tz("America/New_York").format("ll") : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small>Created By</small>
                            <br /><b>{transaction.createdBy ? transaction.createdBy.firstName ? transaction.createdBy.firstName + " " + transaction.createdBy.lastName : transaction.createdBy.username : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small>Updated By</small>
                            <br /><b>{transaction.updatedBy ? transaction.updatedBy.firstName ? transaction.updatedBy.firstName + " " + transaction.updatedBy.lastName : transaction.updatedBy.username : "--"}</b>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <small>Charge Type</small>
                            <br /><b>{transaction.chargeType ? transaction.chargeType.name : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small>Group Type</small>
                            <br /><b>{transaction.groupType ? transaction.groupType.name : "--"}</b>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small id={"amountEdit" + transaction.pk} onClick={() => this.toggleModal("amountEdit" + transaction.pk)}>Amount  <i className="fa fa-pencil-square" aria-hidden="true"> </i></small>
                            <br /><b>${transaction.amount ? transaction.amount : "--"}</b>
                        </FormGroup>
                        {this.state.openModal === "amountEdit" + transaction.pk &&
                            <UpdateTransactionInfo transactionId={transaction.id} title="Edit Amount" name="amount" value={transaction.amount}
                                target={"amountEdit" + transaction.pk} type="text" open={this.state.openModal === "amountEdit" + transaction.pk} canForceUpdate={this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_force_update_transaction")}
                                handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <small id={"driverEdit" + transaction.pk} onClick={() => this.toggleModal("driverEdit" + transaction.pk)}>Driver {transaction.chargeType && ["Zelle Payment"].includes(transaction.chargeType.name) && <i className="fa fa-pencil-square" aria-hidden="true"> </i>}</small>
                            <br /><b>{transaction.driver && transaction.driver.name ? transaction.driver.name : "--"}</b>
                            {this.state.openModal === "driverEdit" + transaction.pk &&
                                <UpdateTransactionInfo transactionId={transaction.id} title="Edit Driver" name="driver" value={transaction.driver ? transaction.driver.name : '--'}
                                    target={"driverEdit" + transaction.pk} type="text" open={this.state.openModal === "driverEdit" + transaction.pk}
                                    handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                                />
                            }
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <small id={"editCarId" + transaction.pk}>Agreement {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_transaction_rental") && <i onClick={() => this.toggleModal("editCarId" + transaction.pk)} className="fa fa-pencil-square" aria-hidden="true" />}</small>
                            <br /><b>{transaction.agreement && transaction.agreement.car && transaction.agreement.car ? "Car " + transaction.agreement.car.pk : "--"}</b>
                        </FormGroup>
                    </Col>
                    {this.state.openModal === "editCarId" + transaction.pk &&
                        <UpdateTransactionInfo transactionId={transaction.id} title="Edit Rental" driverId={transaction.driver.id} name="agreementId" value={transaction.agreement ? transaction.agreement.id : null}
                            target={"editCarId" + transaction.pk} type="text" open={this.state.openModal === "editCarId" + transaction.pk}
                            handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} currentUser={this.props.currentUser}
                        />
                    }
                    <Col>
                        <FormGroup>
                            <small id={"reasonEdit" + transaction.pk} onClick={() => this.toggleModal("reasonEdit" + transaction.pk)}>Change Reason <i className="fa fa-pencil-square" aria-hidden="true"> </i></small>
                            <br /><b>{transaction.changeReason ? transaction.changeReason : "--"}</b>
                        </FormGroup>
                        {this.state.openModal === "reasonEdit" + transaction.pk &&
                            <UpdateTransactionInfo transactionId={transaction.id} title="Edit Change Reason" name="changeReason" value={transaction.changeReason}
                                target={"reasonEdit" + transaction.pk} type="text" open={this.state.openModal === "reasonEdit" + transaction.pk}
                                handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <small id="editBranchId">Branch {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_transaction_branch") && <i onClick={() => this.toggleModal("editBranchId")} className="fa fa-pencil-square" aria-hidden="true" />}</small>
                            <br /><Badge className='bos-badge-blue'>{transaction.branch ? transaction.branch.name : "--"}</Badge>
                        </FormGroup>
                        {this.state.openModal === "editBranchId" &&
                            <UpdateTransactionInfo transactionId={transaction.id} tenantId={transaction.branch.tenant.id} title="Edit Branch" name="branchId" value={transaction.branch ? transaction.branch.id : null}
                                target={"editBranchId"} type="text" open={this.state.openModal === "editBranchId"}
                                handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} currentUser={this.props.currentUser}
                            />
                        }
                    </Col >
                    <Col xs={4}>
                        <small>Idempotency Key</small>
                        <br /><b><small>{transaction.idempotencyKey ? transaction.idempotencyKey : "--"}</small></b>
                    </Col>
                    {transaction && transaction.amount && transaction.amount < 0 &&
                        <Col>
                            <FormGroup>
                                <small>Download Reciept</small>
                                <br /><b>
                                    <a href={"/billing/invoice/download_receipt/?id=" + transaction.pk} target="_blank" className="ghost btn btn-secondary btn-sm">Download Receipt</a>
                                </b>
                            </FormGroup>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <small id={"notesEdit" + transaction.pk} onClick={() => this.toggleModal("notesEdit" + transaction.pk)}>Notes <i className="fa fa-pencil-square" aria-hidden="true"> </i></small>
                            <br /><b>{transaction.notes ? transaction.notes : "--"}</b>
                        </FormGroup>
                        {this.state.openModal === "notesEdit" + transaction.pk &&
                            <UpdateTransactionInfo transactionId={transaction.id} title="Edit Notes" name="notes" value={transaction.notes}
                                target={"notesEdit" + transaction.pk} type="text" open={this.state.openModal === "notesEdit" + transaction.pk}
                                handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery}
                            />
                        }
                    </Col>
                </Row >
            </>
            }
        </>
    }

    render() {
        let transaction = this.props.transaction
        return (
            <div>
                <Modal isOpen={this.props.open} className="transaction-info-modal">
                    <ModalHeader>Transaction Info </ModalHeader>
                    <ModalBody>
                        {this.props.loading ?
                            <Loader /> :
                            !this.props.loading && transaction ? <>
                                {this.renderTransaction(transaction)}
                                {transaction && transaction.updates && transaction.updates.edges && transaction.updates.edges.length > 0 && transaction.updates.edges.map(item => this.renderTransaction(item.node))}
                            </>
                                :
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <br /><b>No transaction info available</b>
                                        </FormGroup>
                                    </Col>
                                </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {transaction && transaction.isDeleted === false && this.props.hasPermissions && this.props.hasPermissions.includes("delete_transaction") &&
                            <Col className="d-flex justify-content-start">
                                <Button className="transaction-info-modal-danger-btn" onClick={() => this.toggleModal("deleteTransaction" + transaction.id)}>Delete</Button>
                                {this.state.openModal === "deleteTransaction" + transaction.id &&
                                    <ConfirmationPopup
                                        open={this.state.openModal === "deleteTransaction" + transaction.id}
                                        message={"Are you sure you want to delete this transaction?"}
                                        loading={this.state.loading}
                                        handleClose={() => this.toggleModal("")}
                                        action={"Yes"}
                                        confirmAction={this.deleteTransaction}
                                        color="danger"
                                        error={this.state.error ? this.state.error : ""}
                                        success={this.state.transactionDeleted ? "Action Performed Successfully" : ""}
                                    />
                                }
                            </Col>
                        }
                        <Col className="d-flex justify-content-end">
                            <Button className="transaction-info-modal-secondary-btn" onClick={this.handleClose}>Close</Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(TransactionQuery, {
        options: ({ id }) => ({ variables: { id: id }, fetchPolicy: 'cache-and-network', }),
        props({ data: { transaction, loading, refetch, variables } }) {
            return {
                transaction, loading, variables,
                refetchTransactionQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: TransactionQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { transaction: fetchMoreResult.transaction }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_force_update_transaction", "delete_transaction", "custom_can_edit_transaction_rental", "custom_can_edit_transaction_branch"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(TransactionInfo)
