import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Popover, PopoverHeader, PopoverBody, Table, UncontrolledTooltip } from 'reactstrap'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location-icon.svg'
import CarModelGroupIcon from '../../CA/CarModelGroupIcon'
import toTitleCase from '../../Functions/toTitleCase'
import CarInfoModal from '../../CA/CarInfoModal'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import moment from 'moment'
import gql from 'graphql-tag'
import '../CarIssue.css'
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { JobStatuses } from '../../Functions/querys'

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
))

const CarJobQuery = gql`query CarJobQuery($cursor: String, $searchTerm: String, $stageIn:[Int], $vendorIn:[ID], $jobTypeIn:[ID], $statusIn:[String], $orderBy: [String], $first:Int, $startDate:Date, $endDate:Date) {
    allJobs(first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, stageIn:$stageIn, vendorIn:$vendorIn, jobTypeIn:$jobTypeIn, statusIn:$statusIn, startDate:$startDate, endDate:$endDate){
        edges {
            node {
              id
              dateAdded
              jobDate
              status
              notes
              totalPrice
              partsQuantity
              customId
              runner
              runnerRep {
                id
                username
                firstName
                lastName
              }
              driver{
                  id
                  name
              }
              jobType {
                id
                name
              }
              updatedBy{
                id
                username
                firstName
                lastName
              }
              car {
                id
                pk
                year
                make
                model
                modelSeries
                location
                color
                group
                notes
                stage
                carpolicySet {
                  edges {
                    node {
                      id
                      status
                      insurancePolicy {
                        id
                        startDate
                        endDate
                        status
                        policyNumber
                        broker {
                          id
                          name
                        }
                      }
                    }
                  }
                }
                currentAgreement {
                  driver {
                    id
                    tlcLicense
                    name
                  }
                }
              }
              vendor {
                id
                name
              }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const locateCar = gql`
    mutation locateCar($input: TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
            }
            location{
                address
                longitude
                latitude
                speed
                date
            }
        }
    }`

const tableHeaders = [
    { id: "job", name: "Job Details", sortable: false }, { id: "id", name: "Car", sortable: true },
    { id: "notes", name: "Job Notes", sortable: false }, { id: "action", name: "Actions", sortable: false }
]

class JobsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    locateCar = (carId, modalName) => {
        if (carId) {
            this.setState({ loading: true })
            let input = { carId: carId, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address, longitude: Number(location.longitude), latitude: Number(location.latitude), speed: location.speed, date: location.date
                    }
                    this.setState({ location: location, loading: false, openModal: modalName })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", openModal: modalName, loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} loadMoreEntries={this.props.allJobs && this.props.allJobs.pageInfo && this.props.allJobs.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allJobs && this.props.allJobs.edges && this.props.allJobs.edges.length > 0 ? this.props.allJobs.edges.map((job, i) =>
                            <tr>
                                <td scope="row">{i + 1}</td>
                                <td>
                                    <div className="bos-table-td-left-box">
                                        <i className="fa fa-wrench" aria-hidden="true"></i>
                                    </div>

                                    <div className="bos-table-td-right-box">
                                        <small>
                                            <span id={"runner" + i}>{job.node.runner ? (job.node.runner === "REP" ? (job.node.runnerRep ? job.node.runnerRep.firstName ? job.node.runnerRep.firstName + " " + job.node.runnerRep.lastName : job.node.runnerRep.username : "REP") : (job.node.driver ? job.node.driver.name : "DRIVER")) : "--"}</span> | {job.node.dateAdded ? moment(job.node.dateAdded).tz("America/New_York").format('ll') : "--"} | {job.node.status && this.props.allJobStatuses && this.props.allJobStatuses.states ? this.props.allJobStatuses.states.filter(item => item.name == job.node.status)[0].description : "--"}
                                            <UncontrolledTooltip placement="right" target={"runner" + i}>Job Runner - {job.node.runner}</UncontrolledTooltip>
                                        </small>
                                        <div><b>{job.node.jobType ? job.node.jobType.name : "--"} | {job.node.vendor ? job.node.vendor.name : "--"}</b></div>
                                        <small>
                                            ${job.node.totalPrice} | <span id={"customId" + i}>{job.node.customId}</span> | <span id={"updatedBy" + i}>{job.node.updatedBy ? job.node.updatedBy.firstName ? job.node.updatedBy.firstName + " " + job.node.updatedBy.lastName : job.node.updatedBy.username : "--"}</span>
                                            <UncontrolledTooltip placement="right" target={"customId" + i}>Custom ID</UncontrolledTooltip>
                                            <UncontrolledTooltip placement="right" target={"updatedBy" + i}>Last Updated By</UncontrolledTooltip>
                                        </small>
                                    </div>
                                    <div className='bos-table-td-right-box-actions-wrapper'>
                                        <ul className="bos-table-td-right-box-actions">
                                            {job.node.status && job.node.status === "A_2" && <li>
                                                <i className="fa fa-check" aria-hidden="true" style={{ color: "#2acd72" }}></i>
                                            </li>}
                                        </ul>
                                    </div>
                                </td>
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <div className="bos-table-td-left-box">
                                            {this.state.openModal === "CarInfo" + job.node.car.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + job.node.car.pk} id={job.node.car.pk} carPk={job.node.car.pk} carId={job.node.car.id} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} />}
                                            <small>{job.node.car.pk}</small>
                                            <br />
                                            <a onClick={() => this.toggleModal("CarInfo" + job.node.car.pk)} style={{ cursor: "pointer" }}>
                                                {/* <i className="fa fa-cog" style={{fontSize: "22px"}} aria-hidden="true"></i> */}
                                                <CarModelGroupIcon carGroup={job.node.car.group} carColor={job.node.car.color} id={job.node.car.pk} key={i + 1} />
                                            </a>
                                            <br />
                                            <small>Stage {renderEnumToInt(job.node.car.stage)}</small>
                                        </div>

                                        <div className="bos-table-td-right-box">
                                            <span>
                                                <small>{job.node.car.year} | {job.node.car.color} {job.node.car.group ? "| " + job.node.car.group : ""}</small>
                                            </span>
                                            <div className="car-name">
                                                <a className="bos-custom-link" href={"/car/" + job.node.car.pk} target="_blank">
                                                    {toTitleCase(job.node.car.make)} {toTitleCase(job.node.car.model)}
                                                </a>&nbsp;
                                                <small>{job.node.car.modelSeries ? "(" + job.node.car.modelSeries + ")" : ""}</small>
                                                {(job.node.car.stage == 10 || (job.node.car.currentAgreement && job.node.car.currentAgreement.id)) && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                </>}
                                            </div>
                                            <small>
                                                {job.node.car.carpolicySet && job.node.car.carpolicySet.edges && job.node.car.carpolicySet.edges.length > 0 &&
                                                    job.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1').length > 0 ?
                                                    job.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1')[0].node.insurancePolicy.policyNumber
                                                    : "No Insurance Found!"}
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="notes">
                                        {/* {this.state.openModal==="notesHistory"+i&& 
                                    <CarNotes car={job.node.car} target={"issueNotesEdit"+i} noteType="Car Issue" title="Issue Notes History" objectId={job.node.id} open={this.state.openModal === "notesHistory"+i} handleClose={()=>this.toggleModal("")}/>
                                } */}

                                        <span id={"issueNotesEdit" + i}>
                                            {/* <i onClick={()=>this.toggleModal("notesHistory"+i)} className={"fa fa-history"}></i>&nbsp;&nbsp; */}
                                            {/* {this.props.currentUser && this.props.currentUser.groupNames && Array.isArray(this.props.currentUser.groupNames) && this.props.currentUser.groupNames.includes("Fleet") && 
                                        <i onClick={()=>this.toggleModal("issueNotesEdit"+i)} className={"fa fa-pencil-square-o"}></i>}&nbsp;&nbsp; */}
                                            <small className="note-text">
                                                {job.node.notes ? job.node.notes : "--"}
                                            </small>
                                            <UncontrolledTooltip placement="top" target={"issueNotesEdit" + i}>{job.node.notes ? job.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <ul className="actions pt-3">
                                        <li>
                                            {this.state.openModal === "Location" + i &&
                                                <Popover placement="left" isOpen={this.state.openModal === "Location" + i} target={"LocationPopup" + i} toggle={() => this.setState({ openModal: "" })}>
                                                    <PopoverHeader>Car Location</PopoverHeader>
                                                    <PopoverBody>
                                                        {this.state.location && this.state.location.latitude && this.state.location.longitude ?
                                                            <MyMapComponent
                                                                isMarkerShown
                                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I&v=3.exp&libraries=geometry,drawing,places"
                                                                loadingElement={<div style={{ height: `100%` }} />}
                                                                containerElement={<div style={{ height: `300px` }} />}
                                                                mapElement={<div style={{ height: `100%` }} />}
                                                                latitude={this.state.location.latitude}
                                                                longitude={this.state.location.longitude}
                                                            /> :
                                                            "Car Location Not Found!"
                                                        }
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                            <a onClick={() => this.locateCar(job.node.car.id, "Location" + i)} id={"LocationPopup" + i}><LocationIcon width={20} fill="#303E67" /></a>
                                            <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>Locate Car</UncontrolledTooltip>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Cars Found!"}</td>
                        </tr>}
                    </tbody>
                </Table>
            </PrimaryListingContainer>

        )
    }
}

export default compose(
    withApollo,
    graphql(locateCar, {
        props: ({ mutate }) => ({
            locate: (input) => mutate({ variables: { input } }),
        })
    }),
    graphql(JobStatuses, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, jobStatuses } }) {
            return { loading, jobStatuses }
        },
    }),
    graphql(CarJobQuery,
        {
            options: ({ searchTerm, orderBy, stageIn, jobTypeIn, statusIn, vendorIn, startDate, endDate }) => ({
                variables: { searchTerm, orderBy, stageIn, jobTypeIn, statusIn, first: 30, vendorIn, startDate, endDate },
                notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
            }),
            props({ data: { loading, allJobs, fetchMore, variables } }) {
                return {
                    loading,
                    allJobs,
                    variables,
                    refetchQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = allJobs && allJobs.edges ? allJobs.edges.length : 30
                        return fetchMore({
                            query: CarJobQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allJobs.edges
                                const pageInfo = fetchMoreResult.allJobs.pageInfo
                                return {
                                    allJobs: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.allJobs.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: CarJobQuery,
                            variables: {
                                cursor: allJobs.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allJobs.edges
                                const pageInfo = fetchMoreResult.allJobs.pageInfo

                                return {
                                    allJobs: {
                                        edges: [...previousResult.allJobs.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        stageIn: previousResult.stageIn,
                                        jobTypeIn: previousResult.jobTypeIn,
                                        statusIn: previousResult.statusIn,
                                        vendorIn: previousResult.vendorIn,
                                        __typename: previousResult.allJobs.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(JobsList)