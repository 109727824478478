import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Alert, Table, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import moment from 'moment-timezone'
import InstallCarPopover from './InstallCarPopover'
import { AllCarRegistrationsQuery, CarSearchQuery } from '../Queries'
import { UpdateCarRegistrationMutation, CreateCarRegistrationMutation } from '../Mutations'
import toTitleCase from '../../Functions/toTitleCase'

class RegistrationActivityModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            carSuggestions: null,
            selectedCar: null,
            openModal: null,
            showCarFinder: false
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    refetchQuery = () => {
        this.props.refetchQuery()
        this.props.refetchCarRegistrationsQuery()
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
        }
        this.setState({ [name]: value })
    };

    handleClose = (e) => {
        this.props.handleClose()
    };

    handleCarSearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.client.query({
                query: CarSearchQuery,
                variables: { searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                    this.setState({ carSuggestions: response.data.allCars.edges })
                } else {
                    this.setState({ carSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ carSuggestions: null })
            })
        } else {
            this.setState({ carSuggestions: null })
        }
    }

    handleUninstall = (carRegistrationId) => {
        try {
            this.setState({ error: "", loading: true })
            let input = { id: carRegistrationId, isInstalled: false }
            this.props.client.mutate({
                mutation: UpdateCarRegistrationMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.updateCarRegistration && result.data.updateCarRegistration.ok) {
                    this.setState({ loading: false, error: null })
                    this.refetchQuery()
                    // this.handleClose();
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (result.data && result.data.updateCarRegistration && result.data.updateCarRegistration.errors && result.data.updateCarRegistration.errors[0] && result.data.updateCarRegistration.errors[0].messages[0])
                        errorMessage = result.data.updateCarRegistration.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured " + error
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: errorMessage, loading: false })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.open} className="registration-activity-modal">
                <ModalHeader>Registration Activity History</ModalHeader>
                <ModalBody>
                    {this.props.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Table responsive>
                        <thead>
                            <th>Car Details</th>
                            <th>Installation</th>
                            <th>Installed</th>
                            <th>Active</th>
                            <th>Uninstallation</th>
                            <th>Action</th>
                        </thead>
                        <tbody>

                            {this.props.allCarRegistrations && this.props.allCarRegistrations.edges && this.props.allCarRegistrations.edges.length > 0 && this.props.allCarRegistrations.edges.map((carReg, i) =>
                                <tr>
                                    <td><small>{carReg.node.car.pk} | {carReg.node.car.vin}</small><br /><b>{toTitleCase(carReg.node.car.carModel.make) + " " + carReg.node.car.carModel.name} {carReg.node.car.year}</b></td>
                                    <td><small>{carReg.node.createdBy ? carReg.node.createdBy.firstName ? carReg.node.createdBy.firstName + " " + carReg.node.createdBy.lastName : carReg.node.createdBy.username : "--"}</small><br />{carReg.node.dateAdded ? moment(carReg.node.dateAdded).tz("America/New_York").format('lll') : "--"}</td>
                                    <td><i className={'fa fa-circle ' + (carReg.node.isInstalled ? "text-success" : "text-danger")} /></td>
                                    <td><i className={'fa fa-circle ' + (carReg.node.isActive ? "text-success" : "text-danger")} /></td>
                                    <td><small>{carReg.node.removedBy ? carReg.node.removedBy.firstName ? carReg.node.removedBy.firstName + " " + carReg.node.removedBy.lastName : carReg.node.removedBy.username : "--"}</small><br />{carReg.node.removedAt ? moment(carReg.node.removedAt).tz("America/New_York").format('lll') : "--"}</td>
                                    <td>{carReg.node.isInstalled && <Button className='bos-btn-secondary' onClick={() => this.handleUninstall(carReg.node.id)}>Remove</Button>}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter className='d-block'>
                    <Row>
                        <Col className='text-right'>
                            {this.props.loading ? "Loading Registration Activities..." : <>
                                {/* <Button id="InstallCarPopover" className="bos-btn-primary" onClick={() => this.toggleModal("InstallCarPopover")}>Install On A Car</Button>&nbsp;&nbsp; */}
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>
                                {this.state.openModal === "InstallCarPopover" && <InstallCarPopover target="InstallCarPopover" registrationId={this.props.registrationId} open={this.state.openModal === "InstallCarPopover"} handleClose={() => this.toggleModal("")} refetchQuery={this.refetchQuery} />}
                            </>}
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarRegistrationsQuery, {
        options: ({ registrationId }) => ({
            variables: { registrationId, first: 30, orderBy: ["-id"] },
            notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
        }),
        props({ data: { loading, allCarRegistrations, fetchMore, variables } }) {
            return {
                loading, allCarRegistrations, variables,
                refetchCarRegistrationsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allCarRegistrations && allCarRegistrations.edges ? allCarRegistrations.edges.length : 30
                    return fetchMore({
                        query: AllCarRegistrationsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarRegistrations.edges
                            const pageInfo = fetchMoreResult.allCarRegistrations.pageInfo
                            return {
                                allCarRegistrations: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allCarRegistrations.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllCarRegistrationsQuery,
                        variables: {
                            cursor: allCarRegistrations.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarRegistrations.edges
                            const pageInfo = fetchMoreResult.allCarRegistrations.pageInfo

                            return {
                                allCarRegistrations: {
                                    edges: [...previousResult.allCarRegistrations.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allCarRegistrations.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(RegistrationActivityModal)
