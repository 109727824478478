import React from "react"
import { compose, graphql, withApollo } from "react-apollo"
import { Table } from "reactstrap"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { AllNotificationTemplatesQuery } from "./Queries"

const tableHeaders = [
    { id: "name", name: "name", sortable: false },
    { id: "subject", name: "subject", sortable: false },
    { id: "description", name: "description", sortable: false },
    { id: "body", name: "body", sortable: false },

]


const NotificationTemplatesList = ({ updateOrder, loading, orderBy, history, allNotificationTemplates }) => {

    return (
        <div className="AutomationList" >
            <PrimaryListingContainer listLoading={loading}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {allNotificationTemplates && allNotificationTemplates && allNotificationTemplates.length > 0 ? allNotificationTemplates.map((notificationTemplates, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{notificationTemplates.name ? notificationTemplates.name : "--"}</td>
                                <td>
                                    {notificationTemplates.subject ? notificationTemplates.subject : "--"}
                                </td>
                                <td>
                                    {notificationTemplates.description ? notificationTemplates.description : "--"}
                                </td>
                                <td>
                                    {notificationTemplates.body ? notificationTemplates.body : "--"}
                                </td>
                            </tr>
                        )) :
                            <tr>
                                <td colSpan={tableHeaders.length + 1} className="text-center">No Notification Templates Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        </div>
    )
}

export default compose(
    withApollo,
    graphql(AllNotificationTemplatesQuery, {
        options: ({ }) => ({ variables: {} }),
        props({ data: { loading, error, allNotificationTemplates } }) {
            return {
                loading, error, allNotificationTemplates,
            }
        }
    }),
)(NotificationTemplatesList)