import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import NotificationTemplatesList from "./NotificationTemplatesList"
import NotificationTemplatesListHeader from "./NotificationTemplatesListHeader"


const NotificationTemplatesListContainer = ({ location, history, currentUser }) => {
    const [filterValues, setFilterValues] = useState({
    })

    const updateOrder = (string) => {
        let order = [...filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        setFilterValues({ ...filterValues, orderBy: order })
    }

    return (
        <Container fluid className="bos-listing-container">
            <Row>
                <Col>
                    <NotificationTemplatesList
                        key={String("true")}
                        searchTerm={filterValues && filterValues.searchTerm ? filterValues.searchTerm : null}
                        currentUser={currentUser}
                        history={history}
                        orderBy={filterValues && filterValues.orderBy ? filterValues.orderBy : []}
                        updateOrder={updateOrder}
                        location={location}
                    />

                </Col>
            </Row>
        </Container>

    )
}

export default NotificationTemplatesListContainer