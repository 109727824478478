import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Badge, Table, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap'
import DriverUpdateTagsPopover from '../Drivers/Driver/Tags/DriverUpdateTagsPopover'
import DriverNotes from '../ApplicationsNew/DriverNotes'
import DocumentModal from '../Material/DocumentModal/DocumentModal'
import UpdateDriverInfo from '../ApplicationsNew/UpdateDriverInfo'
import Languages from "../Material/Language.json"
import MassMessageModal from "../Material/MassMessageModal"
import RepAssignment from "../Material/RepAssignment"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { ReactComponent as InOffice } from "../assets/images/icons/in-office.svg"
import { AllFollowUpCustomersQuery } from "./Queries"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import datetimeToString from "../Functions/datetimeToString"
import "../Material/ListingHeader.css"
import "./index.css"
import "../index-dark.css"
import Label from "reactstrap/lib/Label"

const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, views: true },
    { id: "id", name: "Driver Info", sortable: true, views: true },
    { id: "last_lead_date", name: "Last Applied", sortable: true, views: true },
    { id: "lead_count", name: "Leads Count", sortable: true, views: true },
    { id: "dtac__reason", name: "Latest DTAC Reason", sortable: false, views: true },
    { id: "dtac_count", name: "DTAC Count", sortable: true, views: true },
    { id: "notes", name: "Driver Notes", sortable: false, views: true },
    { id: "tags", name: "Tags", sortable: false, views: true },
    { id: "assigned_to__name", name: "Assigned Rep", sortable: false, views: true },
]

class FollowupCustomersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectAll: false,
            showEdit: "",
            selectedDriverPreview: null
        }
    }

    updateSelected = (value) => {
        let selectedCustomers = this.props.selectedCustomers
        if (selectedCustomers.includes(value)) {
            selectedCustomers = selectedCustomers.filter(item => item !== value)
        } else {
            selectedCustomers.push(value)
        }
        this.props.setSelectedCustomers(selectedCustomers)
    };

    toggleSelectAllClick = () => {
        if (this.props.allFollowUpCustomers && !this.state.selectAll) {
            this.props.setSelectedCustomers(this.props.allFollowUpCustomers.edges.map(n => n.node))
            this.setState({ selectAll: true })
        } else {
            this.props.setSelectedCustomers([])
            this.setState({ selectAll: false })
        }
    };

    renderLangCode(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    toggleEdit = (editName) => {
        this.setState({ showEdit: this.state.showEdit === editName ? "" : editName })
    }

    clearSelectedCustomers = () => {
        this.setState({ selectedCustomers: [] })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refetchDrivers !== this.props.refetchDrivers && this.props.refetchDrivers) {
            this.props.refetchQuery()
            this.props.setRefetchQuery()
        }
    }
    render() {
        let allFollowUpCustomers = this.props.allFollowUpCustomers
        return (
            <>
                <Label style={{ color: "red" }}>Note: This list contains only those drivers which currently doesn't have an <b>active rental, open reservation, active/pending insurance or open lead</b>. </Label>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={allFollowUpCustomers && allFollowUpCustomers.edges.length} pageInfo={allFollowUpCustomers && allFollowUpCustomers.pageInfo} loadMoreEntries={allFollowUpCustomers && allFollowUpCustomers.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchDriversQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                                {tableHeaders.map(tableHeader => {
                                    return tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {allFollowUpCustomers && allFollowUpCustomers.edges && allFollowUpCustomers.edges.length > 0 ? allFollowUpCustomers.edges.map((driver, i) => <tr key={driver.node.id} className={driver.node && driver.node.inOffice && "driver-in-office-row"}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedCustomers.includes(driver.node.id) && <span className="listing-counter">{i + 1}</span>}
                                    <span className="listing-checkbox" onClick={() => this.updateSelected(driver.node.id)}>
                                        {this.props.selectedCustomers.includes(driver.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                <td>
                                    <small>
                                        <DatetimeRenderer datetime={driver.node.dateAdded} placement={"left"} />
                                        <br />
                                        <i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(driver.node.dateAdded)}
                                    </small>
                                </td>
                                <td>
                                    {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[driver.node.id]} />}
                                    {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={driver.node.id} currentUser={this.props.currentUser} />}
                                    <div className="driver-detail-block">
                                        {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                            <label>{driver.node.name}</label>
                                            <span className="bos-custom-tooltip-header">
                                                <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{driver.node.email || "--"}<br />
                                                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{driver.node.phone || "--"}<br />
                                            </span>
                                            <p className="bos-custom-tooltip-body">
                                                <b>Driver License:</b> {driver.node.dmvLicense || "--"} <br />
                                                {driver.node.isTlc && driver.node.tlcLicense && <><b>TLC License:</b> {driver.node.tlcLicense}<br /></>}
                                                <b>Preferred Language:</b> {driver.node.preferredLanguage ? this.renderLangCode(driver.node.preferredLanguage) : "--"}<br />
                                            </p>
                                            <span className="bos-custom-tooltip-footer">
                                                {<><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                {<><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                {this.props.listingType === "drivers" && <>
                                                    <Button id={"reserve-car-" + i} onClick={() => this.setState({ openModal: (driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "UpdateReservationModal" : "NewReservationModal") + i })}>{driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? <i className="fa fa-calendar-check-o text-success" aria-hidden="true"></i> : <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>}</Button>
                                                    &nbsp;&nbsp;</>}
                                            </span>
                                        </UncontrolledTooltip>}
                                        <div className="d-flex" id={"driverInfo" + i}>
                                            <span>
                                                <small>
                                                    {driver.node.dmvLicense || "--"}&nbsp;&nbsp;<Badge className='bos-badge-blue bos-badge-small' >{driver.node.branch && driver.node.branch.name ? driver.node.branch.name : "No Branch Selected"}</Badge>
                                                </small>
                                                <br />
                                                <a className="bos-custom-link" href={'/driver/' + driver.node.id} target="_blank">
                                                    {driver.node.name.trim() ? driver.node.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                </a>
                                            </span>
                                        </div>
                                        <ul className="driver-detail-actions pt-2">
                                            {driver.node && driver.node.inOffice &&
                                                <li>
                                                    <InOffice id={"inOffice" + String(driver.node.pk)} width={25} height={25} fill="#198754" />
                                                    <UncontrolledTooltip target={"inOffice" + String(driver.node.pk)}>Driver in Office</UncontrolledTooltip>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    {driver.node && driver.node.leadSet && driver.node.leadSet.edges && driver.node.leadSet.edges.length > 0 ? <small>
                                        <DatetimeRenderer datetime={driver.node.leadSet.edges.sort((a, b) => new Date(b.node.dateAdded).getTime() - new Date(a.node.dateAdded).getTime())[0].node.dateAdded} placement={"left"} />
                                        <br />
                                        <i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(driver.node.leadSet.edges.sort((a, b) => new Date(b.node.dateAdded).getTime() - new Date(a.node.dateAdded).getTime())[0].node.dateAdded)}
                                    </small> : "--"}
                                </td>
                                <td>
                                    {driver.node && driver.node.leadSet && driver.node.leadSet.edges && driver.node.leadSet.edges.length > 0 ? <>
                                        <span style={{ backgroundColor: "green", color: "white", marginLeft: "0.5rem", padding: "4px 10px", borderRadius: "12px" }}>{driver.node.leadSet.edges.length}</span>
                                    </> : "--"}
                                </td>
                                <td>
                                    {driver.node && driver.node.didnttakecardriverSet && driver.node.didnttakecardriverSet.edges && driver.node.didnttakecardriverSet.edges.length > 0 ? driver.node.didnttakecardriverSet.edges.sort((a, b) => new Date(b.node.dateAdded).getTime() - new Date(a.node.dateAdded).getTime())[0].node.reason.name : "--"}
                                </td>
                                <td>
                                    {driver.node && driver.node.didnttakecardriverSet && driver.node.didnttakecardriverSet.edges && driver.node.didnttakecardriverSet.edges.length > 0 ? <>
                                        <span id={"dtac" + String(driver.node.pk)} style={{ backgroundColor: "orange", color: "white", marginLeft: "0.5rem", padding: "4px 10px", borderRadius: "12px" }}>{driver.node.didnttakecardriverSet.edges.length}</span>
                                        <UncontrolledTooltip target={"dtac" + String(driver.node.pk)} className="dtac-history-tooltip" delay={{ "show": 400, "hide": 200 }} placement="right" autohide={false}>
                                            <Label>DTAC History</Label>
                                            <ul>
                                                {driver.node && driver.node.didnttakecardriverSet && driver.node.didnttakecardriverSet.edges && driver.node.didnttakecardriverSet.edges.length > 0 ? driver.node.didnttakecardriverSet.edges.sort((a, b) => new Date(b.node.dateAdded).getTime() - new Date(a.node.dateAdded).getTime()).map(dtac =>
                                                    <li>
                                                        {dtac.node.reason.name} @ <DatetimeRenderer datetime={dtac.node.dateAdded} placement={"left"} format={"ll"} hideTooltip />
                                                    </li>
                                                ) : "--"}
                                            </ul>
                                        </UncontrolledTooltip>
                                    </> : "--"}
                                </td>
                                <td className="shortText" style={{ maxWidth: "15rem" }}>
                                    <small id={"notes" + driver.node.pk}>{driver.node.notes ? driver.node.notes : "--"}</small>
                                    <UncontrolledTooltip placement="right" target={"notes" + driver.node.pk}>{driver.node.notes} </UncontrolledTooltip>
                                    <br />
                                    {this.state.openModal === i + "notesHistory" && <DriverNotes driver={driver.node} title="Driver Notes History" target={"notesHistory" + i} open={this.state.openModal === i + "notesHistory"} handleClose={() => this.toggleModal(i + "notesHistory")} placement={"left"} />}
                                    {driver && this.state.openModal === "notesEdit" &&
                                        <UpdateDriverInfo driverId={driver.id} title="Edit Notes" name="notes" value={driver.node.notes}
                                            target={"driverNotesHistory"} id="notes" type="text" open={this.state.openModal === "driverNotesEdit"}
                                            handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchQuery}
                                        />
                                    }
                                    <i id={"notesEdit" + i} onClick={() => this.toggleModal("notesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                    <i id={"notesHistory" + i} onClick={() => this.toggleModal(i + "notesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <span className='pr-3 align-self-center'>
                                            <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleEdit("drivertags" + driver.node.pk)} id={"drivertags" + driver.node.pk}></i>
                                            <DriverUpdateTagsPopover isOpen={this.state.showEdit === "drivertags" + driver.node.pk} handleClose={() => this.toggleEdit("")} driverId={driver.node.id} target={"drivertags" + driver.node.pk} driverTags={driver.node.tags} refetchQuery={this.props.refetchQuery} />
                                        </span>
                                        <span className='pl-3' style={{ maxWidth: "150px", borderLeft: "1px solid #e1e1e1" }}>
                                            {driver.node.isRepo && <><Badge color="danger">REPO</Badge></>}
                                            {driver.node.isDisabled && <><Badge color="danger">DISABLED</Badge></>}
                                            {driver.node.tags && driver.node.tags.map((tag, i) =>
                                                <><Badge key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge>&nbsp;</>
                                            )}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <span className='pr-3 align-self-center'>
                                            <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleEdit("rep" + driver.node.pk)} id={"rep" + driver.node.pk}></i>
                                            {this.state.showEdit === "rep" + driver.node.pk && <Popover isOpen={this.state.showEdit === "rep" + driver.node.pk} placement="bottom" target={"rep" + driver.node.pk}>
                                                <PopoverHeader onClick={() => this.toggleEdit("")}>Assign Rep <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                                <PopoverBody style={{ minWidth: "200px" }}>
                                                    <RepAssignment handleClose={() => this.toggleEdit("rep" + driver.node.pk)} driver={driver.node} defaultValue={driver.node.assignedReps.edges} refetchDrivers={this.props.refetchQuery} groups={["Sales Team"]} />
                                                </PopoverBody>
                                            </Popover>}
                                        </span>
                                        <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                            {driver.node.assignedReps && driver.node.assignedReps.edges && driver.node.assignedReps.edges.length > 0 ? driver.node.assignedReps.edges.map(rep =>
                                                <span>{rep.node.firstName ? rep.node.firstName + " " + rep.node.lastName : rep.node.username}</span>) : "--"
                                            }
                                        </span>
                                    </div>
                                </td>
                            </tr>) :
                                this.props.loading ? <tr><td colSpan={7}>Loading Follow Up Customers List...</td></tr>
                                    : <tr>
                                        <th scope="row">&nbsp;</th>
                                        <td colSpan={7}>No Follow Up Customers Found</td>
                                    </tr>}
                        </tbody>
                    </Table>
                </PrimaryListingContainer >
            </>

        )
    }
}

export default compose(
    withApollo,
    graphql(AllFollowUpCustomersQuery, {
        options: ({ searchTerm, orderBy, insuranceStatuses, insuranceRemovedReasonIds, didntTakeCarReasonIds, driverTags, assignedTo, hasRented, leadStatuses, dtacAfterRental, excludeDriverTags }) => ({
            variables: {
                searchTerm,
                orderBy,
                insuranceStatuses,
                insuranceRemovedReasonIds,
                didntTakeCarReasonIds,
                driverTags,
                assignedTo,
                hasRented,
                leadStatuses,
                dtacAfterRental,
                excludeDriverTags,
                first: 30,

            },
            notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, allFollowUpCustomers, fetchMore, searchTerm, variables } }) {
            return {
                loading,
                allFollowUpCustomers,
                variables,
                refetchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allFollowUpCustomers && allFollowUpCustomers.edges ? allFollowUpCustomers.edges.length : 30
                    return fetchMore({
                        query: AllFollowUpCustomersQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allFollowUpCustomers.edges
                            const pageInfo = fetchMoreResult.allFollowUpCustomers.pageInfo
                            return {
                                allFollowUpCustomers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allFollowUpCustomers.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllFollowUpCustomersQuery,
                        variables: {
                            cursor: allFollowUpCustomers.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allFollowUpCustomers.edges
                            const pageInfo = fetchMoreResult.allFollowUpCustomers.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allFollowUpCustomers: {
                                    edges: [...previousResult.allFollowUpCustomers.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allFollowUpCustomers.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(FollowupCustomersList)
