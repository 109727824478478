import gql from 'graphql-tag'

const AllDriverInteractionsQuery = gql`query AllDriverInteractionsQuery($id: ID, $interactionDate:Date, $interactionType:String, $direction:String, $orderBy:[String], $first: Int, $after: Int, $handles: [String]){
    allDriverInteractions(id: $id, interactionDate: $interactionDate, interactionType: $interactionType, direction: $direction, orderBy: $orderBy, first: $first, after: $after, handles: $handles){
        body
        dateSent
        direction
        from_
        to
        status
        isSent
        type
        cursor
        clientResponse
        attachments{
            name
            content
            contentType
            url
        }
        rep{
            id
            username
            firstName
            lastName
        } 
    }
    
}`
const BranchSettingQuery = gql`query AllBranchSettings($settingName: String){
    allBranchSettings(settingName: $settingName){
        edges {
        node {
            id
            value
            branch{
                id
                name
                tenant{
                    id
                    name
                }
            }
        }
        }
    }
}`

const AllCustomerHandlesQuery = gql`query Query($customerId: ID!, $handleType:[String], $orderBy: [String]){
    optionsList: allCustomerHandles(customerId: $customerId, handleType:$handleType, orderBy:$orderBy){
        edges{
            node{
                id
                pk
                type
                title
                handle
            }
        }
    }
}`

export { AllDriverInteractionsQuery, BranchSettingQuery, AllCustomerHandlesQuery }