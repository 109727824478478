import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo/index'
import { Button, Col, Row, Input } from 'reactstrap'
import MassMessageModal from '../../Material/MassMessageModal'
import AddNewDriver from './AddNewDriver'
import ListingFilters from "../../Material/ListingFilters"
import { debounce } from 'lodash'
import './DriversListContainer.css'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import toTitleCase from '../../Functions/toTitleCase'
import WeeklyChargeModal from '../../Material/WeeklyChargeModal'
import RepAssignmentModal from "../../Material/RepAssignmentModal"
import { HasPermissionsQuery } from "../../Functions/querys"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import updateFilters from "../../Functions/updateFilters"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"
import TablePreferencePicker from "../../Material/TablePreferencePicker"
import { PaymentsStatusesQuery } from "../../Billing/PaymentSchedules/Queries"

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 13){
        edges{
            node{
                id
                name
                color
            }
        }
    }
}`

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const assignDriverToRepMutation = gql`
mutation CreateNewAssignment ($repID:ID!, $drivers:ID!){
    createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
    }
}`


const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
    }
} `

const AllPromoNames = gql`
{
    optionsList: allPromotionsTemplateNames
}`



const collectionDays = [
    { value: 0, label: "Monday" }, { value: 1, label: "Tuesday" }, { value: 2, label: "Wednesday" }, { value: 3, label: "Thursday" }, { value: 4, label: "Friday" }, { value: 5, label: "Saturday" }, { value: 6, label: "Sunday" }
]
class DriversListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "text", name: "searchTerm", title: "Search Term", placeholder: "Search By Name, TLC, Phone", showFilter: false },
            { type: "number", name: "pk", title: "Driver ID", placeholder: "1234", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "email", title: "Email", placeholder: "john.doe@joinbuggy.com", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "phone", title: "Phone", placeholder: "+19499425025", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "tlcLicense", title: "TLC License #", placeholder: "784273498", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "dmvLicense", title: "DMV License #", placeholder: "3123123", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "select", name: "statuses", title: "Payment Status ", optionsQuery: PaymentsStatusesQuery, valueSelector: "description", labelSelector: "description", placeholder: "Filter By Payment Status", showFilter: activeTab === "Schedules", isMulti: true },
            {
                type: "select", name: "stages", title: "Driver Stage", options: [
                    { value: 6, label: "Active" },
                    { value: 10, label: "Inactive", },
                    { value: 1, label: "Applied" },
                    { value: 0, label: "Didnt Take Car" },
                ], placeholder: "Filter By Stage", showFilter: ["Collections", "All Drivers"].includes(activeTab), isMulti: true
            },
            {
                type: "select", name: "carStatus", title: "Car Status", options: [
                    { value: "Enabled", label: "Enabled" },
                    { value: "Disabled", label: "Disabled" },
                ], placeholder: "Filter By Car Status", showFilter: activeTab !== "Schedules"
            },
            { type: "select", name: "chargeDayofweek", title: "Billing Day", options: collectionDays, placeholder: "Filter By Billing Days", showFilter: ["Collections"].includes(activeTab) },
            { type: "select", name: "driverTags", title: "Driver Tags", optionsQuery: TagsQuery, placeholder: "Filter By Driver Tags", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: activeTab !== "Schedules" },
            {
                type: "boolean", name: "hasBalanceWriteOff", title: "Filter By Bad Debt", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Bad Debt", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            {
                type: "boolean", name: "hasPaymentPlan", title: "Filter By Payment Plan", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Payment Plan", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            { type: "number", name: "minBalance", title: "Min Balance", placeholder: "-10000", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "number", name: "maxBalance", title: "Max Balance", placeholder: "10000", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "boolean", name: "inOffice", title: "Filter By Driver In Office", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Driver In Office", showFilter: activeTab !== "Schedules" },
            {
                type: "boolean", name: "hideFromCollections", title: "Show Hidden", optionOne: "YES",
                optionTwo: "NO", placeholder: "Show Hidden Only", showFilter: ["Collections"].includes(activeTab)
            },
            {
                type: "boolean", name: "needsCollection", title: "Needs Collection", optionOne: "YES",
                optionTwo: "NO", placeholder: "Needs Collection", showFilter: ["Collections"].includes(activeTab)
            },
            {
                type: "select", name: "assignedTo", title: "Assigned Reps", valueSelector: "id", labelSelector: "firstName",
                optionsQuery: RepsQuery, variables: [{ groups: ["Billing"] }], placeholder: "Filter By Assigned Reps",
                showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            {
                type: "boolean", name: "autoCharge", title: "Filter By Auto Charge", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Auto Charge", showFilter: ["Collections"].includes(activeTab)
            },
            {
                type: "boolean", name: "isBlocked", title: "Filter By Is Blocked", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Is Blocked", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            { type: "select", name: "promoNames", title: "Promo Names", placeholder: "Filter By Promo Name", optionsQuery: AllPromoNames, showFilter: activeTab !== "Schedules", showFilter: ["Active Drivers"].includes(activeTab), isMulti: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    unAssignDrivers = () => {
        let input = {
            repID: "none",
            drivers: this.props.selectedDrivers
        }
        this.props.client.mutate({
            mutation: assignDriverToRepMutation,
            variables: input,
        }).then((result) => {
            this.props.refetchQuery()
        })
    };
    updateDriver = (id, newvals) => {
        let input = {
            id: id,
            ...newvals
        }
        this.props.update(input).then(result => { this.props.refetchQuery() })
    }
    toggleHideDrivers = (action) => {
        let selectedDrivers = this.props.selectedDrivers
        selectedDrivers.map(item => {
            this.updateDriver(item, { hideFromCollections: action })
        })
        this.props.resetSelectedDrivers()
    }
    setConfigurations = (activeTab) => {
        let conf = this.getFilterConfigurations(activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    }
                    else if (filter['name'] === "promoNames") {
                        options = result.data.optionsList.map(option => ({ value: option, label: option }))
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    updateAutoCharge = () => {
        let selectedDrivers = this.props.selectedDrivers
        selectedDrivers.map(item => {
            this.updateDriver(item, { autoCharge: this.state.autoCharge })
        })
        this.props.resetSelectedDrivers()
        this.toggleModal("")
    }
    componentDidMount() {
        if (this.props.activeTab)
            this.setConfigurations(this.props.activeTab)
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setConfigurations(this.props.activeTab)
        }

        if ((this.props.location.search !== prevProps.location.search && this.props.location.search) && (prevProps.filterValues !== this.props.filterValues)) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            updateFilters({ ...this.props.filterValues, ...urlFilterValues, }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    render() {
        return (
            <Row>
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedDrivers && this.props.selectedDrivers.length > 0 ? this.props.selectedDrivers : null} />}
                {this.state.openModal === "AddNewDriver" && <AddNewDriver handleClose={() => this.toggleModal("AddNewDriver")} open={this.state.openModal === "AddNewDriver"} />}
                {this.state.openModal === "WeeklyChargeModal" && <WeeklyChargeModal open={this.state.openModal === "WeeklyChargeModal"} handleClose={() => this.toggleModal(false)} />}
                {this.state.openModal === "RepAssignmentModal" && <RepAssignmentModal
                    open={this.state.openModal === "RepAssignmentModal"}
                    title={"Assign " + this.props.selectedDrivers.length + " Selected Drivers to Reps"}
                    drivers={this.props.selectedDrivers}
                    handleClose={() => this.toggleModal(false)}
                    refetchDrivers={this.props.refetchQuery}
                    groups={this.props.activeTab === "Collections" ? ["Collectors"] : []}
                />}
                {this.state.openModal === "autoChargeConfirmation" &&
                    <ConfirmationPopup
                        open={this.state.openModal === "autoChargeConfirmation"}
                        message={"Are you sure you want to turn " + (this.state.autoCharge ? "on" : "off") + " auto charge for " + this.props.selectedDrivers.length + " selected drivers?"}
                        loading={this.state.loading}
                        handleClose={() => this.toggleModal("")}
                        action={"Yes"}
                        confirmAction={this.updateAutoCharge}
                        color="danger"
                        error={this.state.error ? this.state.error : ""}
                        success={this.state.insuranceCreated ? "Action Performed Successfully" : ""}
                    />
                }
                {this.state.openModal === "viewColumnPicker" &&
                    <TablePreferencePicker columnConfig={this.props.columnConfig} currentUser={this.props.currentUser} open={this.state.openModal === "viewColumnPicker"} target="toggleColumnPickerButton" setColumns={this.props.setColumns} refetchPreferences={this.props.refetchPreferences} preferenceType={this.props.preferenceType} />
                }
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} key={`searchTerm-${this.props.activeTab}`} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={8} className="text-right mt-2">
                    {this.props.isAuthorized && this.props.activeTab === "Collections" && <>
                        <a className="driver-list-button" onClick={() => this.toggleModal("WeeklyChargeModal")}>
                            Weekly Charge | <i className="fa fa-dollar" aria-hidden="true"></i>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </>}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 &&
                        <>
                            <a className="driver-list-button" onClick={() => this.setState({ openModal: "autoChargeConfirmation", autoCharge: true })}>
                                Auto Charge On | <i className="fa fa-money" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;
                            <a className="driver-list-button" onClick={() => this.setState({ openModal: "autoChargeConfirmation", autoCharge: false })}>
                                Auto Charge Off | <i className="fa fa-money" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;
                            {this.props.activeTab === "Collections" && this.props.filterValues.hideFromCollections !== undefined && (this.props.filterValues.hideFromCollections === true ?
                                this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_unhide_collection") && <>
                                    <a className="driver-list-button" onClick={() => this.toggleHideDrivers(false)}>
                                        Unhide From Collections | <i className="fa fa-eye" aria-hidden="true"></i>
                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                                : this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_hide_collection") && <> <a className="driver-list-button" onClick={() => this.toggleHideDrivers(true)}>
                                    Hide From Collections | <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                </>)
                            }
                            {["All Drivers", "Active Drivers", "Collections"].includes(this.props.activeTab) && this.props.hasPermissions && this.props.hasPermissions.includes("add_driverassignment") &&
                                <>
                                    <a className="driver-list-button" onClick={this.unAssignDrivers}>
                                        Unassign Drivers | <i className="fa fa-minus" aria-hidden="true"></i>
                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a className="driver-list-button" onClick={() => this.toggleModal("RepAssignmentModal")}>
                                        Assign Drivers | <i className="fa fa-plus" aria-hidden="true"></i>
                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                            }
                        </>
                    }
                    {this.props.activeTab !== "Schedules" && <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                        Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {["All Drivers", "Active Drivers"].includes(this.props.activeTab) && <>
                        <a className="driver-list-button" onClick={() => this.toggleModal("AddNewDriver")}>
                            New Driver | <i className="fa fa-plus" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </>}
                    {this.props.activeTab !== "Schedules" &&
                        <>
                            <a id="toggleColumnPickerButton" className="driver-list-button" onClick={() => this.toggleModal("viewColumnPicker")}>
                                Preference | <i class="fa fa-table" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>

                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(UpdateDriver, {
        props: ({ mutate }) => ({
            update: (input) => mutate({ variables: { input } }),
        }),
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: {
                userPermissions: [
                    "custom_can_unhide_collection",
                    "custom_can_hide_collection",
                    "add_driverassignment"
                ]
            }
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(DriversListHeader)
