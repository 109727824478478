import React, { Component } from "react"
import { compose, graphql } from 'react-apollo'
import { Table, Button, UncontrolledTooltip, Badge } from "reactstrap"
import { InsuranceStatusQuery, DriverInsuranceStatus } from "./Queries"
import DriverNotes from "../ApplicationsNew/DriverNotes"
import DriverUpdateTagsPopover from "../Drivers/Driver/Tags/DriverUpdateTagsPopover"
import MassMessageModal from "../Material/MassMessageModal"
import DocumentModal from "../Material/DocumentModal/DocumentModal"
import { UpdateInsuranceStatus } from "./Mutations"
import UpdateInsuranceInfo from "./UpdateInsuranceInfo"
import renderEnumToInt from "../Functions/renderEnumToInt"
import UpdateDriverInfo from "../ApplicationsNew/UpdateDriverInfo"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import "../Material/ListingHeader.css"
import InsurancePreviewModal from "./InsurancePreviewModal"
import datetimeToString from "../Functions/datetimeToString"
import { ReactComponent as Accident } from '../assets/images/icons/accident.svg'
import { ReactComponent as InOffice } from '../assets/images/icons/in-office.svg'

class InsuranceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectAll: false,
            openModal: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateSelected = (insuranceObject) => {
        let selectedInsurances = this.props.selectedInsurances
        if (selectedInsurances && selectedInsurances.map(item => item.id).includes(insuranceObject.id)) {
            selectedInsurances = selectedInsurances.filter(item => item.id !== insuranceObject.id)
        } else {
            selectedInsurances.push(insuranceObject)
        }
        this.props.setSelectedInsurances(selectedInsurances)
    };

    updateDriver = (id, newvals) => {
        let input = {
            id: id,
            ...newvals
        }
        this.props.update(input).then(result => { this.props.refetchInsurancesQuery() })
    }

    toggleSelectAll = () => {
        if (this.props.insurances && this.props.insurances.edges && this.props.insurances.edges.length > 0 && !this.state.selectAll) {
            this.props.setSelectedInsurances(this.props.insurances.edges.map(n => n.node))
        } else {
            this.props.setSelectedInsurances([])
        }
        this.setState({ selectAll: !this.state.selectAll })
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedTab !== prevProps.selectedTab)
            this.setState({ selectAll: false, openModal: "" })
    }

    render() {
        const { columnConfig } = this.props
        return (
            <PrimaryListingContainer listLoading={this.props.loading} loadMoreEntries={this.props.insurances && this.props.insurances.pageInfo && this.props.insurances.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} pageInfo={this.props.insurances && this.props.insurances.pageInfo} totalFetchedRows={this.props.insurances && this.props.insurances.edges.length} refetchListQuery={this.props.refetchInsurancesQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAll} className="select-all-checkbox"><i className={this.state.selectAll ? "fa fa-check-square-o" : "fa fa-square-o"}></i></th>
                            {columnConfig && columnConfig.filter(header => header.visible && header.view.includes(this.props.selectedTab)).map(header => (
                                header.sortable ?
                                    <th key={header.id} onClick={() => this.props.updateOrder(header.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(header.id)) ? <i className={"fa " + (this.props.orderBy.includes(header.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{header.name}</th> :
                                    <th key={header.id}>{header.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.insurances && this.props.insurances.edges && this.props.insurances.edges.length > 0 ? this.props.insurances.edges.filter(insurance => insurance.node).map((insurance, i) =>
                            <tr key={insurance.node.id} className={insurance.node.driver && insurance.node.driver.inOffice && "driver-in-office-row"}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedInsurances.map(insurance => insurance.id).includes(insurance.node.id) && <strong className="listing-counter">{i + 1}</strong>}
                                    <span className="listing-checkbox" onClick={() => this.updateSelected(insurance.node)}>
                                        {this.props.selectedInsurances.map(insurance => insurance.id).includes(insurance.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                {columnConfig && columnConfig.filter(column => column.visible).map(column =>
                                    <>
                                        {column.id === "date_added" && ["Active", "All Insurances"].includes(this.props.selectedTab) &&
                                            <td>
                                                <DatetimeRenderer datetime={insurance.node.dateAdded} placement={"left"} />
                                                <br /><small><i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(insurance.node.dateAdded)}</small>
                                            </td>
                                        }
                                        {column.id === "date_modified" && ["Pending - Commercial", "Pending - UW"].includes(this.props.selectedTab) &&
                                            <td>
                                                <DatetimeRenderer datetime={insurance.node.dateModified} placement={"left"} />
                                                <br /><small><i className="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;{datetimeToString(insurance.node.dateModified)}</small>
                                            </td>
                                        }
                                        {column.id === "driver__last_name" &&
                                            <td>
                                                {this.state.openModal === "MassMessageModal" + insurance.node.pk && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + insurance.node.pk)} open={this.state.openModal === "MassMessageModal" + insurance.node.pk} drivers={[insurance.node.driver.id]} />}
                                                {this.state.openModal === "driverDocuments" + insurance.node.pk && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + insurance.node.pk} objectType="driver" id={insurance.node.driver.id} currentUser={this.props.currentUser} refetchOnUpdate={this.props.refetchInsurancesQuery} />}
                                                {this.state.openModal === "insuranceInfoModal" + insurance.node.pk &&
                                                    <InsurancePreviewModal
                                                        open={this.state.openModal === "insuranceInfoModal" + insurance.node.pk}
                                                        id={insurance.node.id}
                                                        handleClose={() => this.toggleModal("insuranceInfoModal" + insurance.node.pk)}
                                                        refetchQuery={this.props.refetchInsurancesQuery}
                                                        currentUser={this.props.currentUser}
                                                    />
                                                }
                                                {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + String(insurance.node.pk)} placement="right" autohide={false} className="bos-custom-tooltip">
                                                    <label>{insurance.node.driver.name}</label>
                                                    <span className="bos-custom-tooltip-header">
                                                        <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{insurance.node.driver.email}<br />
                                                        <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{insurance.node.driver.phone}<br />
                                                    </span>
                                                    <p className="bos-custom-tooltip-body">
                                                        <b>DMV License:</b> {insurance.node.driver.dmvLicense || "--"}<br />
                                                        <b>TLC License:</b> {insurance.node.driver.tlcLicense || "--"}<br />
                                                        <b>Balance:</b> ${insurance.node.driver.balance || "0"}<br />
                                                    </p>
                                                    <span className="bos-custom-tooltip-footer">
                                                        {insurance.node.driver && <><Button onClick={() => this.toggleModal("MassMessageModal" + insurance.node.pk)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                        {insurance.node.driver && <><Button onClick={() => this.toggleModal("driverDocuments" + insurance.node.pk)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                        <Button onClick={() => this.toggleModal("insuranceInfoModal" + insurance.node.pk)}><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;&nbsp;More Info</Button>
                                                    </span>
                                                </UncontrolledTooltip>}
                                                <small>{insurance.node.driver.dmvLicense || "--"}</small>
                                                <br />

                                                <a id={"driverInfo" + String(insurance.node.pk)} className="bos-custom-link" href={'/driver/' + insurance.node.driver.id} target="_blank">
                                                    {insurance.node.driver.name.trim() ? insurance.node.driver.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                </a>
                                                {(!insurance.node.driver.mobileappdriver || !insurance.node.driver.mobileappdriver.id) && <>&nbsp;&nbsp;<i className={"fa fa-mobile text-danger fa-lg"} id={"driverNotUsingMobile" + i}></i> <UncontrolledTooltip placement="bottom" target={"driverNotUsingMobile" + i}>Not Using Mobile App</UncontrolledTooltip></>}
                                                {insurance.node.driver && insurance.node.driver.inOffice && <>&nbsp;&nbsp;<InOffice height={25} id={"inOffice" + String(insurance.node.pk)} width={25} fill="#198754" /><UncontrolledTooltip target={"inOffice" + String(insurance.node.pk)}>Driver in Office</UncontrolledTooltip></>}

                                                {this.props.statuses === 3 && insurance.node.driver && insurance.node.driver.agreementSet && insurance.node.driver.agreementSet.edges &&
                                                    insurance.node.driver.agreementSet.edges.length > 0 && insurance.node.driver.agreementSet.edges[0].node &&
                                                    insurance.node.driver.agreementSet.edges[0].node.currentReturn && insurance.node.driver.agreementSet.edges[0].node.currentReturn.returnCategory &&
                                                    insurance.node.driver.agreementSet.edges[0].node.currentReturn.returnCategory === "ACCIDENT_SWITCH" &&
                                                    <>
                                                        &nbsp;&nbsp;<Accident fill={"#DC3546"} id={"accident"} className="mb-2" />
                                                        <UncontrolledTooltip target={"accident"}>Accident Switch</UncontrolledTooltip>
                                                    </>
                                                }
                                            </td>
                                        }
                                        {column.id === "branch_id" &&
                                            <td>
                                                <Badge className='bos-badge-blue'>{insurance.node.driver.branch.name}</Badge>
                                            </td>
                                        }
                                        {column.id === "missing_docs" && ["Pending - Commercial", "Pending - UW"].includes(this.props.selectedTab) &&
                                            <td>
                                                {insurance.node.driver.missingInsuranceDocuments && insurance.node.driver.missingInsuranceDocuments.length > 0 ? insurance.node.driver.missingInsuranceDocuments.map(doc => <><Badge color="danger">{doc.typeName}</Badge>&nbsp;&nbsp;</>) : <i id={"required_document" + i} className="fa fa-check-circle text-success"></i>}
                                            </td>
                                        }
                                        {column.id === "status" && ["All Insurances"].includes(this.props.selectedTab) &&
                                            <td>
                                                <Badge style={{ background: renderEnumToInt(insurance.node.status) === 1 ? "green" : renderEnumToInt(insurance.node.status) === 3 ? "orange" : "red" }}>{this.props.DriverInsuranceStatusStatus.states.find(val => val.name === insurance.node.status).description}</Badge>
                                            </td>
                                        }
                                        {column.id === "insurance_notes" &&
                                            <td className="shortText">
                                                {this.state.openModal === insurance.node.pk + "insuranceNotesHistory" && <DriverNotes driver={insurance.node.driver} noteType="INSURANCE" objectId={insurance.node.id} title="Insurance Notes History" target={"insuranceNotesHistory" + insurance.node.pk} open={this.state.openModal === insurance.node.pk + "insuranceNotesHistory"} handleClose={() => this.toggleModal("")} placement="bottom" />}
                                                {this.state.openModal === insurance.node.pk + "insuranceNotes" &&
                                                    <UpdateInsuranceInfo
                                                        id={insurance.node.id}
                                                        target={"insuranceNotes" + insurance.node.pk}
                                                        handleClose={() => this.toggleModal(insurance.node.pk + "insuranceNotes")}
                                                        isOpen={this.state.openModal === insurance.node.pk + "insuranceNotes"}
                                                        refetchQuery={this.props.refetchInsurancesQuery}
                                                        title="Update Insurance Notes"
                                                        placeholder="Update Notes"
                                                        name="notes"
                                                        type="text"
                                                        defaultValue={insurance.node.notes ? insurance.node.notes : ""}
                                                    />
                                                }
                                                <small id={"notes" + insurance.node.pk}>{insurance.node.notes ? insurance.node.notes : "--"}</small>
                                                <br />
                                                <i id={"insuranceNotes" + insurance.node.pk} onClick={() => this.toggleModal(insurance.node.pk + "insuranceNotes")} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                <i id={"insuranceNotesHistory" + insurance.node.pk} onClick={() => this.toggleModal(insurance.node.pk + "insuranceNotesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                                <UncontrolledTooltip placement="right" target={"notes" + insurance.node.pk}>{insurance.node.notes} </UncontrolledTooltip>
                                            </td >
                                        }
                                        {
                                            column.id === "driver_notes" &&
                                            <td className="shortText" style={{ maxWidth: "15rem" }}>
                                                <small id={"driverNotes" + insurance.node.driver.pk}>{insurance.node.driver.notes ? insurance.node.driver.notes : "--"}</small>
                                                <UncontrolledTooltip placement="right" target={"driverNotes" + insurance.node.driver.pk}>{insurance.node.driver.notes} </UncontrolledTooltip>
                                                <br />
                                                {this.state.openModal === i + "notesHistory" && <DriverNotes driver={insurance.node.driver} noteType="Customer Service" title="Driver Notes History" target={"notesHistory" + i} open={this.state.openModal === i + "notesHistory"} handleClose={() => this.toggleModal(i + "notesHistory")} placement={"left"} />}
                                                {this.state.openModal === "notesEdit" + i && <UpdateDriverInfo driverId={insurance.node.driver.id} title="Edit Driver Notes" name="notes" value={insurance.node.driver.notes}
                                                    target={"notesEdit" + i} id="notes" type="textarea" open={this.state.openModal === "notesEdit" + i}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchInsurancesQuery}
                                                />}
                                                <i id={"notesEdit" + i} onClick={() => this.toggleModal("notesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                <i id={"notesHistory" + i} onClick={() => this.toggleModal(i + "notesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            </td>
                                        }
                                        {
                                            column.id === "tags" &&
                                            <td>
                                                <div className="d-flex">
                                                    <span className='pr-3 align-self-center'>
                                                        <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("drivertags" + insurance.node.driver.pk)} id={"drivertags" + insurance.node.driver.pk}></i>
                                                        <DriverUpdateTagsPopover
                                                            isOpen={this.state.openModal === "drivertags" + insurance.node.driver.pk}
                                                            target={"drivertags" + insurance.node.driver.pk}
                                                            handleClose={() => this.toggleModal("")}
                                                            driverId={insurance.node.driver.id}
                                                            driverTags={insurance.node.driver.tags}
                                                            refetchQuery={this.props.refetchInsurancesQuery}
                                                        />
                                                    </span>
                                                    <span className='pl-3' style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                        {insurance.node.driver.tags && insurance.node.driver.tags.length > 0 ? insurance.node.driver.tags.map((tag, i) =>
                                                            <><Badge key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge></>
                                                        ) : <small className="text-danger">No Tags Found</small>}
                                                    </span>
                                                </div>
                                            </td>
                                        }
                                        {
                                            column.id === "assigned_to" &&
                                            <td>
                                                <div className="d-flex">
                                                    <span className='pr-3 align-self-center'>
                                                        <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("rep" + insurance.node.pk)} id={"rep" + insurance.node.pk}></i>
                                                        {this.state.openModal === "rep" + insurance.node.pk &&
                                                            <UpdateInsuranceInfo
                                                                id={insurance.node.id}
                                                                target={"rep" + insurance.node.pk}
                                                                handleClose={() => this.toggleModal("rep" + insurance.node.pk)}
                                                                isOpen={this.state.openModal === "rep" + insurance.node.pk}
                                                                refetchQuery={this.props.refetchInsurancesQuery}
                                                                title="Assign Reps"
                                                                placeholder="Update Reps"
                                                                name="representative"
                                                                type="select"
                                                                defaultValue={insurance.node.createdBy ? { value: insurance.node.createdBy.id, label: insurance.node.createdBy.firstName ? insurance.node.createdBy.firstName + " " + insurance.node.createdBy.lastName : insurance.node.createdBy.username } : null}
                                                            />
                                                        }
                                                    </span>
                                                    <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                        {insurance.node.createdBy ?
                                                            <span>{insurance.node.createdBy.firstName ? insurance.node.createdBy.firstName + " " + insurance.node.createdBy.lastName : insurance.node.createdBy.username}</span> : "--"
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                        }
                                    </>
                                )}
                            </tr >
                        ) : !this.props.loading &&
                        <tr>
                            <td>No Insurances Found</td>
                        </tr>
                        }
                    </tbody >
                </Table >
            </PrimaryListingContainer >
        )
    }
}

export default compose(
    graphql(InsuranceStatusQuery, {
        options: (({ searchTerm, driverTags, assignedTo, statuses, hasMissingDocs, driverStages, orderBy, hasRental, inOffice, types }) => ({
            variables: {
                searchTerm,
                driverTags,
                assignedTo,
                driverStages,
                statuses,
                hasMissingDocs,
                orderBy,
                hasRental,
                inOffice,
                types,
                first: 30,
                loadAgreementSet: statuses && statuses === 3 ? true : false,
                loadMissingInsuranceDoc: statuses && statuses === 3 ? true : false
            },
            notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
        })),
        props({ data: { insurances, loading, refetch, fetchMore, variables } }) {
            return {
                insurances, loading, fetchMore, refetch,
                refetchInsurancesQuery: () => {
                    let currentLength = insurances && insurances.edges ? insurances.edges.length : 30
                    return fetchMore({
                        query: InsuranceStatusQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.insurances.edges
                            let pageInfo = fetchMoreResult.insurances.pageInfo
                            return {
                                insurances: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.insurances.__typename
                                }
                            }
                        }
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: InsuranceStatusQuery,
                        variables: {
                            cursor: insurances.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.insurances.edges
                            const pageInfo = fetchMoreResult.insurances.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                insurances: {
                                    edges: [...previousResult.insurances.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.insurances.__typename
                                },
                            }
                        },
                    })
                }

            }
        }
    }),
    graphql(UpdateInsuranceStatus, {
        props: ({ mutate }) => ({
            update: (input) => mutate({ variables: { input } }),
        }),
    }),
    graphql(DriverInsuranceStatus, { props({ data: { __type: DriverInsuranceStatusStatus } }) { return { DriverInsuranceStatusStatus } } }),
)(InsuranceList)
