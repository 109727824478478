import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap'
import moment from 'moment-timezone'
import DatetimeRenderer from "./DatetimeRenderer"

const CarNotesHistory = gql`
    query CarNotesHistory($carId:ID!, $noteType:String, $objectId:ID, $first:Int){
        carNotesHistory(carId:$carId, noteType: $noteType, objectId: $objectId, first:$first){
            edges{
                node{
                    id
                    details
                    dateAdded
                    user {
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
            pageInfo{
                hasNextPage
            }
      }
    }
`


class CarNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            carId: props.car && props.car.id ? props.car.id : null,
            carNotesHistory: null,
            loading: false,
            limit: 3,
            hasNextPage: false
        }
    }
    fetchData = (carId) => {
        if (carId) {
            this.setState({ loading: true })
            let input = { carId: carId, first: this.state.limit }
            if (this.props.noteType)
                input["noteType"] = this.props.noteType
            else
                input["noteType"] = 'General'
            if (this.props.objectId)
                input["objectId"] = this.props.objectId
            this.props.client.query({
                query: CarNotesHistory,
                variables: input,
            }).then((result) => {
                if (result && result.data && result.data.carNotesHistory) {
                    let edges = result.data.carNotesHistory.edges
                    let hasNextPage = false
                    if (result.data.carNotesHistory.pageInfo && result.data.carNotesHistory.pageInfo.hasNextPage) {
                        hasNextPage = result.data.carNotesHistory.pageInfo.hasNextPage
                    }
                    this.setState({ carNotesHistory: edges, hasNextPage: hasNextPage, limit: this.state.limit + 3, loading: false })
                }
            }).catch((err) => {
                this.setState({ loading: false })
            })
        } else {
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.car !== prevProps.car || (this.props.car && prevProps.open !== this.props.open)) {
            this.fetchData(this.props.car.id)
        }
    }
    componentDidMount() {
        if (this.props.car) {
            this.fetchData(this.props.car.id)
        }
    }

    render() {
        return (
            <div>
                <Popover placement="right" isOpen={this.props.open} target={this.props.target}>
                    <PopoverHeader onClick={this.props.handleClose}>Car Notes <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.loading ?
                            <p>Loading Car Notes...</p> :
                            <>
                                {this.state.carNotesHistory && this.state.carNotesHistory.map((note, i) =>
                                    <p key={i}><b>On {<DatetimeRenderer datetime={note.node.dateAdded} format={"dddd, MMMM Do YYYY, h:mm a"} />}</b>{note.node.details}</p>
                                )}
                                {this.state.hasNextPage && this.state.carId &&
                                    <Button type="button" size="sm" onClick={() => this.fetchData(this.state.carId)}>Show More</Button>
                                }
                            </>
                        }

                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
)(CarNotes)