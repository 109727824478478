import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import toTitleCase from "../../Functions/toTitleCase"
import UpdateFinancialAccount from "./UpdateFinancialAccount"
import { HasPermissionsQuery } from "../../Functions/querys"
import ConfirmationPopup from "../../Material/ConfirmationPopup"

const DeleteFinancialAccount = gql`mutation DeleteFinancialAccount($input: DeleteFinancialAccountInput!){
    deleteFinancialAccount(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const FinancialAccountsQuery = gql`query FinancialAccountsQuery($cursor: String, $first: Int!, $searchTerm: String, $branchIds: [ID], $orderBy: [String]) {
    allFinancialAccounts(first: $first, after: $cursor, searchTerm: $searchTerm, branchIds: $branchIds, orderBy: $orderBy){
        edges{
            node{
                id
                pk
                dateAdded
                dateModified
                creditAccountNumber
                debitAccountNumber
                carType
                createdBy {
                    id
                    username
                    firstName
                    lastName
                }
                chargeType{
                    id
                    name
                    salestaxSet {
                        edges {
                            node {
                                id
                                branches {
                                    edges {
                                        node {
                                            id
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                subChargeType{
                    id
                    name
                }
                branches {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    } 
}`


const tableHeaders = [
    { id: "id", name: "ID", sortable: true },
    { id: "date_modified", name: "Last Modified", sortable: true },
    { id: "chargeType", name: "Charge Type", sortable: true },
    { id: "carType", name: "Car Type", sortable: true },
    { id: "creditAccountNumber", name: "Credit Account", sortable: true },
    { id: "debitAccountNumber", name: "Debit Account", sortable: true },
    { id: "branches", name: "Branches", sortable: false },
    { id: "isTaxable", name: "Is Taxable", sortable: true },
    { id: "created_by", name: "Created By", sortable: false },
    { id: "action", name: "Action", sortable: false },
]

class BranchList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    deleteFinancialAccount = (id) => {
        try {
            if (id) {
                this.setState({ loading: true })
                let input = {
                    id: id
                }
                this.props.client.mutate({
                    mutation: DeleteFinancialAccount,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.deleteFinancialAccount && result.data.deleteFinancialAccount.ok) {
                        this.props.refetchQuery()
                        this.setState({ error: "", openModal: "" })
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.deleteFinancialAccount && result.data.deleteFinancialAccount.errors && result.data.deleteFinancialAccount.errors[0] && result.data.deleteFinancialAccount.errors[0].messages) {
                            error = result.data.deleteFinancialAccount.errors[0].messages.toString()
                        }
                        this.setState({ error: error })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let error = "An error has occured: Please contact admin: " + err
                    this.setState({ error: error })
                    this.setState({ loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field: Please Contact Admin" })
        }
        catch (err) {
            let error = "An error has occured: " + err
            this.setState({ error: error })
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.allFinancialAccounts && this.props.allFinancialAccounts.edges.length} pageInfo={this.props.allFinancialAccounts && this.props.allFinancialAccounts.pageInfo} loadMoreEntries={this.props.allFinancialAccounts && this.props.allFinancialAccounts.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.filter(item => this.props.isBranchView ? !["branches", "action"].includes(item.id) : !["isTaxable"].includes(item.id)).map(tableHeader => {
                                return (
                                    tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrderBy(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.includes(tableHeader.id) ? <i className={"fa " + (this.props.orderBy === tableHeader.id ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allFinancialAccounts && this.props.allFinancialAccounts.edges && this.props.allFinancialAccounts.edges.length > 0 ? this.props.allFinancialAccounts.edges.map((account, i) =>
                            <tr key={account.node.id}>
                                <td>
                                    <strong>{account.node.pk}</strong>
                                </td>
                                <td>
                                    {account.node.dateModified ? <span><DatetimeRenderer datetime={account.node.dateModified} /></span> : '--'}
                                </td>
                                <td>
                                    {account.node.subChargeType && account.node.subChargeType.name ? `${account.node.subChargeType.name} - ` : ""}{account.node.chargeType && account.node.chargeType.name ? account.node.chargeType.name : "--"}
                                </td>
                                <td>
                                    {toTitleCase(account.node.carType)}
                                </td>
                                <td>
                                    {account.node.creditAccountNumber}
                                    {!this.props.isBranchView &&
                                        <>
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            {
                                                this.state.openModal === "creditAccountNumberEdit" + account.node.pk &&
                                                <UpdateFinancialAccount
                                                    financialAccountId={account.node.id}
                                                    title="Update Credit Account"
                                                    name="creditAccountNumber"
                                                    value={account.node.creditAccountNumber}
                                                    target={"creditAccountNumberEdit" + account.node.pk}
                                                    id={"creditAccountNumberEdit" + account.node.pk}
                                                    type="text"
                                                    open={this.state.openModal === "creditAccountNumberEdit" + account.node.pk}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchDriverQuery={this.props.refetchQuery}
                                                />
                                            }
                                            <i className={"fa fa-pencil"} onClick={() => this.toggleModal("creditAccountNumberEdit" + account.node.pk)} id={"creditAccountNumberEdit" + account.node.pk}></i>&nbsp;&nbsp;
                                        </>
                                    }
                                </td>
                                <td>
                                    {account.node.debitAccountNumber}
                                    {!this.props.isBranchView && <>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        {this.state.openModal === "debitAccountNumberEdit" + account.node.pk &&
                                            <UpdateFinancialAccount
                                                financialAccountId={account.node.id}
                                                title="Update Credit Account"
                                                name="debitAccountNumber"
                                                value={account.node.debitAccountNumber}
                                                target={"debitAccountNumberEdit" + account.node.pk}
                                                id={"debitAccountNumberEdit" + account.node.pk}
                                                type="text"
                                                open={this.state.openModal === "debitAccountNumberEdit" + account.node.pk}
                                                handleClose={() => this.toggleModal("")}
                                                refetchDriverQuery={this.props.refetchQuery}
                                            />
                                        }
                                        <i className={"fa fa-pencil"} onClick={() => this.toggleModal("debitAccountNumberEdit" + account.node.pk)} id={"debitAccountNumberEdit" + account.node.pk}></i>&nbsp;&nbsp;
                                    </>}
                                </td>
                                {!this.props.isBranchView && <td>
                                    {account.node.branches && account.node.branches.edges && account.node.branches.edges.length > 0 ? account.node.branches.edges.filter((_, i) => i < 2).map((branch, i) =>
                                        <><Link to={`/organization/branch/${branch.node.id}`}>{branch.node.name}</Link>,&nbsp;&nbsp;</>
                                    ) : "--"}
                                    {account.node.branches && account.node.branches.edges && account.node.branches.edges.length > 2 && <>
                                        <span id={"more-branches-" + account.node.pk}>+{account.node.branches.edges.length - 2} more</span>
                                        <UncontrolledTooltip className="more-info-tooltip" target={"more-branches-" + account.node.pk} placement="right" delay={{ "show": 400, "hide": 200 }} autohide={false}>
                                            {account.node.branches.edges.filter((_, i) => i >= 2).map((branch, i) =>
                                                <>
                                                    <Link to={`/organization/branch/${branch.node.id}`}>{branch.node.name}</Link><br />
                                                </>
                                            )}
                                        </UncontrolledTooltip>
                                    </>}
                                    &nbsp;&nbsp;<i className={"fa fa-pencil"} onClick={() => this.toggleModal("editBranches" + account.node.pk)} id={"editBranches" + account.node.pk}></i>
                                    {this.state.openModal === "editBranches" + account.node.pk &&
                                        <UpdateFinancialAccount
                                            financialAccountId={account.node.id}
                                            title="Add New Branches"
                                            name="branchIds"
                                            value={account.node.branches.edges.map(branch => branch.node.id)}
                                            target={"editBranches" + account.node.pk}
                                            id={"editBranches" + account.node.pk}
                                            type="select"
                                            open={this.state.openModal === "editBranches" + account.node.pk}
                                            handleClose={() => this.toggleModal("")}
                                            refetchDriverQuery={this.props.refetchQuery}
                                        />
                                    }
                                </td>}
                                {this.props.isBranchView && <td>
                                    {account.node.chargeType && account.node.chargeType.salestaxSet && account.node.chargeType.salestaxSet.edges && account.node.chargeType.salestaxSet.edges.length > 0 && account.node.chargeType.salestaxSet.edges[0].node.branches && account.node.chargeType.salestaxSet.edges[0].node.branches.edges && account.node.chargeType.salestaxSet.edges[0].node.branches.edges.length > 0 &&
                                        account.node.chargeType.salestaxSet.edges[0].node.branches.edges.find((branch, i) => {
                                            return branch.node.id === this.props.branchIds
                                        }) ? <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i> : <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-danger" ></i>
                                    }
                                </td>}
                                <td>
                                    {account.node.createdBy ? account.node.createdBy.firstName ? account.node.createdBy.firstName + " " + account.node.createdBy.lastName : account.node.createdBy.username : "--"}
                                </td>
                                {!this.props.isBranchView && <td>
                                    <i className="fa fa-lg fa-trash" aria-hidden="true" id={"deleteFinancialAccount" + account.node.pk} onClick={() => this.toggleModal("deleteFinancialAccount" + account.node.pk)} style={{ color: "red", opacity: !this.props.hasPermissions.includes("delete_financialaccount") ? 0.3 : 1 }}></i>
                                    {!this.props.hasPermissions.includes("delete_financialaccount") && <UncontrolledTooltip target={"deleteFinancialAccount" + account.node.pk}>Not Enough Permissions To Perform This Action</UncontrolledTooltip>}
                                    {this.state.openModal === "deleteFinancialAccount" + account.node.pk && this.props.hasPermissions.includes("delete_financialaccount") &&
                                        <ConfirmationPopup
                                            open={this.state.openModal === "deleteFinancialAccount" + account.node.pk}
                                            message={"ARE YOU SURE YOU WANT TO DELETE THIS FINANCIAL ACCOUNT?"}
                                            loading={this.state.loading}
                                            handleClose={() => this.toggleModal("")}
                                            action={"Yes"}
                                            confirmAction={() => this.deleteFinancialAccount(account.node.id)}
                                            color="danger"
                                            error={this.state.error ? this.state.error : ""}
                                        />
                                    }
                                </td>}
                            </tr>) :
                            <tr>
                                <td colSpan={7}>No Financial Accounts Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}
export default compose(
    withApollo,
    graphql(FinancialAccountsQuery, {
        options: ({ searchTerm, branchIds, orderBy }) => ({
            variables: {
                searchTerm, branchIds, orderBy,
                first: 30,
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, allFinancialAccounts, fetchMore, variables, refetch } }) {
            return {
                loading,
                allFinancialAccounts,
                variables,
                refetch,
                refetchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allFinancialAccounts && allFinancialAccounts.edges ? allFinancialAccounts.edges.length : 30
                    return fetchMore({
                        query: FinancialAccountsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allFinancialAccounts.edges
                            const pageInfo = fetchMoreResult.allFinancialAccounts.pageInfo
                            return {
                                allFinancialAccounts: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allFinancialAccounts.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: FinancialAccountsQuery,
                        variables: {
                            cursor: allFinancialAccounts.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allFinancialAccounts.edges
                            const pageInfo = fetchMoreResult.allFinancialAccounts.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allFinancialAccounts: {
                                    edges: [...previousResult.allFinancialAccounts.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allFinancialAccounts.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),

    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["delete_financialaccount"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(BranchList)
