import React, { Component } from 'react'
import gql from 'graphql-tag'
import PageCrash from "../Components/PageCrash"

const CREATE_CLOUD_LOG = gql`
  mutation CreateCloudLog($input: CreateCloudLogInput!) {
    createCloudLog(input: $input) {
      ok
      errors {
        field
        messages
      }
    }
  }
`

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: "" }
        this.logErrorToBackend = this.logErrorToBackend.bind(this)
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        this.logErrorToBackend(error, errorInfo)
        this.setState({ error: error.toString(), hasError: true })
    }

    logErrorToBackend = (error, errorInfo) => {
        const logData = {
            error: error.toString(),
            stackTrace: errorInfo.componentStack,
            location: window.location && window.location.href ? window.location.href : "No location detected."
        }
        const input = {
            message: JSON.stringify(logData),
            logType: "error",
        }

        this.props.client.mutate({
            mutation: CREATE_CLOUD_LOG,
            variables: { input },
        })
            .then(result => {
                if (result.data.createCloudLog.ok) {
                    console.log("Error logged successfully.")
                } else if (result.data.createCloudLog.errors.length > 0) {
                    console.error("Logging error failed:", result.data.createCloudLog.errors)
                }
            })
            .catch(err => {
                console.error("Error logging to backend:", err)
            })
    };

    render() {
        if (this.state.hasError) {
            return <PageCrash title={"Oops!"} error={this.state.error} />
        }

        return this.props.children
    }
}

export default ErrorBoundary
