import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "reactstrap"
import { Route, Switch } from "react-router-dom"
import AutomatelyListHeader from "./AutomatelyListHeader"
import AutomationForm from "./AutomationForm"
import AutomationList from "./AutomationList"
import AutomationDetailContainer from "./AutomationDetailContainer"

export default function AutomatelyContainer() {



    return (
        <div className="AutomatelyContainer">
            <Switch>
                <Route path="/automations/new" render={(props) => <AutomationForm {...props} />} />
                <Route path="/automations/:id" render={(props) => <AutomationDetailContainer {...props} />} />
                <Route exact path="/automations" render={(props) => <AllAutomations {...props} />} />
            </Switch>

        </div>

    )
}

const AllAutomations = () => {
    const [filterValues, setFilterValues] = useState(() => {
        // Retrieve filters from localStorage, if they exist
        const savedFilters = localStorage.getItem('AutomationContainerFilters')
        return savedFilters ? JSON.parse(savedFilters) : { searchTerm: "" }
    })
    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        // Save filters to localStorage whenever they change
        localStorage.setItem('AutomationContainerFilters', JSON.stringify(filterValues))
    }, [filterValues])
    return (
        <Container fluid>
            <AutomatelyListHeader
                activeTab="all"
                filterValues={filterValues}
                setFilterValues={(res) => setFilterValues({ ...res })}
                selectedIds={selectedIds}
                resetSelectedIds={() => setSelectedIds([])}
            />
            <Row>
                <Col>
                    <AutomationList {...filterValues} />
                </Col>
            </Row>
        </Container>
    )
}