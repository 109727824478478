import gql from 'graphql-tag'
const DriverNotesDetails = gql`
    query DriverNotesDetails($notesId:ID!){
        driverNotesDetails(notesId:$notesId){
            id
            dateAdded
            noteType
            user {
                id
                username
                firstName
                lastName
            }
            changedFrom
            changedTo
      }
    }
`
const CarNotesDetails = gql`
    query CarNotesDetails($notesId:ID!){
        carNotesDetails(notesId:$notesId){
            id
            dateAdded
            noteType
            user {
                id
                username
                firstName
                lastName
            }
            changedFrom
            changedTo
      }
    }
`

const DriverNotesHistory = gql`
    query DriverNotesHistory($driverId:ID!, $noteType:String, $objectId:ID, $first:Int){
        driverNotesHistory(driverId:$driverId, noteType: $noteType, objectId: $objectId, first:$first){
            edges{
                node{
                    id
                    dateAdded
                    changedTo
                }
            }
            pageInfo{
                hasNextPage
            }
        }
    }
`

const CarNotesHistory = gql`
    query CarNotesHistory($carId:ID!, $noteType:String, $objectId:ID, $first:Int){
        carNotesHistory(carId:$carId, noteType: $noteType, objectId: $objectId, first:$first){
            edges{
                node{
                    id
                    dateAdded
                    changedTo
                }
            }
            pageInfo{
                hasNextPage
            }
        }
    }
`
export { DriverNotesDetails, CarNotesDetails, DriverNotesHistory, CarNotesHistory }