import React, { Component } from 'react'
import { compose, graphql, withApollo, Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText } from 'reactstrap'
import moment from 'moment-timezone'
import toTitleCase from '../../Functions/toTitleCase'
import Select from 'react-select'
import { HasPermissionsQuery } from "../../Functions/querys"

const countries = [{ label: "United States", value: "US" }, { label: "Mexico", value: "MX" }]
const genders = [{ label: "Male", value: "M", }, { label: "Female", value: "F" }, { label: "Other", value: "O" }]

const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypesQuery{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                  requiresExpiration
                  documentCategory {
                    id
                    name
                  }
                }
            }
      }
    }
`

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
        allDriverDocumentTypes(isActive:true, driverId: $driverId){
            edges{
                node{
                  typeName
                  id
                }
            }
        }
    }
`

const UploadDocumentMutation = gql`
mutation uploadDocumentMutation($input: UploadDocumentMutationInput!){
    uploadDocument(input:$input){
    ok
    errors{
        messages
    }
    documentData {
        fieldCode
        value
    }
}} `

const DriverDocumentDataQuery = gql`query DriverDocumentData($driverId: ID!){
    driverDocumentData(driverId: $driverId){
        documentData {
            documentName
            requiredData {
                fieldCode
                fieldType
                displayName
                value
                dataCategory
            }
        }
        tenantName
    }
}`

const UploadRentalContractMutation = gql`
mutation UploadRentalContractMutation($input: UploadRentalContractMutationInput!){
    uploadRentalContract(input:$input){
    ok
    errors{
        messages
    }
}} `

class DocumentDataSelect extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { field, handleDriverDataChange } = this.props
        const [options, optionName] = field.fieldCode === "GENDER"
            ? [genders, 'Gender']
            : [countries, 'Country']
        return (
            <Select
                className="bos-custom-select"
                classNamePrefix="bos-select"
                placeholder={`Select ${optionName}`}
                options={options}
                defaultValue={options.find(option => option.value === field.value)}
                name={field.fieldCode}
                onChange={(selectedOption) => {
                    handleDriverDataChange({
                        name: field.fieldCode,
                        value: selectedOption ? selectedOption.value : ''
                    })
                }}
            />
        )
    }
}

class DocumentDataInput extends Component {
    constructor(props) {
        super(props)
    }
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.field !== this.props.field ||
            nextProps.handleDriverDataChange !== this.props.handleDriverDataChange ||
            nextProps.hasIssuedExpirationDates !== this.props.hasIssuedExpirationDates
        )
    }

    render() {
        const { field, handleDriverDataChange, hasIssuedExpirationDates = false } = this.props
        return (
            <Input
                type={field.fieldType}
                name={field.fieldCode}
                value={field.value || ''}
                onChange={(e) => {
                    // If fieldCode is for any type of ZIPCODE (TAX,ID,MAIN), then keep using an Input of type:'text' but send numbersOnly=true
                    field.fieldCode.startsWith("ZIPCODE") ? handleDriverDataChange(e, true)
                        : handleDriverDataChange(e)
                }}
                // Need to have max/min exceptions for different type of dates
                max={field.fieldCode === 'DATE_OF_BIRTH' || field.fieldCode === 'ISSUED_DATE' ? new Date().toISOString().split('T')[0] : undefined}
                min={hasIssuedExpirationDates && field.fieldCode === 'EXPIRATION_DATE'
                    ? new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
                    : field.fieldCode === 'EXPIRATION_DATE'
                        ? new Date().toISOString().split('T')[0]
                        : undefined
                }
                style={{
                    backgroundColor: field.value && field.value.toString().trim() !== "" ? '' : 'rgba(255, 0, 0, 0.1)'
                }}
            />
        )
    }
}

class UploadDocumentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            documentUploaded: false,
            document: null,
            documentType: "",
            openModal: "",
            fileName: null,
            requiresExpiration: false,
            documentExpirationDate: null,
            documentTypeDocumentData: [],
            actionSuccess: false,
            allInputsFilled: false,
            tenantName: "",
            hasIssuedExpirationDates: false
        }
    }

    checkAllInputsFilled = () => {
        const allFilled = this.state.documentTypeDocumentData.every(
            (field) => field.value && field.value.toString().trim() !== ""
        )
        this.setState({ allInputsFilled: allFilled })
    }

    handleDriverDataChange = (input, numbersOnly = false) => {
        let { name, value } = input.target || input
        if (input.target && input.target.type == 'text' && !numbersOnly) {
            value = value.replace(/[^a-zA-Z0-9\s]/g, '')
        } else if (numbersOnly) {
            value = value.replace(/[^0-9]/g, '')
        }

        this.setState(prevState => ({
            documentTypeDocumentData: prevState.documentTypeDocumentData.map(field =>
                field.fieldCode === name
                    ? { ...field, value: value }
                    : field
            )
        }), this.checkAllInputsFilled)
    }

    handleClose = (e) => {
        let state = {
            loading: false,
            error: null,
            documentUploaded: false,
            document: null,
            documentType: "",
            openModal: "",
            fileName: null,
            requiresExpiration: false,
            documentExpirationDate: null,
            documentTypeDocumentData: [],
            actionSuccess: false,
            allInputsFilled: false
        }
        this.setState({ ...state })
        this.props.refetchQuery()
        this.props.handleClose()
    };

    uploadDocument = () => {
        try {
            this.setState({ loading: true })
            if (!this.props.agreementId && !this.state.documentType || (this.state.requiresExpiration && !this.state.documentExpirationDate)) {
                this.setState({ loading: false, documentUploaded: false, error: "Missing input parameters, please put all the required details." })
            }

            let documentData = []
            for (const obj of this.state.documentTypeDocumentData) {
                documentData.push({
                    fieldCode: obj.fieldCode,
                    value: obj.value
                })
            }

            let input = {
                file: this.state.document
            }
            if (this.props.agreementId) {
                input["agreementId"] = this.props.agreementId
            }
            else {
                input[this.props.objectType === "car" ? "carId" : "driverId"] = this.props.id
                input["documentTypeId"] = this.state.documentType
                input["fileName"] = this.state.fileName
                input["expiration"] = this.state.documentExpirationDate
                input["documentData"] = documentData
            }
            this.props.client.mutate({
                mutation: this.props.agreementId ? UploadRentalContractMutation : UploadDocumentMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.uploadDocument && result.data.uploadDocument.ok) {
                    this.setState({ loading: false, documentUploaded: true, error: null })
                    this.handleClose()
                } else if (result.data && result.data.uploadRentalContract && result.data.uploadRentalContract.ok) {
                    this.setState({ loading: false, documentUploaded: true, error: null })
                    this.handleClose()
                }
                else if (result && result.data && result.data.uploadDocument && result.data.uploadDocument.errors && result.data.uploadDocument.errors[0] && result.data.uploadDocument.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.uploadDocument.errors[0].messages.toString() })
                } else if (result && result.data && result.data.uploadRentalContract && result.data.uploadRentalContract.errors && result.data.uploadRentalContract.errors[0] && result.data.uploadRentalContract.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.uploadRentalContract.errors[0].messages.toString() })
                }
                else {
                    this.setState({ loading: false, documentUploaded: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, documentUploaded: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, documentUploaded: false, })
        }
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    checkHasIssuedExpirationDates = (documentData) => {
        const hasIssuedDate = documentData.some(item => item.fieldCode === 'ISSUED_DATE')
        const hasExpirationDate = documentData.some(item => item.fieldCode === 'EXPIRATION_DATE')
        if (hasIssuedDate && hasExpirationDate) {
            this.setState({ hasIssuedExpirationDates: true })
        } else {
            this.setState({ hasIssuedExpirationDates: false })
        }
    }

    updateDocument = (e) => {
        let base64Img = e.target.files[0]
        let name = e.target.files[0].name
        let _this = this
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            _this.setState({ document: imageInBase64, fileName: name })
        }
    }

    updateDocType = (documentTypeId) => {
        let documentTypes = this.props.objectType === "car" ? this.props.allCarDocumentTypes : this.props.allDriverDocumentTypes
        let selectedType = documentTypes.edges.filter(type => type.node.id === documentTypeId)[0]
        let selectedTypeName = selectedType.node.typeName
        let hasRequiredData = false

        if (this.props.driverDocumentData.tenantName === 'Mi Nave') {
            let allDriverDocumentData = this.props.driverDocumentData.documentData
            for (const field of allDriverDocumentData) {
                if (field.documentName == selectedTypeName) {
                    hasRequiredData = true
                    this.checkHasIssuedExpirationDates(field.requiredData)
                    this.setState({ documentTypeDocumentData: field.requiredData })
                    break
                }
            }
            if (!hasRequiredData) {
                this.setState({ documentTypeDocumentData: [] })
            }
        }

        this.setState({ documentType: documentTypeId, requiresExpiration: selectedType.node.requiresExpiration })
    }

    render() {
        let documentTypes =
            this.props.objectType === "car" ?
                this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_upload_tlc_docs") ?
                    this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges :
                    this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.filter(carDocumentType => carDocumentType.node.typeName.toLowerCase() != "registration" && carDocumentType.node.documentCategory && carDocumentType.node.documentCategory.name != "TLC") :
                this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.agreementId ? this.props.allDriverDocumentTypes.edges.filter(item => item.node.typeName.toLowerCase() === "contract") : this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 &&
                    this.props.allDriverDocumentTypes.edges.filter(item => item.node.typeName.toLowerCase() !== "contract")

        return (
            <div>
                <Modal className="uploadDocument" isOpen={this.props.open}>
                    {this.props.id && <>
                        <ModalHeader>Upload Document For {toTitleCase(this.props.objectType)} </ModalHeader>
                        <ModalBody>
                            {this.state.error && !this.state.documentUploaded && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.state.documentUploaded && !this.state.error && <Row><Col xs={12}><Alert color="success">Document Uploaded successfully!</Alert></Col></Row>}
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="documentType">Select Document Type</Label>
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={this.props.allCarDocumentTypesLoading || this.props.allDriverDocumentTypesLoading || this.props.driverDocumentDataLoading}
                                            options={documentTypes && documentTypes.length > 0 ? documentTypes.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                            placeholder="Select Document Type"
                                            onChange={docType => this.updateDocType(docType.value)}
                                            defaultValue={this.props.agreementId ? { value: "", label: 'Contract' } : null}
                                            value={this.props.agreementId ? { value: "", label: 'Contract' } : documentTypes && documentTypes.length > 0 && documentTypes.find(item => item.node.id === this.state.documentType) ? { value: this.state.documentType, label: documentTypes && documentTypes.length > 0 && documentTypes.find(item => item.node.id === this.state.documentType).node.typeName } : null}
                                            isDisabled={!!this.props.agreementId}
                                        />
                                    </FormGroup>
                                    {this.state.document && <img src={"data:image/jpeg;base64," + this.state.document} width="100%" />}
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="document">Select File</Label>
                                        <Input type="file" name="document" id="document" onChange={this.updateDocument} accept=".pdf, image/*" />
                                        <FormText color="muted">
                                            Accepted files include pdf,jpg,jpeg and pngs
                                        </FormText>
                                    </FormGroup>
                                </Col>
                                {this.state.documentType && this.state.document && (this.props.driverDocumentData.tenantName === 'Mi Nave') && this.state.documentTypeDocumentData.length > 0 && <>
                                    <Col xs={12}>
                                        <Label for="documentDataInputs">Complete the missing file's data</Label>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="documentCategoryDriver">ID Information</Label>
                                            <Row style={{ marginBottom: "10px" }}>
                                                {this.state.documentTypeDocumentData && this.state.documentTypeDocumentData.map((field, index) => {
                                                    if (field.dataCategory === "ID") {
                                                        return (
                                                            <Col xs={4} key={index}>
                                                                <Label>{field.displayName.toUpperCase() || ''}</Label>
                                                                <DocumentDataInput
                                                                    field={field}
                                                                    handleDriverDataChange={this.handleDriverDataChange}
                                                                    hasIssuedExpirationDates={this.state.hasIssuedExpirationDates} />
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                            <Label for="documentCategoryDriver">Driver Information</Label>
                                            <Row style={{ marginBottom: "10px" }}>
                                                {this.state.documentTypeDocumentData && this.state.documentTypeDocumentData.map((field, index) => {
                                                    if (field.dataCategory === "driver") {
                                                        return (
                                                            <Col xs={4} key={index}>
                                                                <Label>{field.displayName.toUpperCase() || ''}</Label>
                                                                {field.fieldCode === "GENDER" ? <DocumentDataSelect
                                                                    field={field}
                                                                    handleDriverDataChange={this.handleDriverDataChange}
                                                                /> :
                                                                    <DocumentDataInput field={field} handleDriverDataChange={this.handleDriverDataChange} />
                                                                }
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                            <Label for="documentCategoryDriver">Address Information</Label>
                                            <Row style={{ marginBottom: "10px" }}>
                                                {this.state.documentTypeDocumentData && this.state.documentTypeDocumentData.map((field, index) => {
                                                    if (field.dataCategory === "address") {
                                                        return (
                                                            <Col xs={4} key={index}>
                                                                <Label>{field.displayName.toUpperCase() || ''}</Label>
                                                                {field.fieldCode.startsWith("COUNTRY") ? <DocumentDataSelect
                                                                    field={field}
                                                                    handleDriverDataChange={this.handleDriverDataChange}
                                                                /> :
                                                                    <DocumentDataInput field={field} handleDriverDataChange={this.handleDriverDataChange} />
                                                                }
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </>}
                                <Col xs={12}>
                                    {this.state.requiresExpiration && (!this.state.expiration ?
                                        <Alert color="info">An expiration date is required for this document type</Alert>
                                        : (!moment(this.state.expiration, moment.HTML5_FMT.DATE, true).isValid() || (moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) > this.state.expiration) &&
                                            <Alert color="info">Please enter a date that's 10 days from today!</Alert>))
                                    }
                                    {this.state.requiresExpiration && <FormGroup>
                                        <Label for="documentExpirationDate">Expiration date</Label>
                                        <Input type="date" min={moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)} value={this.state.documentExpirationDate} onChange={(e) => this.setState({ documentExpirationDate: e.target.value })} />
                                    </FormGroup>}
                                </Col>
                            </Row>
                            {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        </ModalBody>
                    </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={this.uploadDocument}
                                        disabled={
                                            !this.state.document || !this.state.documentType || this.state.documentUploaded || this.state.loading || (!this.state.allInputsFilled && (this.props.driverDocumentData.tenantName === 'Mi Nave') && this.state.documentTypeDocumentData.length != 0) ||
                                            (this.state.requiresExpiration &&
                                                (!this.state.documentExpirationDate || !moment(this.state.documentExpirationDate, moment.HTML5_FMT.DATE, true).isValid() || (moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) > this.state.documentExpirationDate))
                                            )
                                        }
                                    >Upload Document</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarDocumentTypesQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { allCarDocumentTypesLoading: loading, allCarDocumentTypes } }) {
            return { loading, allCarDocumentTypes }
        },
    }),
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ id }) => ({ variables: { driverId: id }, fetchPolicy: "cache-first", notifyOnNetworkStatusChange: true }),
        props({ data: { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes } }) {
            return { loading, allDriverDocumentTypes }
        },
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_upload_tlc_docs"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
    graphql(DriverDocumentDataQuery, {
        options: ({ id }) => ({ variables: { driverId: id }, fetchPolicy: "cache-first", notifyOnNetworkStatusChange: true }),
        props({ data: { driverDocumentDataLoading: loading, driverDocumentData } }) {
            return { loading, driverDocumentData }
        },
    }),
)(UploadDocumentModal)
