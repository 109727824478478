import gql from 'graphql-tag'

const AllFollowUpCustomersQuery = gql`query AllFollowUpCustomers($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String], $insuranceStatuses: [String], $insuranceRemovedReasonIds:[ID], $didntTakeCarReasonIds: [ID], $driverTags: [String], $assignedTo: [ID], $hasRented: Boolean, $leadStatuses: [String], $dtacAfterRental: Boolean, $excludeDriverTags: [String]) {
    allFollowUpCustomers(first: $first, orderBy: $orderBy, after: $cursor, searchTerm: $searchTerm, insuranceStatuses: $insuranceStatuses, insuranceRemovedReasonIds: $insuranceRemovedReasonIds, didntTakeCarReasonIds:$didntTakeCarReasonIds, driverTags: $driverTags, assignedTo: $assignedTo, hasRented: $hasRented, leadStatuses: $leadStatuses, dtacAfterRental: $dtacAfterRental, excludeDriverTags: $excludeDriverTags){
        edges{    
          node{
            id
            pk
            dateAdded
            name
            phone
            email
            preferredLanguage
            isTlc
            tlcLicense
            dmvLicense
            inOffice
            assignedReps{
              edges{
                  node{
                      id
                      username
                      firstName
                      lastName
                  }
              }
            }
            isDisabled
            isRepo
            branch{
                id
                name
            }
            tags{
                id
                name
                color
            }
            didnttakecardriverSet{
              edges{
                node{
                  id
                  dateAdded
                  reason{
                    id
                    name
                  }
                }
              }
            }
            leadSet{
              edges{
                node{
                  id
                  dateAdded
                }
              }
            }
          }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
      }
  }`

const DriverInsuranceStatusStatus = gql`query   {
    optionsList:__type(name: "DriverInsuranceStatusStatus") {
        states: enumValues {
            name
            description
        }
    }
  }`

const AllRemovedReasonTypes = gql`
      query AllRemovedReasonTypes{
        optionsList:  allRemovedReasonTypes(isActive:true){
              edges{
                  node{
                      id
                      reasonName
                  }
              }
        }
      }
  `

const AllDidntTakeCarReason = gql`
    query AllDidntTakeCarReason{
      optionsList:  allDidntTakeCarReason{
        edges{
            node{
                id
                name
            }
        }
      }
    }
  `

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 13){
        edges{
            node{
                id
                name
                color
            }
        }
    }
}`

const LeadStatus = gql`query Types{
    optionsList:__type(name: "LeadStatus") {
       states: enumValues {
           name
           description
       }
    }
   
  }`

export { AllFollowUpCustomersQuery, DriverInsuranceStatusStatus, AllRemovedReasonTypes, AllDidntTakeCarReason, RepsQuery, TagsQuery, LeadStatus }