import React, { Component } from 'react'
import { compose, graphql, withApollo, Query } from 'react-apollo'
import '../CarDetail.css'
import {
    Col,
    Row,
    Table,
    UncontrolledTooltip
} from 'reactstrap'
import moment from 'moment'
import { AllEquipmentsQuery } from '../Queries'
import { RemoveEquipment } from '../Mutations'
import UpdateEquipment from './UpdateEquipment'
import ConfirmationPopup from "../../../Material/ConfirmationPopup"
import Loader from "../../../Material/Loader"
import InstallEquipment from "./InstallEquipment"

const equipmentsTable = [
    { id: "name", name: "Name", sortable: false },
    { id: "type", name: "Type", sortable: true },
    { id: "description", name: "Description", sortable: true },
    { id: "dateAdded", name: "Date Added", sortable: true },
    { id: "dateRemoved", name: "Date Removed", sortable: true },
    { id: "customId", name: "Custom Device Id", sortable: false },
    { id: "createdBy", name: "Created By", sortable: true },
    { id: "action", name: "Action", sortable: true },
]

class EquipmentsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            orderBy: ["-id"],
            equipmentHeader: this.props.removed ? equipmentsTable : equipmentsTable.filter(equipment => equipment.name !== "Date Removed"),
            equipmentUninstalled: false,
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };
    removeCarEquipment = (equipmentId, refetch) => {
        if (equipmentId) {
            this.setState({ loading: true })
            let input = { equipmentId: equipmentId }
            this.props.client.mutate({
                mutation: RemoveEquipment,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.removeEquipment && result.data.removeEquipment.ok) {
                    refetch()
                    this.setState({ loading: false, equipmentUninstalled: true })
                } else if (result && result.data && result.data.removeEquipment && result.data.removeEquipment.errors && result.data.removeEquipment.errors.length > 0) {
                    this.setState({ error: result.data.removeEquipment.errors[0].messages[0] })
                    this.setState({ loading: false })
                } else {
                    this.setState({ error: "An error has occurred. Please check your input or contact admin." })
                    this.setState({ loading: false })
                }
            }).catch((err) => {
                this.setState({ loading: false })
                this.setState({ error: err.toString() })
            })
        }
    }
    handleCloseConfirmationPopup = () => {
        this.toggleModal("")
        this.setState({ error: "", equipmentUninstalled: false, success: "" })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.removed !== this.props.removed) {
            if (this.props.removed) {
                this.setState({ equipmentHeader: equipmentsTable.map(equipment => equipment) })
            } else {
                let equipmentTable = equipmentsTable.filter(equipment => equipment.name !== "Date Removed")
                this.setState({ equipmentHeader: equipmentTable })
            }
        }
    }

    render() {
        return (
            <div>
                {this.props.openModal === "InstallEquipment" && <InstallEquipment pk={this.props.carId} refetchQuery={this.props.refetchQuery} open={this.props.openModal === "InstallEquipment"} handleClose={this.props.handleClose} />}
                {this.props.loading ? <Loader /> :
                    <Row>
                        <Col sm="12">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {this.state.equipmentHeader && this.state.equipmentHeader.map(tableHeader =>
                                            <th key={tableHeader.id}>{tableHeader.name}</th>

                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props &&
                                            this.props.allEquipments &&
                                            this.props.allEquipments.edges &&
                                            this.props.allEquipments.edges.length > 0 ?
                                            this.props.allEquipments.edges.map((equipment, index) =>
                                                <tr>
                                                    <td>
                                                        {equipment.node.equipmentItems && equipment.node.equipmentItems.name ? equipment.node.equipmentItems.name : "---"}
                                                    </td>
                                                    <td>
                                                        {
                                                            equipment.node.equipmentItems &&
                                                                equipment.node.equipmentItems.equipmentItemsGroup ?
                                                                equipment.node.equipmentItems.equipmentItemsGroup.name : "---"
                                                        }
                                                    </td>
                                                    <td>
                                                        {equipment.node.equipmentItems && equipment.node.equipmentItems.description ? equipment.node.equipmentItems.description : "---"}
                                                    </td>
                                                    <td>
                                                        {equipment.node && equipment.node.dateAdded ? moment(equipment.node.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "---"}
                                                    </td>
                                                    {this.props.removed && <td>
                                                        {equipment.node && equipment.node.dateRemoved ? moment(equipment.node.dateRemoved).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "---"}
                                                    </td>}
                                                    <td>
                                                        {this.state.openModal === "updateCarEquipmentCustomId" + index && <UpdateEquipment id={equipment.node.id} value={equipment.node.customDeviceId} name="customDeviceId" placeholder="Update Custom Device Id" target={"updateCarEquipmentCustomId" + index} open={this.state.openModal === "updateCarEquipmentCustomId" + index} handleClose={() => this.toggleModal("")} refetchEquipments={this.props.refetchQuery} />}
                                                        {equipment.node.customDeviceId ? equipment.node.customDeviceId : "---"} <i id={"updateCarEquipmentCustomId" + index} onClick={() => this.toggleModal("updateCarEquipmentCustomId" + index)} className={"fa fa-pencil"} />
                                                    </td>
                                                    <td>
                                                        {equipment.node.rep ? equipment.node.rep.firstName ? equipment.node.rep.firstName + " " + equipment.node.rep.lastName : equipment.node.rep.username : "---"}
                                                    </td>
                                                    <td>
                                                        {this.state.openModal === "uninstallEquipment" + index &&
                                                            <ConfirmationPopup
                                                                message="Are you sure you want to uninstall this equipment?"
                                                                open={this.state.openModal === "uninstallEquipment" + index}
                                                                confirmAction={() => this.removeCarEquipment(equipment.node.id, this.props.refetchQuery)}
                                                                handleClose={() => this.handleCloseConfirmationPopup()}
                                                                action="Uninstall"
                                                                loading={this.state.loading}
                                                                color="danger"
                                                                error={this.state.error ? this.state.error : ""}
                                                                success={this.state.equipmentUninstalled ? "Equipment Uninstalled Successfully" : ""}
                                                            />
                                                        }
                                                        <i
                                                            id={"uninstallEquipment" + index}
                                                            className="fa fa-lg fa-times text-danger pl-3"
                                                            aria-hidden="true"
                                                            onClick={() => this.toggleModal("uninstallEquipment" + index)}
                                                        ></i>
                                                        <UncontrolledTooltip placement="right" target={"uninstallEquipment" + index}>Uninstall Equipment</UncontrolledTooltip>
                                                    </td>
                                                </tr>) :
                                            <tr>
                                                <td>
                                                    {this.props.loading ?
                                                        <p>
                                                            Loading Equipments
                                                        </p> :
                                                        <p>
                                                            No Equipments
                                                        </p>
                                                    }
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllEquipmentsQuery, {
        options: ({ carId, removed, equipmentType }) => ({
            variables: { carId, removed, equipmentType, fetchPolicy: 'cache-and-network' }
        }),
        props({ data: { allEquipments, loading, fetchMore, variables } }) {
            return {
                allEquipments,
                loading,
                refetchQuery: () => {
                    let currentLength = allEquipments && allEquipments.edges ? allEquipments.edges.length < 30 ? allEquipments.edges.length + 1 : allEquipments.edges.length : 30
                    return fetchMore({
                        query: AllEquipmentsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allEquipments.edges
                            const pageInfo = fetchMoreResult.allEquipments.pageInfo
                            return {
                                allEquipments: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    removed: previousResult.removed,
                                    equipmentType: previousResult.equipmentType,
                                    __typename: previousResult.allEquipments.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
)(EquipmentsList)