import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Badge, Progress, Alert } from 'reactstrap'
import { Subscription, SubscriptionTypes, StripeCards } from "./Queries"
import Label from "reactstrap/lib/Label"
import toTitleCase from "../../Functions/toTitleCase"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import { CancelSubscriptionMutation } from "./Mutations"
import Select from "react-select"
import "./Subscriptions.css"

class ScheduleReturnForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    cancelSubscription = () => {
        try {
            this.setState({ loading: true })
            let input = {
                subscriptionId: this.props.subscription.id,
                status: 0
            }
            this.props.client.mutate({
                mutation: CancelSubscriptionMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.updateSubscription && result.data.updateSubscription.ok) {
                    this.setState({ loading: false, subscriptionCanceled: true, error: null })
                    this.handleClose()
                } else {
                    this.setState({ loading: false, subscriptionCanceled: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, subscriptionCanceled: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, subscriptionCanceled: false, })
        }
    };


    render() {
        return (
            <div>
                <Modal className="subscriptionDetail" isOpen={this.props.open} toggle={this.props.handleClose}>
                    <ModalHeader>Subscription Detail</ModalHeader>
                    {(this.state.loading || this.props.loading) && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                    {this.props.subscription && <ModalBody>
                        {this.state.openModal === "cancelSubscription" && <ConfirmationPopup
                            open={this.state.openModal === "cancelSubscription"}
                            message={"Are you sure you want to cancel this subscription?"}
                            loading={this.state.loading}
                            handleClose={this.props.handleClose}
                            action={"Yes"}
                            confirmAction={this.cancelSubscription}
                            color="danger"
                            error={this.state.error ? this.state.error : ""}
                            success={this.state.subscriptionCanceled ? "Subscription Cancelled Successfully" : ""}
                        />}
                        <Row className="my-2">
                            <Col xs={6}>
                                <Label>Subscription Type</Label>
                                <Select
                                    className="bos-custom-select"
                                    classNamePrefix="bos-select"
                                    placeholder="Select Agreement Type"
                                    isDisabled
                                    value={({ label: this.props.subscription.subscriptionType.name, value: this.props.subscription.subscriptionType.id })}
                                    isLoading={this.props.allSubscriptionTypesLoading || this.props.loading}
                                    onChange={(item) => this.setState({ subscriptionType: item && item.value ? item.value : null })}
                                />
                            </Col>
                            <Col className="edit-item" xs={6}>
                                <Label>Status</Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    value={this.props.subscription.statusDisplay ? this.props.subscription.statusDisplay : "--"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="edit-item" xs={6}>
                                <Label>Stripe Status</Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    value={this.props.subscription.stripeStatus ? toTitleCase(this.props.subscription.stripeStatus) : "--"}
                                    disabled
                                />
                            </Col>
                            <Col className="edit-item" xs={6}>
                                <Label>Created By</Label>
                                <Input
                                    type="text"
                                    name="createdBy"
                                    value={this.props.subscription.createdBy.firstName ? this.props.subscription.createdBy.firstName + " " + this.props.subscription.createdBy.lastName : this.props.subscription.createdBy.username}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="edit-item" xs={6}>
                                <Label>Amount</Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    value={this.props.subscription.subscriptionType ? this.props.subscription.subscriptionType.amount : "--"}
                                    disabled
                                />
                            </Col>
                            <Col className="edit-item" xs={6}>
                                <Label>Deductible Amount</Label>
                                <Input
                                    type="text"
                                    name="deductible"
                                    value={this.props.subscription.subscriptionType ? this.props.subscription.subscriptionType.deductibleAmount : "--"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="edit-item" xs={6}>
                                <Label>Start Date</Label>
                                <Input
                                    type="text"
                                    name="startDate"
                                    value={this.props.subscription.startDate ? this.props.subscription.startDate : "--"}
                                    disabled
                                />
                            </Col>
                            <Col className="edit-item" xs={6}>
                                <Label>End Date</Label>
                                <Input
                                    type="text"
                                    name="startDate"
                                    value={this.props.subscription.endDate ? this.props.subscription.endDate : "--"}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="edit-item" xs={12}>
                                <Label>Action</Label>
                                <br />
                                {this.props.subscription.status.includes(1) && <Button size="sm" outline color="danger" className="primary" onClick={() => this.toggleModal("cancelSubscription")}>CANCEL SUBSCRIPTION</Button>} &nbsp;&nbsp;
                                <a target="_blank" href={this.props.subscription.externalLink}><i className={"fa fa-cc-stripe fa-2x"}></i></a>

                            </Col>
                        </Row>
                    </ModalBody>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.props.handleClose}>
                                        Close
                                    </Button> &nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(Subscription, {
        options: ({ id }) => ({ variables: { id }, notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' }),
        props({ data: { loading, subscription } }) {
            return {
                loading,
                subscription,
            }
        },
    }),
    graphql(SubscriptionTypes, {
        options: ({ agreementTypeId }) => ({ fetchPolicy: 'cache-and-network', variables: { agreementTypeId: agreementTypeId } }),
        props({ data: { loading, allSubscriptionTypes } }) {
            return {
                allSubscriptionTypesLoading: loading,
                allSubscriptionTypes
            }
        },
    }),
    graphql(StripeCards, {
        options: ({ driverId }) => ({ variables: { id: driverId } }),
        props({ data: { loading, cards, variables } }) {
            return { cardsLoading: loading, cards, variables }
        },
    }),
)(ScheduleReturnForm)
