import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Card, CardBody, Form, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap'
import Select from 'react-select'
import { CarIssueStatuses, HasPermissionsQuery, CarIssueSeverities } from '../Functions/querys'
import renderEnumToInt from "../Functions/renderEnumToInt"
import toTitleCase from '../Functions/toTitleCase'
import './CarIssue.css'
import moment from 'moment-timezone'
import datetimeToString from '../Functions/datetimeToString'
import CloseCarIssue from '../CarIssues/CloseCarIssue'

const CarsQuery = gql`query CarsQuery($searchTerm: String){
    allCars(searchTerm: $searchTerm, first:5){
        edges{
            node{
                id
                pk
                vin
                plate{
                    dmvPlate
                    fhv
                }
            }
        }
    } 
}`

const CarDetailsQuery = gql`query Car($id: Int!){
    car(id: $id){
        id
        pk
    }
  }`

const CreateCarIssue = gql`
mutation CreateCarIssue($input: CreateCarIssueMutationInput!){
    createCarIssue(input:$input){
    ok
    errors{
        messages
    }
}
} `
const UpdateCarIssue = gql`
mutation UpdateCarIssue($input: UpdateCarIssueMutationInput!){
    updateCarIssue(input:$input){
    ok
    errors{
        messages
    }
}
} `
const AllCarIssueCategoriesQuery = gql`
  query AllCarIssueCategories{
    allCarIssueCategories(orderBy:["category"]){
        edges {
        node {
            id
            category
            description
            type
        }
        }
    }
  }
`
const AllVendorsQuery = gql`
  query AllVendors{
    allVendors(isActive:true, orderBy:["name"]){
      edges {
        node {
          id
          pk
          name
          address
        }
      }
    }
  }
`


class CarIssueModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCar: this.props.isUpdate && this.props.carIssue && this.props.carIssue.car ? this.props.carIssue.car : null,
            category: this.props.isUpdate && this.props.carIssue && this.props.carIssue.issueCategory ? this.props.carIssue.issueCategory.id : null,
            description: this.props.isUpdate && this.props.carIssue && this.props.carIssue.issueDesc ? this.props.carIssue.issueDesc : null,
            vendor: this.props.isUpdate && this.props.carIssue && this.props.carIssue.vendor ? this.props.carIssue.vendor.id : null,
            status: this.props.isUpdate && this.props.carIssue && this.props.carIssue.status ? this.props.carIssue.status : null,
            projectedCost: this.props.isUpdate && this.props.carIssue && this.props.carIssue.projectedCost ? this.props.carIssue.projectedCost : null,
            severity: this.props.isUpdate && this.props.carIssue && this.props.carIssue.severity ? this.props.carIssue.severity : null,
            alertDriver: this.props.isUpdate && this.props.carIssue && this.props.carIssue.alertDriver != null ? this.props.carIssue.alertDriver : false,
            canPickup: this.props.isUpdate && this.props.carIssue && this.props.carIssue.canPickup != null ? this.props.carIssue.canPickup : true,
            metadata: this.props.isUpdate && this.props.carIssue && this.props.carIssue.metadata ? this.props.carIssue.metadata : null,
            carSuggestions: null,
            carIssueCategories: [],
            location: null,
            openModal: "",
            isCarStage11: false
        }
    }
    fetchCar = (id) => {
        this.setState({ loading: true })
        this.props.client.query({
            query: CarDetailsQuery,
            variables: { id: id }
        }).then((response) => {
            if (response && response.data && response.data.car) {
                this.setState({ selectedCar: response.data.car, loading: false })
            } else {
                this.setState({ selectedCar: null, loading: false })
            }
        }).catch((err) => {
            this.setState({ selectedCar: null, loading: false })
        })
    }
    componentDidMount() {
        if (this.props.carPk) {
            this.fetchCar(this.props.carPk)
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.allCarIssueCategories !== this.props.allCarIssueCategories && this.state.carIssueCategories.length === 0) {
            let categoriesName = [... new Set(this.props.allCarIssueCategories.edges.map(option => option.node.type))].map(option => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase())
            let carIssueCategories = {}
            categoriesName.forEach(cat => {
                carIssueCategories[cat] = { options: [] }
            })
            for (let i = 0; i < this.props.allCarIssueCategories.edges.length; i++) {
                let type = this.props.allCarIssueCategories.edges[i].node.type.charAt(0).toUpperCase() + this.props.allCarIssueCategories.edges[i].node.type.slice(1).toLowerCase()
                if (carIssueCategories[type] && this.props.allCarIssueCategories.edges[i].node.category && type && type !== this.props.allCarIssueCategories.edges[i].node.category) {

                    carIssueCategories[type].options = [...carIssueCategories[type].options, { label: this.props.allCarIssueCategories.edges[i].node.category, value: this.props.allCarIssueCategories.edges[i].node.id }]
                }
            }
            let categories = Object.keys(carIssueCategories).map(category => ({ label: category, options: carIssueCategories[category].options }))
            this.setState({ carIssueCategories: categories })
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
        }
        this.setState({ [name]: value })
    };
    handleClose = (e) => {
        let state = {
            selectedCar: null,
            category: null,
            description: null,
            vendor: null,
            status: null,
            projectedCost: null,
            severity: null,
            location: null,
            alertDriver: false,
            canPickup: false,
            metadata: null,
            carSuggestions: null,
            openModal: "",
            isCarStage11: false
        }
        this.setState({ ...state })
        // if(this.props.refetchQuery){
        //     this.props.refetchQuery();
        // }
        this.props.handleClose()
    };
    handleSubmit = () => {
        try {
            this.setState({ loading: true, error: "" })
            if (!this.state.selectedCar || !this.state.selectedCar.id || !this.state.category || !this.state.description || this.state.description == '' || this.state.description.trim() == '') {
                throw 'Error: Required fields are missing'
            }
            let input = {
                categoryId: this.state.category,
                description: this.state.description,
            }
            if (this.state.vendor) input["vendorId"] = this.state.vendor
            if (this.state.status) input["status"] = this.state.status
            if (this.state.projectedCost) input["projectedCost"] = this.state.projectedCost
            if (this.state.severity) input["severity"] = renderEnumToInt(this.state.severity)
            if (this.state.canPickup != null) input["canPickup"] = this.state.canPickup
            if (!this.props.isUpdate && this.state.selectedCar.id) input["carId"] = this.state.selectedCar.id
            if (!this.props.isUpdate && this.state.alertDriver) input["alertDriver"] = this.state.alertDriver
            if (!this.props.isUpdate && this.state.isCarStage11) input["addToStage11"] = this.state.isCarStage11
            if (this.props.isUpdate) input["issueId"] = this.props.carIssue.id

            this.props.client.mutate({
                mutation: this.props.isUpdate ? UpdateCarIssue : CreateCarIssue,
                variables: { input },
            }).then((result) => {
                if (result.data && ((this.props.isUpdate && result.data.updateCarIssue && result.data.updateCarIssue.ok) || (!this.props.isUpdate && result.data.createCarIssue && result.data.createCarIssue.ok))) {
                    this.setState({ loading: false, error: null })
                    this.props.refetchQuery()
                    this.handleClose()
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (!this.props.isUpdate && result.data && result.data.createCarIssue && result.data.createCarIssue.errors && result.data.createCarIssue.errors[0] && result.data.createCarIssue.errors[0].messages[0])
                        errorMessage = result.data.createCarIssue.errors[0].messages[0]
                    else if (this.props.isUpdate && result.data && result.data.updateCarIssue && result.data.updateCarIssue.errors && result.data.updateCarIssue.errors[0] && result.data.updateCarIssue.errors[0].messages[0])
                        errorMessage = result.data.updateCarIssue.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured" + error
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: err, loading: false })
        }
    };
    handleUpdateCarSearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.client.query({
                query: CarsQuery,
                variables: { searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                    this.setState({ carSuggestions: response.data.allCars.edges })
                } else {
                    this.setState({ carSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ carSuggestions: null })
            })
        } else {
            this.setState({ carSuggestions: null })
        }
    }

    render() {
        let metadata = this.state.metadata, issueVendors = null, issueCreatedBy = null
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        if (metadata) {
            issueCreatedBy = metadata && metadata.createdBy ? metadata.createdBy : null
            issueVendors = metadata && metadata.vendors && metadata.vendors.length > 0 ? metadata.vendors : null
            if (issueVendors) {
                issueVendors = issueVendors.map(vendor => ({
                    name: this.props.allVendors && this.props.allVendors.edges.length > 0 && this.props.allVendors.edges.filter(item => item.node.pk == Object.keys(vendor)[0]).length > 0 ? this.props.allVendors.edges.filter(item => item.node.pk == Object.keys(vendor)[0])[0].node.name : "--",
                    dateAdded: vendor[Object.keys(vendor)[0]]
                }))
                issueVendors.sort(function (x, y) { return y.dateAdded - x.dateAdded })
            }
        }
        return (
            <div>
                <Modal isOpen={this.props.open} className="car-issue-modal">
                    {this.state.openModal && this.state.openModal === "closeIssueModal" && <CloseCarIssue handleClose={() => this.handleClose()} open={this.state.openModal === "closeIssueModal"} id={this.props.carIssue.id} refetchQuery={this.props.refetchQuery} />}
                    <ModalHeader>{this.props.isUpdate ? "Update" : "Create"} Car Issue</ModalHeader>
                    <ModalBody>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="carId"><small>What's the Car ID? *</small></Label>
                                    {this.state.selectedCar ? <><br /><b>{this.state.selectedCar.pk}</b>&nbsp;&nbsp;&nbsp;{!(this.props.isUpdate || this.props.isCreate) && <i className="fa fa-times" onClick={() => this.setState({ selectedCar: null, carSuggestions: null })} />} </> :
                                        <ButtonDropdown className="w-100" isOpen={this.state.openModal === "CarFinder"} toggle={() => this.toggleModal("CarFinder")}>
                                            <DropdownToggle caret>Choose Car for issue</DropdownToggle>
                                            <DropdownMenu className='w-100'>
                                                <Label for="carSearch" className='pl-3'><small>Search by id, vin, dmv plate</small></Label>
                                                <Input type="text" onChange={this.handleUpdateCarSearch} name="driverSearch" id="driverSearch" />
                                                {this.state.carSuggestions && this.state.carSuggestions.length > 0 && this.state.carSuggestions.map((car, i) =>
                                                    <DropdownItem key={i} onClick={() => this.setState({ selectedCar: car.node })} style={{ display: "content" }} >{car.node.pk} · {car.node.vin} · {car.node.plate ? car.node.plate.dmvPlate : "No Plate"}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </ButtonDropdown>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="category" className="d-block"><small>Issue Category *</small></Label>
                                    <Select
                                        // isDisabled={this.props.isUpdate}
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allCarIssueCategories || !this.props.allCarIssueCategories.edges || !this.props.allCarIssueCategories.edges.length > 0 || !this.props.allCarIssueCategories.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        defaultValue={this.props.isUpdate && this.props.carIssue && this.props.carIssue.issueCategory ? ({ value: this.props.carIssue.issueCategory.id, label: this.props.carIssue.issueCategory.name }) : null}
                                        options={this.state.carIssueCategories && this.state.carIssueCategories.map((category, key) => (
                                            {
                                                label: category['label'],
                                                options: category.options && category.options.map((subOption, key) => ({ value: subOption.value, label: subOption.label }))
                                            }
                                        )
                                        )}
                                        placeholder="Select Issue Category"
                                        onChange={category => this.setState({ category: category ? category.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="description"><small>How you Describe the Car Issue? *</small></Label>
                                    <Input type="textarea" disabled={(this.props.isUpdate && this.props.carIssue.status === "CLOSED")} name="description" value={this.state.description} onChange={this.updateInput} placeholder="Please Enter the Issue Description" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="vendor" className="d-block"><small>Car Vendor</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isDisabled={(this.props.isUpdate && this.props.carIssue.status === "CLOSED")}
                                        isLoading={!this.props.allVendors || !this.props.allVendors.edges || !this.props.allVendors.edges.length > 0 || !this.props.allVendors.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        defaultValue={this.props.isUpdate && this.props.carIssue && this.props.carIssue.vendor ? ({ value: this.props.carIssue.vendor.id, label: this.props.carIssue.vendor.name }) : null}
                                        options={this.props.allVendors && this.props.allVendors.edges && this.props.allVendors.edges.length > 0 && this.props.allVendors.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="Select Car Vendor"
                                        onChange={vendor => this.setState({ vendor: vendor ? vendor.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="vendor" className="d-block"><small>Issue Status</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isDisabled={(this.props.isUpdate && this.props.carIssue.status === "CLOSED")}
                                        defaultValue={this.props.isUpdate && this.props.carIssue && this.props.carIssue.status ? ({ value: this.props.carIssue.status, label: toTitleCase(this.props.carIssue.status) }) : null}
                                        isLoading={!this.props.carIssueStatuses || !this.props.carIssueStatuses.states || !this.props.carIssueStatuses.states.length > 0 || !this.props.carIssueStatuses.states.map(item => ({ value: item.name, label: item.description }))}
                                        options={this.props.carIssueStatuses && this.props.carIssueStatuses.states && this.props.carIssueStatuses.states.length > 0 && this.props.carIssueStatuses.states.filter(item => item.name !== "CLOSED").map(item => ({ value: item.name, label: item.description }))}
                                        placeholder="Select Issue Status"
                                        onChange={status => this.setState({ status: status ? status.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="projectedCost" className="d-block"><small>Projected Cost</small></Label>
                                    <Input type="number" name="projectedCost" value={this.state.projectedCost} onChange={this.updateInput} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="severity" className="d-block"><small>Severity</small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        defaultValue={this.props.isUpdate && this.props.carIssue && this.props.carIssue.severity && this.props.carIssueSeverities ? ({ value: this.props.carIssue.severity, label: this.props.carIssueSeverities.states.find(reason => reason.name === this.props.carIssue.severity).description }) : null}
                                        isLoading={!this.props.carIssueSeverities || !this.props.carIssueSeverities.states || !this.props.carIssueSeverities.states.length > 0 || !this.props.carIssueSeverities.states.map(item => ({ value: item.name, label: item.description }))}
                                        options={this.props.carIssueSeverities && this.props.carIssueSeverities.states && this.props.carIssueSeverities.states.length > 0 && this.props.carIssueSeverities.states.map(item => ({ value: item.name, label: item.description }))}
                                        placeholder="Select Issue Severity"
                                        onChange={severity => this.setState({ severity: severity ? severity.value : null })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label for="alertDriver" className="d-block"><small>Notify Driver?</small></Label>
                                <span>YES</span>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="alertDriver"
                                        id="alertDriver"
                                        disabled={this.props.isUpdate}
                                        checked={!this.state.alertDriver}
                                        onChange={() => this.setState({ alertDriver: !this.state.alertDriver })}
                                    />
                                    <label className="toggle-switch-label" htmlFor="alertDriver">
                                        <span className="toggle-switch-inner" />
                                        <span className="toggle-switch-switch" />
                                    </label>
                                </div>
                                <span>NO</span>
                            </Col>
                            <Col>
                                <Label for="canPickup" className="d-block"><small>Can Pickup?</small></Label>
                                <span>YES</span>
                                <div className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        name="canPickup"
                                        id="canPickup"
                                        disabled={(this.props.isUpdate && this.props.carIssue.status === "CLOSED")}
                                        checked={!this.state.canPickup}
                                        onChange={() => this.setState({ canPickup: !this.state.canPickup })}
                                    />
                                    <label className="toggle-switch-label" htmlFor="canPickup">
                                        <span className="toggle-switch-inner" />
                                        <span className="toggle-switch-switch" />
                                    </label>
                                </div>
                                <span>NO</span>
                            </Col>
                            {!this.props.isUpdate ? <Col>
                                <FormGroup>
                                    <Label for="isCarStage11" className="d-block"><small>Add car to stage 11?</small></Label>
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isCarStage11"
                                            id="isCarStage11"
                                            checked={!this.state.isCarStage11}
                                            onChange={() => this.setState({ isCarStage11: !this.state.isCarStage11 })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isCarStage11">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </FormGroup>
                            </Col> :
                                <Col>
                                    <FormGroup>
                                        <small>Created By</small>
                                        <br /><b>{issueCreatedBy ? issueCreatedBy : "--"} </b>
                                    </FormGroup>
                                </Col>}
                        </Row>
                        {this.props.isUpdate && <Row className="issue-extra-info-wrapper">
                            <Col>
                                <FormGroup>
                                    <small>Date Added</small>
                                    <br /><b>{this.props.carIssue.dateAdded ? moment(this.props.carIssue.dateAdded).tz("America/New_York").format('lll') : "--"} </b>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <small>Last Interaction By</small>
                                    <br /><b>{this.props.carIssue.user ? this.props.carIssue.user.firstName ? this.props.carIssue.user.firstName + " " + this.props.carIssue.user.lastName : this.props.carIssue.user.username : "--"} </b>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <small>Date Closed</small>
                                    <br /><b>{this.props.carIssue.dateClosed ? moment(this.props.carIssue.dateClosed).tz("America/New_York").format('lll') : "--"} </b>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {this.props.isUpdate && issueVendors && issueVendors.length > 0 && <Row className='issue-modal-jobs-wrapper'>
                            <Col>
                                <Button className='issue-modal-collapse-btn' onClick={() => this.toggleModal('openissue')}>
                                    Issue Vendor History&nbsp;&nbsp;&nbsp;<i className={this.state.openModal && this.state.openModal === "openissue" ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true" />
                                </Button>
                                <Collapse isOpen={this.state.openModal && this.state.openModal === "openissue"}>
                                    {issueVendors.map(vendor =>
                                        <Card>
                                            <CardBody>
                                                <Form>
                                                    <Row>
                                                        <Col>
                                                            <Label><small>Vendor</small></Label>
                                                            <br /><b>{vendor.name}</b>
                                                        </Col>
                                                        <Col>
                                                            <Label><small>Since</small></Label>
                                                            <br /><b>{datetimeToString(vendor.dateAdded)}</b>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    )}
                                </Collapse>
                            </Col>
                        </Row>}
                    </ModalBody>
                    <ModalFooter className='d-block'>
                        {!this.state.loading &&
                            <Row>
                                {this.props.isUpdate && this.props.carIssue.status != "CLOSED" && <Col>
                                    <Button id="closeIssue" disabled={this.props.hasPermissions && !this.props.hasPermissions.includes("custom_can_close_car_issue")} color="danger" onClick={() => this.toggleModal("closeIssueModal")}>Close Issue</Button>
                                    {this.props.hasPermissions && !this.props.hasPermissions.includes("custom_can_close_car_issue") && <UncontrolledTooltip target="closeIssue" placement="right">Not enough permissions to perform this action</UncontrolledTooltip>}
                                </Col>}
                                <Col className='text-right'>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="primary" onClick={this.handleSubmit} disabled={this.state.loading || (this.props.isUpdate && this.props.carIssue.status === "CLOSED")}>
                                        {this.props.isUpdate ? "Update" : "Create"} Car Issue
                                    </Button>
                                </Col></Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarIssueCategoriesQuery, { options: { fetchPolicy: "cache-and-network" }, props({ data: { loading, allCarIssueCategories } }) { return { loading, allCarIssueCategories } } }),
    graphql(AllVendorsQuery, { options: { fetchPolicy: 'cache-first' }, props({ data: { loading, allVendors } }) { return { loading, allVendors } } }),
    graphql(CarIssueStatuses, { options: { fetchPolicy: 'cache-first' }, props({ data: { loading, carIssueStatuses } }) { return { loading, carIssueStatuses } } }),
    graphql(CarIssueSeverities, { options: { fetchPolicy: 'cache-first' }, props({ data: { loading, carIssueSeverities } }) { return { loading, carIssueSeverities } } }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_close_car_issue"] } }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(CarIssueModal)
