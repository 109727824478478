import gql from "graphql-tag"
const InsuranceStatusQuery = gql`query InsuranceStatusQuery($cursor: String,$first:Int!,$assignedTo: ID, $searchTerm: String, $orderBy: [String],$statuses:[Int], $driverTags: [String], $hasMissingDocs: Boolean, $driverStages:[Int], $hasRental:Boolean, $loadAgreementSet: Boolean!, $loadMissingInsuranceDoc: Boolean!, $inOffice: Boolean, $types: [ID]) {
    insurances: allInsuranceStatus(first:$first,orderBy:$orderBy,assignedTo: $assignedTo,after: $cursor,searchTerm:$searchTerm,statuses:$statuses, driverTags: $driverTags, hasMissingDocs: $hasMissingDocs, driverStages:$driverStages, hasRental:$hasRental, inOffice: $inOffice, types: $types){
        edges{
            node{
                id
                pk
                dateAdded
                dateModified
                duration
                surcharge
                deposit
                status
                notes
                createdBy{
                    id
                    username
                    firstName
                    lastName
                }
                driver{
                    id
                    pk
                    name
                    email
                    phone
                    notes
                    balance
                    dmvLicense
                    tlcLicense
                    inOffice
                    branch{
                        id
                        name
                    }
                    missingInsuranceDocuments @include(if: $loadMissingInsuranceDoc){
                        id
                        typeName
                    }
                    mobileappdriver{
                        id
                    }
                    notes
                    dateAdded
                    stage
                    tags{
                        id
                        name
                    }
                    agreementSet(orderBy: ["-date_added"], first:1) @include(if: $loadAgreementSet) {
                        edges {
                          node {
                            id
                            currentReturn {
                              id
                              returnCategory
                            }
                          }
                        }
                      }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    } }`

const DriverInsuranceStatus = gql`query   {
    __type(name: "DriverInsuranceStatusStatus") {
        states: enumValues {
            name
            description
        }
    }
}`

const InsuranceQuery = gql`query InsuranceStatus($id: ID!) {
    insuranceStatus(id:$id){
        id
        pk
        dateAdded
        duration
        surcharge
        deposit
        status
        notes
        metadata
        dateToReconsider
        createdBy {
            id
            username
            firstName
            lastName
        }
        driverremovedreasonSet {
            edges {
              node {
                id
                reason{
                    id
                    reasonName
                }
              }
            }
        }
        broker{
            id
            name
        }
        insuranceReason{
            id
            title
        }
        extraDocsRequired {
            edges {
                node {
                    id
                    typeName
                }
            }
        }
        driverInsuranceType{
            id
            name
            broker {
                id
                name
            }
            surcharge
            deposit
        }
        driver{
            id
            pk
            name
            email
            phone
            notes
            balance
            dmvLicense
            tlcLicense
            dob
            age
            city
            state
            streetAddress
            balanceWriteOff
            creditWriteOff
            currentAgreement {
                id
              }
              reservationDriver {
                edges {
                  node {
                    id
                  }
                }
              }
            driverRecord {
                name
                value
            }
            branch{
                id
                name
                tenant{
                    id
                }
            }
            missingInsuranceDocuments{
                id
                typeName
            }
        }
    } 
}`

export { InsuranceStatusQuery, DriverInsuranceStatus, InsuranceQuery }