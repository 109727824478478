import React, { useState } from "react"
import { compose, graphql } from "react-apollo"
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Progress, Button } from "reactstrap"
import { CreateConditionGroupMutation } from "./Mutations"
import Select from "react-select"
import { AllConditionGroupsQuery } from "./Queries"

const ConditionGroupForm = ({ createConditionGroup, loading, handleClose, isOpen, refetchAutomationQuery, automationId }) => {

    const [logicalOperator, setLogicalOperator] = useState('')
    const [error, setError] = useState(null)
    const [stateLoading, setStateLoading] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault()
        setStateLoading(true)
        createConditionGroup({
            variables: {
                input: {
                    logicalOperator: logicalOperator,
                    automationId: automationId,
                }
            }
        }).then((response) => {
            if (response.data.createConditionGroup.ok) {
                refetchAutomationQuery()
                handleClose()
            } else {
                setError(response.data.createConditionGroup.errors[0].messages)
            }
        })
        setStateLoading(false)
    }
    return (
        <Modal isOpen={isOpen} toggle={handleClose} >
            <ModalHeader toggle={handleClose}>Ad Logical Operator</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || stateLoading) && <Progress animated color="info" value="100" />}
                <Form className="ActionForm" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="logicalOperator">Logical Operator</Label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select"
                            id="logicalOperator"
                            options={
                                ["AND", "OR"].map((conditionGroup) => ({
                                    label: conditionGroup,
                                    value: conditionGroup,
                                }))
                            }
                            placeholder="Select Logical Operator"
                            onChange={(option) => setLogicalOperator(option.value)}
                        />
                    </FormGroup>
                    <div className="text-right">
                        <Button color="primary" type="submit" disabled={!logicalOperator}>Add Condition</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default compose(
    graphql(AllConditionGroupsQuery, {
        options: ({ automationId }) => ({ variables: { automation: automationId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading: stateloading, allConditionGroups } }) {
            return { stateloading, allConditionGroups }
        },
    }),
    graphql(CreateConditionGroupMutation, { name: 'createConditionGroup' }),
)(ConditionGroupForm)
