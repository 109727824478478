import gql from "graphql-tag"

export const AllNotificationTemplatesQuery = gql`
    query AllNotificationTemplates{
        allNotificationTemplates{
            name
            subject
            body
            description
        }
    }
`