import gql from 'graphql-tag'

export const AllAutomationsQuery = gql`query AllAutomations($first: Int, $after: String, $searchTerm: String, $isActive: Boolean){
    allAutomations(first: $first, after: $after, name_Icontains: $searchTerm, isActive: $isActive){
        edges {
        node {
            id
            name
            dateAdded
            isActive
            actionSet{
                edges{
                    node{ id actionType }
                }
            }
            trigger{
                id
                triggerType
                contentType{
                    id
                    model
                }
            }
            createdBy{
                id
                username
                firstName
                lastName
            }
        }
        }
    }
}`

export const AllAvailableModelsQuery = gql`query AllAvailableModelsQuery($first: Int, $after: String){
    allAvailableModels(first: $first, after: $after){
        edges{
            node { 
                id 
                availableModelFields 
                recentObjects 
                contentType{ 
                    id
                    appLabel 
                    model 
                } 
            }
        }
    }
    
}`


export const AllAvailableModelFields = gql`query AllAvailableModelFields($id: ID!){
    allAvailableModelFields(id: $id)
}`


export const ActionTypesAndParamsQuery = gql`query ActionTypesAndParamsQuery{
    actionTypesAndParams{
        actionType
        actionParams
    }
}`

export const ActionQuery = gql`query ActionQuery($id: ID!){
    action(id: $id){
        
        actionType
        actionParams
        automation{
            id
            trigger{
                id
                contentType{
                    id
                    model
                }
            }
        }
    }
}`

export const AllTriggersQuery = gql`query AllTriggersQuery($automation: ID!){
    allTriggers(automation: $automation){
        edges{
            node{
                id
                triggerType
                columnsUpdated
                contentType{
                    model
                }
            }
        }
    }
}`

export const AllActionsQuery = gql`query AllActionsQuery($automation: ID!){
    allActions(automation: $automation){
        edges{
            node{
                id
                actionType
                actionParams
            }
        }
    }
}`

export const TriggerQuery = gql`query TriggerQuery($id: ID!){
    trigger(id: $id){
        id
        triggerType
        columnsUpdated
        contentType{
            id
            model
            appLabel
        }
    }
}`

export const ConditionTypes = gql`query ConditionTypes{
    conditionTypes: __type(name: "ConditionConditionType") {
        states: enumValues {
            name
            description
        }
    }
}`

export const TriggerTypes = gql`query TriggerTypes{
    triggerTypes: __type(name: "TriggerTriggerType") {
        states: enumValues {
            name
            description
        }
    }
}`

export const AutomationQuery = gql`query Automation($id: ID!){
    automation(id: $id){
        id
        name
        isActive
        dateAdded
        dateModified
        createdBy{
            id
            username
        }
        modifiedBy{
            id
            username
        }
        conditiongroupSet{
            edges{
                node{
                    id
                    logicalOperator
                    conditionSet{
                        edges{
                            node{
                                id
                                conditionType
                                conditionValue
                                fieldName
                            }
                        }
                    }
                }
            }
        }
        actionSet{
            edges{
                node{
                    id
                    actionType
                    actionParams
                    automation{
                        id
                    }
                }
            }
        }
        trigger{
            id
            triggerType
            columnsUpdated
            contentType{
                id
                model
            }
        }
    }
}`

export const AllConditionGroupsQuery = gql`query AllConditionGroupsQuery($automation: ID!){
    allConditionGroups(automation: $automation){
        edges{
            node{
                id
                logicalOperator
                parent
                conditionSet{
                    edges{
                        node{
                            id
                            conditionType
                            conditionValue
                            fieldName
                        }
                    }
                }
            }
        }
    }
}`

export const AvailableModelQuery = gql`query AvailableModelQuery($id: ID, $contentTypeId: ID){
    availableModel(id: $id, contentTypeId: $contentTypeId){
        id
        availableModelFields
        recentObjects
        contentType{
            id
            appLabel
            model
        }
    }
}`

export const ConditionQuery = gql`query ConditionQuery($id: ID!){
    condition(id: $id){
        id
        conditionType
        conditionValue
        fieldName
    }
}`
