//  Write and export functions to update address in a modal

import React, { useState, useEffect } from 'react'
import { CREATE_ADDRESS_MUTATION, UPDATE_ADDRESS_MUTATION } from "../Mutations"
import { Row, Col, ModalBody, ModalHeader, Alert, FormGroup, Label, Input, Progress, ModalFooter, Button, Modal, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import Select from "react-select"
import "./DriverDetailNew.css"
import * as USStates from "../../../../Material/States.json"
import * as USCities from "../../../../Material/Cities.json"
import MexicoCities from "../../../../Material/MexicoCities.json"
import MexicoStates from "../../../../Material/MexicoStates.json"
import SearchPlaces from "../../../../Material/SearchPlaces"
const countries = [{ label: "United States", value: "US" }, { label: "Mexico", value: "MX" }]
export function CreateAddress({ client, driverId, driverName, tenantCustomId, open, handleClose, refetchDriverQuery }) {
    const [input, setInput] = useState({
        title: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: countries[0].value,
        driverId: driverId
    })
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const getStateVal = (value) => {
        for (let [key, state] of Object.entries(input.country === "US" ? USStates.default : MexicoStates)) {
            if (state.trim().toLowerCase() === value.trim().toLowerCase())
                return key
        }
        return undefined
    }

    const toggleClose = () => {
        setInput({
            title: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: countries[0].value,
            driverId: driverId
        })
        handleClose()
    }
    const renderStateDropdown = (country) => {
        let states = Object.keys(country === "US" ? USCities.default : MexicoCities).map(state => ({ value: getStateVal(state), label: state }))
        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            value={input.state ? states.find(state => state.value === input.state) : null}
            options={states}
            placeholder="Select State"
            onChange={(state) => setInput({ ...input, state: state.value })}
        />
    }

    const renderTitleDropdown = (branch) => {
        let optionsMX = [
            { label: "Dirección de comprobante de domicilio 1", value: "CDA1" },
            { label: "Dirección de comprobante de domicilio 2", value: "CDA2" },
            { label: "Estacionamiento 1", value: "PA1" },
            { label: "Estacionamiento 2", value: "PA2" },
            { label: "Estacionamiento 3", value: "PA1" }
        ]

        if (tenantCustomId == 'mnave') {
            return <Select
                className="bos-custom-select" classNamePrefix="bos-select"
                value={input.title ? optionsMX.find(option => option.value === input.title) : null}
                options={optionsMX}
                placeholder="Select Title"
                onChange={(title) => setInput({ ...input, title: title.label })}
            />
        } else {
            return <Input type="text" name="title" placeholder="Enter Address Title" onChange={(e) => setInput({ ...input, title: e.target.value })} />
        }

    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        setError('')
        let errors = []
        const userInput = input
        if (!userInput.addressLine1 || !userInput.city || !userInput.state || !userInput.postalCode || !userInput.country || !userInput.title) {
            errors.push('Please fill out all fields')
        }
        if (userInput.country === 'US' && userInput.postalCode.length !== 5) {
            errors.push('Please enter a valid 5 digit US postal code')
        }
        if (userInput.country === 'US' && userInput.state.length !== 2) {
            errors.push('Please enter a valid 2 letter US state code')
        }
        if (errors.length > 0) {
            setError(errors.join(', '))
            setLoading(false)
            return
        }

        try {
            const { data } = await client.mutate({
                mutation: CREATE_ADDRESS_MUTATION,
                variables: { input: userInput }
            })
            if (data && data.createAddress && data.createAddress.ok) {
                setSuccess(true)
                setLoading(false)
                setTimeout(() => {
                    setSuccess(false)
                    refetchDriverQuery()
                    toggleClose()
                }, 1000)
            } else if (data && data.createAddress && data.createAddress.errors && data.createAddress.errors.length > 0) {
                setError(data.createAddress.errors.map(e => e.messages.join(', ')).join(', '))
                setLoading(false)
            }

        } catch (error) {
            setError(error.message ? error.message : "Error creating address")
            setLoading(false)
        }
    }
    if (!driverId || !client) return null
    return (
        <Modal isOpen={open} className="driverAddress">
            <ModalHeader>New Address for {driverName || driverId} </ModalHeader>
            <ModalBody>
                <Row>
                    {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                    {success && <Col xs={12}><Alert color="success">Created successfully!</Alert></Col>}
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="title"><small>Title * </small></Label>
                            {renderTitleDropdown(input.title)}
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="country"><small>Country * <small>(Address search will be based on selected country)</small></small></Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                placeholder="Select Country"
                                options={countries}
                                defaultValue={countries[0]}
                                onChange={(item) => setInput({ ...input, country: item.value, state: '', city: '' })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="searchBox"><small>Search Address <small>(This value is not saved, only used for search)</small></small></Label>
                            <SearchPlaces
                                onSaveAction={(e) => setInput({
                                    ...input,
                                    addressLine1: e.addressLine1,
                                    addressLine2: e.addressLine2,
                                    city: e.city,
                                    state: e.state,
                                    postalCode: e.postalCode,
                                })}
                                initialValue={null}
                                searchType={"all"}
                                country={input.country ? input.country : 'US'}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <FormGroup>
                            <Label for="addressLine1"><small>Address Line 1 *</small></Label>
                            <Input
                                type="text"
                                name="addressLine1"
                                placeholder="Enter Address Line 1 "
                                value={input.addressLine1}
                                onChange={(e) => setInput({ ...input, addressLine1: e.target.value })}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <FormGroup>
                            <Label for="addressLine2"><small>Address Line 2</small></Label>
                            <Input
                                type="text"
                                name="addressLine2"
                                placeholder="Enter Address Line 1 "
                                value={input.addressLine2}
                                onChange={(e) => setInput({ ...input, addressLine2: e.target.value })}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={6}>
                        <FormGroup>
                            <Label for="state"><small>State *</small></Label>
                            {renderStateDropdown(input.country)}
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label for="city"><small>City *</small></Label>
                            <SearchPlaces
                                onSaveAction={(e) => setInput({ ...input, city: e.name, })}
                                initialValue={input.city}
                                searchType={"city"}
                                country={input.country}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label for="postalCode"><small>Postal Code *</small></Label>
                            <Input
                                type="number"
                                name="postalCode"
                                value={input.postalCode}
                                placeholder="Enter Postal Code"
                                onChange={(e) => setInput({ ...input, postalCode: e.target.value })}
                            />
                        </FormGroup>
                    </Col>

                    {loading && <Col xs={12}><Progress animated color="info" value="100" /></Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                {!loading &&
                    <Row>
                        <Col xs={12}>
                            <Button outline color="secondary" onClick={toggleClose}>Close</Button>&nbsp;&nbsp;
                            <Button className="submit-btn" color="primary" onClick={handleSubmit}
                                disabled={!input.addressLine1 || !input.city || !input.state || !input.postalCode || !input.country || !input.title || loading || success}>Create Address</Button>
                        </Col>
                    </Row>
                }
            </ModalFooter>
        </Modal>
    )
}


export function UpdateAddress({ client, address, open, handleClose, refetchDriverQuery }) {
    const [input, setInput] = useState({
        title: address && address.title || "",
        addressLine1: address && address.addressLine1 || "",
        addressLine2: address && address.addressLine2 || "",
        city: address && address.city || "",
        state: address && address.state || "",
        postalCode: address && address.postalCode || "",
        country: address && address.country || "",
    })

    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // Ensure input is updated if address changes
        if (address) {
            setInput({
                title: address.title || "",
                addressLine1: address.addressLine1 || "",
                addressLine2: address.addressLine2 || "",
                city: address.city || "",
                state: address.state || "",
                postalCode: address.postalCode || "",
                country: address.country || "",
            })
        }
    }, [address])

    const getStateVal = (value) => {
        const states = input.country === "US" ? USStates.default : MexicoStates
        for (let [key, state] of Object.entries(states)) {
            if (state.trim().toLowerCase() === value.trim().toLowerCase()) return key
        }
        return undefined
    }

    const renderStateDropdown = () => {
        const states = Object.entries(input.country === "US" ? USStates.default : MexicoStates).map(([key, label]) => ({
            value: key,
            label
        }))

        return (
            <Select
                className="bos-custom-select"
                classNamePrefix="bos-select"
                value={states.find((state) => state.value === input.state) || null}
                options={states}
                placeholder="Select State"
                onChange={(state) => setInput({ ...input, state: state.value })}
            />
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError("")
        const errors = []

        if (input.country === "US" && input.postalCode.length !== 5) {
            setError("Please enter a valid 5-digit US postal code.")
        }
        if (input.country === "US" && input.state.length !== 2) {
            setError("Please enter a valid 2-letter US state code.")
        }
        if (errors.length > 0) {
            setError(errors.join(", "))
            setLoading(false)
            return
        }

        try {
            const { data } = await client.mutate({
                mutation: UPDATE_ADDRESS_MUTATION,
                variables: { input: { ...input, addressId: address.id } },
            })

            if (data && data.updateAddress && data.updateAddress.ok) {
                setSuccess(true)
                setTimeout(() => {
                    refetchDriverQuery()
                    handleClose()
                }, 1000)
            } else if (data && data.updateAddress && data.updateAddress.errors && data.updateAddress.errors.length > 0) {
                setError(data.updateAddress.errors.map(e => e.messages.join(', ')).join(', '))
                setLoading(false)
            }
        } catch (err) {
            setError(err.message || "Error updating address.")
        } finally {
            setLoading(false)
        }
    }

    if (!address.id || !client) return null

    return (
        <Modal isOpen={open} className="driverAddress">
            <ModalHeader>Update Address</ModalHeader>
            <ModalBody>
                <Row>
                    {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                    {success && <Col xs={12}><Alert color="success">Updated successfully!</Alert></Col>}

                    <Col xs={12}>
                        <FormGroup>
                            <Label><small>Title *</small></Label>
                            <Input
                                type="text"
                                name="title"
                                placeholder="Enter Address Title"
                                value={input.title}
                                onChange={(e) => setInput({ ...input, title: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label><small>Country *<small>(Address search will be based on selected country)</small></small></Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                options={countries}
                                value={countries.find((country) => country.value === input.country) || null}
                                placeholder="Select Country"
                                onChange={(item) => setInput({ ...input, country: item.value, state: "" })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label><small>Search Address <small>(This value is not saved, only used for search)</small></small></Label>
                            <SearchPlaces
                                onSaveAction={(e) => setInput({
                                    ...input,
                                    addressLine1: e.addressLine1,
                                    addressLine2: e.addressLine2,
                                    city: e.city,
                                    state: e.state,
                                    postalCode: e.postalCode,
                                })}
                                initialValue={null}
                                searchType={"all"}
                                country={input.country}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label><small>Address Line 1 *</small></Label>
                            <Input
                                type="text"
                                name="addressLine1"
                                placeholder="Enter Address Line 1"
                                value={input.addressLine1}
                                onChange={(e) => setInput({ ...input, addressLine1: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label><small>Address Line 2</small></Label>
                            <Input
                                type="text"
                                name="addressLine2"
                                placeholder="Enter Address Line 2"
                                value={input.addressLine2}
                                onChange={(e) => setInput({ ...input, addressLine2: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label><small>State *</small></Label>
                            {renderStateDropdown()}
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label><small>City *</small></Label>
                            <SearchPlaces
                                onSaveAction={(e) => setInput({ ...input, city: e.name, })}
                                initialValue={input.city}
                                searchType={"city"}
                                country={input.country}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label><small>Postal Code *</small></Label>
                            <Input
                                type="text"
                                name="postalCode"
                                placeholder="Enter Postal Code"
                                value={input.postalCode}
                                onChange={(e) => setInput({ ...input, postalCode: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    {loading && <Col xs={12}><Progress animated color="info" value={100} /></Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={handleClose}>Close</Button>
                <Button
                    className="submit-btn"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    Update Address
                </Button>
            </ModalFooter>
        </Modal>
    )
}