import React, { Component } from 'react'
import { compose } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Row, Col } from 'reactstrap'
import CarsList from '../CarsList'
import CarsTable from '../CarsManagement/CarsTable'
import { debounce, orderBy } from 'lodash'
import '../CarsList.css'
import CarsListHeader from "../CarsListHeader"

const fleetViews = [
    { id: "#fleet", canManageView: false },
    { id: "#manage", canManageView: true }
]

class FleetManagementContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderBy: ["-id"],
            carManageView: false,
            filterValues: {
            }
        }
    }
    updateSearchTerm = debounce((searchTerm) => {
        this.setState({ searchTerm: searchTerm })
    }, 500)

    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };
    componentDidMount() {
        if (window.location.hash && fleetViews.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = fleetViews.findIndex(tab => tab.id === window.location.hash)
            window.location.hash = fleetViews[tabIndex].id
            this.setState({ canManageView: fleetViews[tabIndex].canManageView })
        } else {
            window.location.hash = fleetViews[0].id
            this.setState({ canManageView: fleetViews[0].canManageView })
        }
    }
    updateCarManageView = () => {
        let tabIndex = fleetViews.findIndex(tab => tab.canManageView === !this.state.carManageView)
        window.location.hash = fleetViews[tabIndex].id
        this.setState({ carManageView: !this.state.carManageView })
    }

    render() {
        return (
            <Container fluid className="FleetManagementContainer CarListContainer">
                <CarsListHeader
                    activeTab={this.state.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={{ orderBy: "-id" }}
                    carManageView={this.state.carManageView} location={this.props.location} history={this.props.history}
                    updateCarManageView={this.updateCarManageView}
                />
                <Row>
                    <Col sm="12">
                        {/* {this.state.carManageView ?
                            <CarsTable
                                isFleetManagement={true}
                                currentUser={this.props.currentUser}
                                isReady={this.state.filterValues && this.state.filterValues.isReady !== null ? this.state.filterValues.isReady : null}
                                modelIds={this.state.filterValues && this.state.filterValues.carModelIds ? this.state.filterValues.carModelIds : null}
                                stage={this.state.filterValues && this.state.filterValues.selectedStages ? this.state.filterValues.selectedStages : null}
                                locationId={this.state.filterValues && this.state.filterValues.selectedLocations && this.state.filterValues.selectedLocations.length > 0 && this.state.filterValues.selectedLocations.filter(item => item != "None") && this.state.filterValues.selectedLocations.filter(item => item != "None").length > 0 ? this.state.filterValues.selectedLocations.filter(item => item != "None") : null}
                                isLocationNull={this.state.filterValues && this.state.filterValues.selectedLocations && this.state.selectedLocations.includes("None") ? true : null}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                orderBy={this.state.orderBy}
                                updateOrder={this.updateOrder}
                                hasDeliveryDate={this.state.filterValues && this.state.filterValues.hasDeliveryDate !== null ? this.state.filterValues.hasDeliveryDate : null}
                            />
                            : */}
                        <CarsList
                            isFleetManagement={true}
                            currentUser={this.props.currentUser}
                            isReady={this.state.filterValues && this.state.filterValues.isReady !== null ? this.state.filterValues.isReady : null}
                            modelIds={this.state.filterValues && this.state.filterValues.carModelIds ? this.state.filterValues.carModelIds : null}
                            stage={this.state.filterValues && this.state.filterValues.selectedStages ? this.state.filterValues.selectedStages : null}
                            locationId={this.state.filterValues && this.state.filterValues.selectedLocations && this.state.filterValues.selectedLocations.length > 0 && Array.isArray(this.state.filterValues.selectedLocations.filter(item => item != "None")) ? this.state.filterValues.selectedLocations.filter(item => item !== "None") : null}
                            isLocationNull={this.state.filterValues && this.state.filterValues.selectedLocations && this.state.filterValues.selectedLocations.includes("None") ? true : null}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            minYear={this.state.filterValues && this.state.filterValues.yearRange && this.state.filterValues.yearRange.minYear ? this.state.filterValues.yearRange.minYear : null}
                            maxYear={this.state.filterValues && this.state.filterValues.yearRange && this.state.filterValues.yearRange.maxYear ? this.state.filterValues.yearRange.maxYear : null}
                            orderBy={this.state.orderBy}
                            updateOrder={this.updateOrder}
                            hasDeliveryDate={this.state.filterValues && this.state.filterValues.hasDeliveryDate !== null ? this.state.filterValues.hasDeliveryDate : null}
                            carTags={this.state.filterValues && this.state.filterValues.carTags !== null ? this.state.filterValues.carTags : null}
                            carModelNames={this.state.filterValues && this.state.filterValues.carModelNames !== null ? this.state.filterValues.carModelNames : null}
                        />
                        {/* } */}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default compose(
    withApollo,
)(FleetManagementContainer)
