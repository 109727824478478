import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import Badge from "reactstrap/lib/Badge"

const TenantQuery = gql`query TenantQuery($cursor: String,$first:Int!, $name: String) {
    tenants: allTenants(first:$first, after: $cursor, name_Icontains:$name){
      edges{
        node{
            id
            pk
            dateModified
            name
            customId
            description
            defaultBranch {
                id
                name
            }
            logoImage
            metadata
            createdBy {
                id
                username
                firstName
                lastName
            }
            isActive
        }
      }
      pageInfo{
          endCursor
          hasNextPage
      }
    } 
}`


const tableHeaders = [
    { id: "id", name: "ID", sortable: false },
    { id: "date_modified", name: "Last Modified", sortable: true },
    { id: "tenant", name: "Tenant", sortable: true },
    { id: "custom_id", name: "Custom ID", sortable: true },
    { id: "default_branch", name: "Default Branch", sortable: false },
    { id: "created_by", name: "Created By", sortable: true },
    { id: "is_active", name: "Is Active", sortable: true },
]

class BranchList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }
    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.tenants && this.props.tenants.edges.length} pageInfo={this.props.tenants && this.props.tenants.pageInfo} loadMoreEntries={this.props.tenants && this.props.tenants.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(tableHeader => {
                                return (
                                    <th key={tableHeader.id} >{tableHeader.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.tenants && this.props.tenants.edges && this.props.tenants.edges.length > 0 ? this.props.tenants.edges.map((tenant, i) =>
                            <tr key={tenant.node.id}>
                                <td>
                                    <strong>{tenant.node.pk}</strong>
                                </td>
                                <td>
                                    {tenant.node.dateModified ? <span><DatetimeRenderer datetime={tenant.node.dateModified} /></span> : '--'}
                                </td>
                                <td className="text-uppercase">
                                    {tenant.node.logoImage ? <img width={50} src={tenant.node.logoImage} alt={tenant.node.name + " Logo"} /> : <i className="fa fa-image fa-lg"></i>}
                                    <br /><Link color="secondary" to={'/organization/tenant/' + tenant.node.id}>{tenant.node.name}</Link>
                                </td>
                                <td>
                                    <small>{tenant.node.customId}</small>
                                </td>
                                <td>
                                    <Badge>{tenant.node.defaultBranch && tenant.node.defaultBranch.name ? tenant.node.defaultBranch.name : "--"}</Badge>
                                </td>
                                <td>
                                    {tenant.node.createdBy && tenant.node.createdBy ? tenant.node.createdBy.firstName ? tenant.node.createdBy.firstName + " " + tenant.node.createdBy.lastName : tenant.node.createdBy.username : "--"}
                                </td>
                                <td>
                                    {tenant.node.isActive ? <i className={"fa fa-check-circle text-success fa-lg"} aria-hidden="true" /> : <i className={"fa fa-times-circle text-danger fa-lg"} aria-hidden="true" />}
                                </td>
                            </tr>) :
                            <tr>
                                <td colSpan={7}>No Tenant Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}
export default compose(
    withApollo,
    graphql(TenantQuery, {
        options: ({ searchTerm, stateCode, city, tenant, orderBy }) => ({
            variables: {
                name: searchTerm, stateCode, city, orderBy,
                first: 30, tenant
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, tenants, fetchMore, variables, refetch } }) {
            return {
                loading,
                tenants,
                variables,
                refetch,
                refetchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = tenants && tenants.edges ? tenants.edges.length : 30
                    return fetchMore({
                        query: TenantQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.tenants.edges
                            const pageInfo = fetchMoreResult.tenants.pageInfo
                            return {
                                tenants: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.tenants.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: TenantQuery,
                        variables: {
                            cursor: tenants.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.tenants.edges
                            const pageInfo = fetchMoreResult.tenants.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                tenants: {
                                    edges: [...previousResult.tenants.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.tenants.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(BranchList)
